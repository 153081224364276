/*===========================================
    c-cardGroup
===========================================*/

$margin-card: 1rem;

//-------------------------------------------
[class*="c-cardGroup-"] {
  display: flex;
  flex-wrap: wrap;

  > * {
    @include media-query(lg) {
      margin-right: $margin-card * 2;
      margin-bottom: $margin-card * 2;
    }
    @include media-query(sm) {
      margin-right: $margin-card;
      margin-bottom: $margin-card * 2;
    }
  }

  //-----------------------------------------
  //  タイプ
  //-----------------------------------------
  &[class*="-full"] {
    flex-wrap: nowrap;
    @include media-query(sm) {
      flex-wrap: wrap;
    }
  }

  &[class*="-6"] {

    > * {
      @include media-query(lg) {
        width: calc((100% - #{$margin-card * 2} * 5) / 6 - 0.01px);
      }
      @include media-query(sm) {
        width: calc((100% - #{$margin-card} * 1) / 2);
      }

      &:nth-child(6n) {
        @include media-query(lg) {
          margin-right: 0;
        }
      }

      &:nth-child(2n) {
        @include media-query(sm) {
          margin-right: 0;
        }
      }

      &:nth-last-child(6),
      &:nth-last-child(5),
      &:nth-last-child(4),
      &:nth-last-child(3),
      &:nth-last-child(2),
      &:nth-last-child(1) {
        @include media-query(lg) {
          margin-bottom: 0;
        }
      }
    }
  }

  &[class*="-5"] {

    > * {
      @include media-query(lg) {
        width: calc((100% - #{$margin-card * 2} * 4) / 5 - 0.01px);
      }
      @include media-query(sm) {
        width: calc((100% - #{$margin-card} * 1) / 2);
      }

      &:nth-child(5n) {
        @include media-query(lg) {
          margin-right: 0;
        }
      }

      &:nth-child(2n) {
        @include media-query(sm) {
          margin-right: 0;
        }
      }

      &:nth-last-child(5),
      &:nth-last-child(4),
      &:nth-last-child(3),
      &:nth-last-child(2),
      &:nth-last-child(1) {
        @include media-query(lg) {
          margin-bottom: 0;
        }
      }
    }
  }

  &[class*="-4"] {

    > * {
      @include media-query(lg) {
        width: calc((100% - #{$margin-card * 2} * 3) / 4 - 0.01px);
      }
      @include media-query(sm) {
        width: calc((100% - #{$margin-card} * 1) / 2);
      }

      &:nth-child(4n) {
        @include media-query(lg) {
          margin-right: 0;
        }
      }

      &:nth-child(2n) {
        @include media-query(sm) {
          margin-right: 0;
        }
      }

      &:nth-last-child(4),
      &:nth-last-child(3),
      &:nth-last-child(2),
      &:nth-last-child(1) {
        @include media-query(lg) {
          margin-bottom: 0;
        }
      }
    }
  }

  &[class*="-3"] {

    > * {
      @include media-query(lg) {
        width: calc((100% - #{$margin-card * 2} * 2) / 3 - 0.01px);
      }
      @include media-query(sm) {
        width: 100%;
      }

      &:nth-child(3n) {
        @include media-query(lg) {
          margin-right: 0;
        }
      }

      &:nth-child(2n) {
        @include media-query(sm) {
          margin-right: 0;
        }
      }

      &:nth-last-child(3),
      &:nth-last-child(2),
      &:nth-last-child(1) {
        @include media-query(lg) {
          margin-bottom: 0;
        }
      }
    }
  }

  &[class*="-2"] {

    > * {
      width: calc((100% - #{$margin-card * 2} * 1) / 2 - 0.01px);

      &:nth-child(2n) {
        margin-right: 0;
      }

      &:nth-last-child(2),
      &:nth-last-child(1) {
        @include media-query(lg){
          margin-bottom: 0;
        }
      }
    }
  }

  &[class*="-1"] {

    > * {
      width: 100%;
      margin-right: 0;
      margin-bottom: $margin-card;

      &:nth-last-child(1) {
        margin-bottom: 0;
      }
    }
  }


  //-----------------------------------------
  //  タイプ
  //-----------------------------------------
  &[class*="-vertical"] {

    > * {
      @include media-query(sm) {
        width: 100%;
        margin-right: 0 !important;
      }
    }
  }
}
