/*===========================================
    js-imageZoom
===========================================*/
.js-imageZoom {
  // position: relative;
}

.js-imageZoom__target {
}

.js-imageZoom__content {
  position: absolute;
  top: 0;
  left: 100%;
  width: 100%;
  height: 100%;
  background-color: #000;
}
