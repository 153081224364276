/*===========================================
    c-link
===========================================*/
[class*="c-link"] {
  position: relative;
  color: $color-link;

  [class*="link__icon"] {
    margin-top: -0.3em;
    font-size: 0.8em;
    vertical-align: middle;

    &[class*="-before"] {
      margin-right: 0;
    }

    &[class*="-after"] {
      margin-left: 0.4em;
    }
  }
}
