/*===========================================
    c-iframe
===========================================*/
[class*="c-iframe"] {
  position: relative;

  &:before {
    content: '';
    position: relative;
    display: block;
    width: 100%;
    padding-top: 56.25%;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
  }
}
