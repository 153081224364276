/*===========================================
    c-button
===========================================*/

$opacity-button: 0.6;
$border-botton: 0px;

//-------------------------------------------

[class*="c-button-"] {
  @include transition;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
  padding: 0.8em 2em;
  background-color: $color-text;
  border-radius: 4px;
  color: $color-white;
  font-weight: bold;
  line-height: 1.4;
  letter-spacing: 0.1em;
  text-decoration: none !important;
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    text-decoration: none;
    @include media-query(lg) {
      opacity: $opacity-button;
    }
  }

  &.is-active {
    border: solid $border-botton $color-text;
    background-color: $color-text;
    color: $color-white;
  }

  &.is-disabled {
    border: solid 2px $color-text !important;
    background-color: trasparent !important;
    color: lighten($color-text, 40%) !important;
    opacity: $opacity-button;
    pointer-events: none;
  }

  > img {
    width: 2.5em !important;
    height: 2.5em !important;
    margin: 0 0.5em 0 0 !important;
  }

  [class*="button__icon"] {
    width: 2.5em;
    height: 2.5em;
    // margin-right: 0.5em;
    // margin-left: 0.5em;

    img {
      display: block;
      width: auto;
      height: 100%;
    }

    &[class*="--before"],
    &[class*="--after"] {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    &[class*="--before"] {
      left: 0.5em;
    }

    &[class*="--after"] {
      right: 0.5em;
    }
  }

  input {
    display: none;
  }

  span {
    margin: 0 0.1em;
    font-size: 0.8em;
    letter-spacing: 0.05em;
  }


  //-----------------------------------------
  //  色
  //-----------------------------------------
  &[class*="-black"] {
    background-color: $color-black;
    color: $color-white;

    &.is-active {
      border-color: $color-black;
      background-color: rgba($color-black, $opacity-button);
      color: $color-white;
    }

    &.is-disabled {
      border: solid 1px $color-black !important;
      background-color: transparent !important;
      color: $color-black !important;
    }
  }

  &[class*="-white"] {
    border: solid 1px $color-border;
    background-color: $color-white;
    color: $color-text;

    &.is-active {
      border: solid 1px $color-border;
      border-color: $color-white;
      background-color: rgba($color-white, $opacity-button);
      color: $color-text;
    }

    &.is-disabled {
      border: solid 1px $color-white !important;
      background-color: transparent !important;
      color: $color-white !important;
    }
  }

  &[class*="-gray"] {
    border: solid 1px $color-border;
    background-color: $color-gray;
    color: $color-text;

    &.is-active {
      border: solid 1px $color-border;
      background-color: rgba($color-gray, $opacity-button);
      color: $color-text;
    }

    &.is-disabled {
      border: solid 1px $color-border !important;
      background-color: transparent !important;
      color: $color-border !important;
    }
  }

  &[class*="-primary"] {
    background-color: $color-primary;
    color: $color-white;

    &.is-active {
      border-color: $color-primary;
      background-color: rgba($color-primary, $opacity-button);
      color: $color-white;
    }

    &.is-disabled {
      border: solid 1px $color-primary !important;
      background-color: transparent !important;
      color: $color-primary !important;
    }
  }

  &[class*="-secondary"] {
    background-color: $color-secondary;
    color: $color-white;

    &.is-active {
      border-color: $color-secondary;
      background-color: rgba($color-secondary, $opacity-button);
      color: $color-white;
    }

    &.is-disabled {
      border: solid 1px $color-secondary !important;
      background-color: transparent !important;
      color: $color-secondary !important;
    }
  }

  &[class*="-tertiary"] {
    background-color: $color-tertiary;
    color: $color-white;

    &.is-active {
      border-color: $color-tertiary;
      background-color: rgba($color-tertiary, $opacity-button);
      color: $color-white;
    }

    &.is-disabled {
      border: solid 1px $color-tertiary !important;
      background-color: transparent !important;
      color: $color-tertiary !important;
    }
  }

  &[class*="-cart"] {
    background-color: $color-cart;
    color: $color-white;

    &.is-active {
      border-color: $color-cart;
      background-color: rgba($color-cart, $opacity-button);
      color: $color-white;
    }

    &.is-disabled {
      border: solid 1px $color-cart !important;
      background-color: transparent !important;
      color: $color-cart !important;
    }
  }

  &[class*="-zumen"] {
    background-color: $color-secondary;
    color: $color-white;

    &.is-active {
      border-color: $color-secondary;
      background-color: rgba($color-secondary, $opacity-button);
      color: $color-white;
    }

    &.is-disabled {
      border: solid 1px $color-secondary !important;
      background-color: transparent !important;
      color: $color-secondary !important;
    }
  }

  &[class*="-mitsumori"] {
    background-color: $color-cart;
    color: $color-white;

    &.is-active {
      border-color: $color-cart;
      background-color: rgba($color-cart, $opacity-button);
      color: $color-white;
    }

    &.is-disabled {
      border: solid 1px $color-cart !important;
      background-color: transparent !important;
      color: $color-cart !important;
    }
  }

  //-----------------------------------------
  //  サイズ
  //-----------------------------------------
  &[class*="-xl"] {
    padding: 1.6em 2em;
    font-size: $font-size-lg;
  }

  &[class*="-lg"] {
    font-size: $font-size-lg;
  }

  &[class*="-md"] {
    font-size:  $font-size-md;
  }

  &[class*="-sm"] {
    font-size:  $font-size-sm;
  }

  &[class*="-xs"] {
    font-size:  $font-size-xs;
  }


  //-----------------------------------------
  //  タイプ
  //-----------------------------------------
  &[class*="-size"] {
    // border-radius: 2em;
    height: 6em;
    border: solid 1px $color-border;
    background-color: $color-white;
    color: $color-text;
    font-size:  $font-size-sm;
    white-space: normal;

    &:hover {
      background-color: $color-border;
      border: solid 1px $color-border;
      opacity: 1;
    }

    &.is-active {
      background-color: $color-primary;
      border: solid 1px $color-primary;
      color: $color-white;
    }

    &.is-disabled {
      border: solid 1px $color-border !important;
      background-color: transparent !important;
      color: $color-text !important;
    }
  }

  &[class*="-tab"] {
    // border-top: solid 1px $color-border;
    border-bottom: solid 1px $color-border;
    border-radius: 0;
    background-color: $color-white;
    color: $color-text;
    font-size:  $font-size-md;
    text-align: center;
    @include media-query(sm) {
      white-space: normal;
    }

    &:after {
      @include transition;
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      transform: scaleX(0);
      width: 100%;
      height: 3px;
      background-color: $color-primary;
    }

    &:hover {
      opacity: 1;

      &:after {
        transform: scaleX(1);
      }
    }

    &.is-active {
      // background-color: $color-primary;
      color: $color-primary;

      &:after {
        transform: scaleX(1);
      }
    }

    &.is-disabled {
      border: none !important;
      border-bottom: solid 1px $color-border !important;
      background-color: $color-white !important;
      color: lighten($color-text, 40%) !important;
      pointer-events: none;
    }
  }

  //-----------------------------------------
  //  カート
  //-----------------------------------------
  &[class*="-select"]{
    width: 50%;

    @include media-query(sm){
      width: 100%;
    }
  }
}
