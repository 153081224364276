/*===========================================
    js-scrollHidden
===========================================*/
.js-scrollHidden {
  @include transition;

  &.is-hidden {
    // top: -112px;
  }
}
