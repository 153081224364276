/*===========================================
    c-card
===========================================*/

$color-card: $color-white;

//-------------------------------------------

[class*="c-card-"] {
  position: relative;
  display: flex;
  flex-direction: column;
  // background-color: $color-card;
  //box-shadow: 4px 4px 32px rgba($color-black, 0.1);
  color: $color-text;
  text-decoration: none;

  .card__head {
    position: relative;
    @media all and (-ms-high-contrast: none) {
      min-height: 0%;
    }

    .card__new {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      padding: 0.2em 0.6em;
      background-color: $color-danger;
      color: $color-white;
      font-weight: bold;
      font-size: $font-size-xs;
    }
  }

  @media all and (-ms-high-contrast: none){
    .card__head {
      // height: 250px;
    }
  }

  .card__body {
    // padding: 1.5rem;
    @include media-query(sm) {
      // padding: 1rem;
    }

    > * {
      // margin: 1rem 0;
      @include media-query(sm) {
        line-height: 1.4;
      }

      &:first-child {
        margin-top: 0 !important;
      }

      &:last-child {
        margin-bottom: 0 !important;
      }
    }
  }

  .card__image {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: auto;
    @include media-query(sm) {
      // height: 100%;
    }

    &:before {
      content:"";
      display: block;
      padding-top: 66%;
    }

    .is-ie11 & {
      // background-color: $color-background;
      background-color: $color-white;
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;

      .is-ie11 & {
        width: 100%;
        height:  auto;
        object-fit: contain;
      }
    }
  }

  .card__category {
    margin-bottom: 0.4em;
    color: lighten($color-text, 15%);
    line-height: 1.6;
  }

  .card__title {
    margin-bottom: 0.4em;

    @include media-query(sm){
      // text-indent: -1em;
      // margin-left: 1em;
    }
  }

  .card__discription {
    margin-bottom: 0.4em;
    color: lighten($color-text, 15%);
  }

  .card__time {
    display: block;
    margin-bottom: 0.4em;
    color: lighten($color-text, 15%);
  }

  .card__price {
    margin: 0.2em 0;
  }

  .card__tag {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;

    > * {
      margin: 0 0.5em 0.5em 0;
    }
  }

  //-----------------------------------------
  //  サイズ
  //-----------------------------------------
  &[class*="-lg"] {

    .card__body {
      // padding: 2.5rem;
      @include media-query(lg) {
        margin-top: 2.5rem;
      }
      @include media-query(sm) {
        padding: 1.5rem;
      }
    }
  }

  &[class*="-md"] {

    .card__body {
      // padding: 1.5rem;
      @include media-query(lg) {
        margin-top: 1.5rem;
      }
      @include media-query(sm) {
        padding: 1rem;
      }
    }
  }

  &[class*="-sm"] {

    .card__body {
      // padding: 1rem;
      @include media-query(lg) {
        margin-top: 0.5rem;
      }
      @include media-query(sm) {
        padding: 0.75rem;
      }
    }
  }


  //-----------------------------------------
  //  カテゴリー
  //-----------------------------------------
  &[class*="-category"] {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    // padding-top: 2rem;
    // border-top: solid 1px $color-border;

    .card__head {
      width: 104px;
      height: 104px;

      img {
        display: block;
        width: 100%;
      }
    }

    .card__body {
      width: calc(100% - 104px - 20px);

      &--full {
        width: 100%;
      }
    }

    .card__information {
      padding-left: 1em;

      .information__label {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-bottom: 0.4em;

        > * {
          margin-right: 0.5em;
          margin-bottom: 0.5em;
        }
      }
    }
  }


  //-----------------------------------------
  //  色見本
  //-----------------------------------------
  &[class*="-color"] {

    .card__head {

      img {
        display: block;
        width: 100%;
      }
    }

    .card__body {
      position: relative;
      margin-top: 1em;
      @media all and (-ms-high-contrast: none) {
        min-height: 0%;
      }
    }

    .card__label {
      display: flex;

      > * {
        width: 100%;
        margin-right: 0.5em;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .card__information {
      width: 100%;
      margin: 1em 0;

      dl {
        display: flex;
        width: 100%;
        padding: 0.5em 0;
        border-bottom: solid 1px $color-border;
        font-size: $font-size-sm;
        line-height: 1.4;

        dt {
          width: 5em;
          margin-right: 1em;
          font-weight: bold;

          &:after {
            content: '：';
          }
        }

        dd {
          width: calc(100% - 6em);
          word-wrap: break-word;
        }
      }

      .information__discription {
        width: 100%;
        border-bottom: solid 1px $color-border;
      }
    }
  }


  //-----------------------------------------
  //  お客様の声
  //-----------------------------------------
  &[class*="-voice"] {
    position: relative;
    background-color: $color-white;
    @include media-query(lg) {
      margin-bottom: 2rem;
      padding: 2rem;
    }
    @include media-query(sm) {
      margin-bottom: 1rem;
      padding: 1.5rem;
    }

    .card__user {
      width: 40px;
    }

    .card__name {
      margin-bottom: 0.4em;
    }

    .card__star {
      display: flex;
      align-items: center;

      img {
        width: 20px;
      }
    }

    &[class*="-md"] {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: solid 1px $color-border;
      @include media-query(lg) {
        flex-direction: row;
      }
      @include media-query(sm) {
        flex-direction: column;
      }

      .card__head {
        display: flex;
        align-items: center;
        @include media-query(lg) {
          margin-right: 3rem;
        }
        @include media-query(sm) {
          width: 100%;
        }
      }

      .card__body {
        @include media-query(lg) {
          width: 50%;
        }
        @include media-query(sm) {
          width: 100%;
        }
      }

      .card__user{
        width: 40px;
        margin-right: 2rem;
      }

      .card__infomation {
        // width: calc(100% - 56px - 3rem - 100px);
      }

      .card__star {
        @include media-query(lg) {
          margin: 0 auto;
        }
        @include media-query(sm) {
          margin-left: auto;
        }
      }

      &[class*="-simple"] {

        .card__head {
          @include media-query(lg) {
            width: calc(20% - 3rem);
          }
          @include media-query(sm) {
            width: 100%;
          }
        }

        .card__body {
          @include media-query(lg) {
            width: 80%;
          }
          @include media-query(sm) {
            width: 100%;
          }
        }
      }
    }

    &[class*="-sm"] {
      border: solid 1px $color-border;
      border-radius: 4px;


      .card__head {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .card__user{
        width: 40px;
      }

      .card__infomation {
        width: calc(100% - 40px - 100px);
        padding: 0 16px;
      }
    }
  }


  //-----------------------------------------
  //  導入事例
  //-----------------------------------------
  &[class*="-case"] {

    .card__body {
      margin-top: 1rem;
    }
  }


  //-----------------------------------------
  //  ブログ
  //-----------------------------------------
  &[class*="-blog"] {

    .card__body {
      position: relative;
      margin-top: 1em;
    }

    .card__category {
      position: absolute;
      top: 0;
      right: 0;
      text-align: right;
    }

    &[class*="-sm"] {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .card__head {
        width: 96px;
        height: 96px;
        margin-right: 16px;
      }

      .card__body {
        width: calc(100% - 96px - 16px);
        margin: 0;
      }

      .card__image {
        overflow: hidden;
        width: 96px;
        height: 96px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;

          .is-ie11 & {
            width: 100%;
            height:  auto;
            object-fit: contain;
          }
        }
      }

      .card__category {
        position: relative;
        text-align: left;
      }
    }
  }

  //-----------------------------------------
  //  ステップ
  //-----------------------------------------
  &[class*="-step"] {
    display: flex;
    align-items: center;
    width: 100%;
    background-color: lighten($color-step, 15%);
    border-radius: 0.4rem;
    border: none;
    @include media-query(sm) {
      margin-bottom: 1rem;
    }

    img {
      display: block;
      width: 100%;
    }

    .card__body {
      padding: 0;
    }

    .card__title {
      margin-bottom: 1em;
    }

    .card__step {
      display: inline-block;
      width: auto;
      padding: 0.2em 0.8em;
      margin-right: 1em;
      margin-bottom: 1em;
      border-radius: 0.4rem;
      background-color: $color-primary;
      color: $color-white;
      font-size: 0.8em;
      font-family: $font-family-number;
      font-weight: bold;
      vertical-align: middle;
    }

    .card__step-secondary {
      display: inline-block;
      width: auto;
      padding: 0.2em 0.8em;
      margin-right: 1em;
      margin-bottom: 1em;
      border-radius: 0.4rem;
      background-color: $color-secondary;
      color: $color-white;
      font-size: 0.8em;
      font-family: $font-family-number;
      font-weight: bold;
      vertical-align: middle;
    }


    .card__discription {
      margin: 0;

      > * {
        margin-bottom: 1em;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .discription__sub {
        margin-top: 1.5em;
        margin-bottom: 1.5em;

        &:last-child {
          margin-bottom: 0;
        }

        > * {
          margin-bottom: 0.5em;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    &[class*="-horizontal"] {
      @include media-query(lg) {
        justify-content: space-between;
        flex-direction: row;
        padding: 2rem 4rem;
      }
      @include media-query(sm) {
        flex-direction: column;
        padding: 2rem 2rem;
      }

      .card__head {
        @include media-query(lg) {
          width: calc((100% - 4rem) / 2);
          margin-right: 4rem;
          // margin-bottom: 0;
          // padding-bottom: 2rem;
        }
        @include media-query(sm) {
          width: 100%;
          margin-bottom: 2rem;
        }
      }

      .card__body {
        @include media-query(lg) {
          width: calc((100% - 4rem) / 2);
        }
        @include media-query(sm) {
          width: 80%;
          margin: 0 auto;
        }
      }
    }

    &[class*="-vertical"] {
      flex-direction: column;
      padding: 2rem 2rem;

      .card__head {
        width: 100%;
        margin-right: 0;
        margin-bottom: 1rem;
      }

      .card__body {
        width: 100%;
      }
    }
  }
}

a[class*="c-card-"] {

  &:hover {
    text-decoration: none !important;
    opacity: 0.6;
  }
}
