/*===========================================
    u-clearfix
===========================================*/
.u-clearfix {

  &:after {
    display: block;
    clear: both;
    height: 0;
    font-size: 0.1em;
    line-height: 0;
    content: "";
  }
}
