/*===========================================
    js-imageSwitch
===========================================*/
.js-imageSwitch {
  // position: relative;
}

.js-imageSwitch__target {
  position: relative;
  // overflow: hidden;
  margin-bottom: 16px;

  > * {
    // @include transition();
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    overflow: hidden;
    width: 100%;
    opacity: 0;

    &.is-active {
      position: relative;
      z-index: 1;
      opacity: 1;
    }
  }
}

.js-imageSwitch__thumbnail {
  display: flex;
  justify-content: center;

  > * {
    @include transition;
    position: relative;
    overflow: hidden;
    width: 48px;
    margin-right: 8px;
    cursor: pointer;
    
    &:after {
      @include transition;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      transform: scale(1.4);
      display: block;
      width: calc(100% - 8px);
      height: calc(100% - 8px);
      border: solid 4px $color-primary;
    }

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      opacity: 0.6;
    }

    &.is-active {

      &:after {
        transform: scale(1);
      }
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

[class*="js-imageSwitch__item"] {
  position: relative;

  &:before {
    content: '';
    display: block;
    width: 100%;
    padding-top: calc(100% * 0.637);
  }

  img, iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  img {
    display: block;
    width: auto;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }

  iframe {
    display: block;
    width: 100%;
    height: 100%;
  }

  &[class*="-thumb"] {

    &:before {
      padding-top: 100%;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
