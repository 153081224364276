/*===========================================
    c-icon
===========================================*/

%icon-border {
  @include transition;
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  height: 2px;
  background-color: $color-text;
}

//-------------------------------------------

[class*="c-icon"] {
  position: relative;
  display: block;
  // min-width: 24px;
  width: 2em;
  // min-height: 24px;
  height: 2em;
  // background-color: $color-white;
  cursor: pointer;

  a & {
    display: inline-block;
    vertical-align: middle;
    margin-top: -0.1em;

    &:hover {
      opacity: 0.6;
    }
  }

  .icon__border {
    @extend %icon-border;
  }

  //-----------------------------------------
  //  menu
  //-----------------------------------------
  &[class*="-black"] {

    .icon__border,
    &:before,
    &:after {
      background-color: $color-black;
    }
  }

  &[class*="-white"] {

    .icon__border,
    &:before,
    &:after {
      background-color: $color-white;
    }
  }

  &[class*="-primary"] {

    .icon__border,
    &:before,
    &:after {
      background-color: $color-primary;
    }
  }

  &[class*="-secondary"] {

    .icon__border,
    &:before,
    &:after {
      background-color: $color-secondary;
    }
  }

  &[class*="-tertiary"] {

    .icon__border,
    &:before,
    &:after {
      background-color: $color-tertiary;
    }
  }

  &[class*="-success"] {

    .icon__border,
    &:before,
    &:after {
      background-color: $color-success;
    }
  }

  &[class*="-warning"] {

    .icon__border,
    &:before,
    &:after {
      background-color: $color-warning;
    }
  }

  &[class*="-danger"] {

    .icon__border,
    &:before,
    &:after {
      background-color: $color-danger;
    }
  }


  //-----------------------------------------
  //  menu
  //-----------------------------------------
  &[class*="-menu"] {

    .icon__border {

      &:nth-child(1) {
        top: 35%;
      }

      &:nth-child(2) {
      }

      &:nth-child(3) {
        top: 65%;
      }
    }

    a:hover & {

      .icon__border {

        &:nth-child(1) {
          top: 30%;
        }

        &:nth-child(2) {
        }

        &:nth-child(3) {
          top: 70%;
        }
      }
    }

    .is-active & {

      .icon__border {

        &:nth-child(1) {
          top: 50%;
          transform: translate(-50%, -50%) rotate(45deg);
        }

        &:nth-child(2) {
          opacity: 0;
        }

        &:nth-child(3) {
          top: 50%;
          transform: translate(-50%, -50%) rotate(-45deg);
        }
      }
    }

    a.is-active:hover & {

      .icon__border {
        top: 50%;
      }
    }
  }


  //-----------------------------------------
  //  toggle
  //-----------------------------------------
  &[class*="-toggle"] {
    transform: scale(0.8);
    border-radius: 50%;
    background-color: $color-primary;

    &:before {
      @extend %icon-border;
      transform: translate(-50%, -50%) rotate(-90deg);
      background-color: $color-white;
    }

    &:after {
      @extend %icon-border;
      background-color: $color-white;
    }

    a:hover & {

      &:before {
        transform: translate(-50%, -50%) rotate(180deg);
      }

      &:after {
        transform: translate(-50%, -50%) rotate(90deg);
      }
    }

    a.is-active & {

      &:before {
        transform: translate(-50%, -50%);
      }

      &:after {
        opacity: 0;
      }
    }
  }


  //-----------------------------------------
  //  cross
  //-----------------------------------------
  &[class*="-cross"] {

    &:before {
      @extend %icon-border;
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &:after {
      @extend %icon-border;
      transform: translate(-50%, -50%) rotate(135deg);
    }

    a.is-active &,
    a:hover & {

      &:before {
        transform: translate(-50%, -50%) rotate(135deg);
      }

      &:after {
        transform: translate(-50%, -50%) rotate(225deg);
      }
    }
  }


  //-----------------------------------------
  //  plus
  //-----------------------------------------
  &[class*="-plus"] {

    &:before {
      @extend %icon-border;
      transform: translate(-50%, -50%) rotate(90deg);
    }

    &:after {
      @extend %icon-border;
      transform: translate(-50%, -50%) rotate(0deg);
    }

    a.is-active &,
    a:hover & {

      &:before {
        transform: translate(-50%, -50%) rotate(180deg);
      }

      &:after {
        transform: translate(-50%, -50%) rotate(90deg);
      }
    }
  }


  //-----------------------------------------
  //  minus
  //-----------------------------------------
  &[class*="-minus"] {

    &:before {
      @extend %icon-border;
      transform: translate(-50%, -50%) rotate(0deg);
    }

    a.is-active &,
    a:hover & {

      &:before {
        transform: translate(-50%, -50%) rotate(180deg);
      }
    }
  }


  //-----------------------------------------
  //  arrow-t
  //-----------------------------------------
  &[class*="-arrow-t"] {

    &:before {
      @extend %icon-border;
      width: 35%;
      transform: translate(-100%, -0.25em) rotate(-45deg);
      transform-origin: right;
    }

    &:after {
      @extend %icon-border;
      width: 35%;
      transform: translate(0, -0.25em) rotate(45deg);
      transform-origin: left;
    }

    a:hover & {

      &:before {
        transform: translate(-100%, -0.4em) rotate(-45deg);
      }

      &:after {
        transform: translate(0, -0.4em) rotate(45deg);
      }
    }

    a.is-active & {

      &:before {
        transform: translate(-100%, 0.25em) rotate(45deg);
        transform-origin: right;
      }

      &:after {
        transform: translate(0, 0.25em) rotate(-45deg);
        transform-origin: left;
      }
    }
  }


  //-----------------------------------------
  //  arrow-r
  //-----------------------------------------
  &[class*="-arrow-r"] {

    &:before {
      @extend %icon-border;
      width: 1px;
      height: 35%;
      transform: translate(0.25em, -100%) rotate(-45deg);
      transform-origin: bottom;
    }

    &:after {
      @extend %icon-border;
      width: 1px;
      height: 35%;
      transform: translate(0.25em, 0) rotate(45deg);
      transform-origin: top;
    }

    a:hover & {

      &:before {
        transform: translate(0.4em, -100%) rotate(-45deg);
      }

      &:after {
        transform: translate(0.4em, 0) rotate(45deg);
      }
    }

    a.is-active & {

      &:before {
        transform: translate(-0.25em, -100%) rotate(45deg);
        transform-origin: bottom;
      }

      &:after {
        transform: translate(-0.25em, 0) rotate(-45deg);
        transform-origin: top;
      }
    }
  }


  //-----------------------------------------
  //  arrow-b
  //-----------------------------------------
  &[class*="-arrow-b"] {

    &:before {
      @extend %icon-border;
      width: 35%;
      transform: translate(-100%, 0.25em) rotate(45deg);
      transform-origin: right;
    }

    &:after {
      @extend %icon-border;
      width: 35%;
      transform: translate(0, 0.25em) rotate(-45deg);
      transform-origin: left;
    }

    a:hover & {

      &:before {
        transform: translate(-100%, 0.4em) rotate(45deg);
      }

      &:after {
        transform: translate(0, 0.4em) rotate(-45deg);
      }
    }

    a.is-active & {

      &:before {
        transform: translate(-100%, -0.25em) rotate(-45deg);
        transform-origin: right;
      }

      &:after {
        transform: translate(0, -0.25em) rotate(45deg);
        transform-origin: left;
      }
    }
  }


  //-----------------------------------------
  //  arrow-l
  //-----------------------------------------
  &[class*="-arrow-l"] {

    &:before {
      @extend %icon-border;
      width: 1px;
      height: 35%;
      transform: translate(-0.25em, -100%) rotate(45deg);
      transform-origin: bottom;
    }

    &:after {
      @extend %icon-border;
      width: 1px;
      height: 35%;
      transform: translate(-0.25em, 0) rotate(-45deg);
      transform-origin: top;
    }

    a:hover & {

      &:before {
        transform: translate(-0.4em, -100%) rotate(45deg);
      }

      &:after {
        transform: translate(-0.4em, 0) rotate(-45deg);
      }
    }

    a.is-active & {

      &:before {
        transform: translate(0.25em, -100%) rotate(-45deg);
        transform-origin: bottom;
      }

      &:after {
        transform: translate(0.25em, 0) rotate(45deg);
        transform-origin: top;
      }
    }
  }


  //-----------------------------------------
  //  triangle-t
  //-----------------------------------------
  &[class*="-triangle-t"] {
    width: 1em;

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 0.4em 0.5em 0.4em;
      border-color: transparent transparent $color-primary transparent;
    }
  }


  //-----------------------------------------
  //  triangle-r
  //-----------------------------------------
  &[class*="-triangle-r"] {
    width: 1em;

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0.4em 0 0.4em 0.5em;
      border-color: transparent transparent transparent $color-primary;
    }
  }


  //-----------------------------------------
  //  triangle-b
  //-----------------------------------------
  &[class*="-triangle-b"] {
    width: 1em;

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0.5em 0.4em 0 0.4em;
      border-color: $color-primary transparent transparent transparent;
    }
  }


  //-----------------------------------------
  //  triangle-l
  //-----------------------------------------
  &[class*="-triangle-l"] {
    width: 1em;

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0.4em 0.5em 0.4em 0;
      border-color: transparent $color-primary transparent transparent;
    }
  }


  //-----------------------------------------
  //  question
  //-----------------------------------------
  &[class*="-question"] {
    border-radius: 50%;
    background-color: $color-primary;

    &:before {
      content: "?";
      display: flex;
      justify-content: center;
      align-items: center;
      color: $color-white;
      font-family: $font-family-number;
      font-weight: bold;
      line-height: 2;
    }
  }


  //-----------------------------------------
  //  number
  //-----------------------------------------
  &[class*="-number"] {
    // display: flex;
    // justify-content: center;
    // align-items: center;
    display: inline-block;
    width: 1.4em;
    height: 1.4em;
    margin: 0.4em 0 0.2em 0;
    // margin-right: 0.5em;
    border-radius: 50%;
    background-color: $color-text;
    color: $color-white;
    font-family: $font-family-number;
    font-size: 0.8em;
    font-weight: bold;
    text-align: center;
    line-height: 1.6;
    cursor: auto;

    &[class*="-primary"]{
      background-color: $color-primary;
    }
  }

  //-----------------------------------------
  //  サイズ
  //-----------------------------------------
  &[class*="-xl"] {
    font-size: $font-size-xl;
  }

  &[class*="-lg"] {
    font-size: $font-size-lg;
  }

  &[class*="-md"] {
    font-size: $font-size-md;
  }

  &[class*="-sm"] {
    font-size: $font-size-sm;
  }

  &[class*="-xs"] {
    font-size: $font-size-xs;
  }
}
