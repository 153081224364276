/*===========================================
    c-recommend
===========================================*/
[class*="c-recommend"] {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: auto;

  .recommend__title {
    margin-right: 0.5em;
    font-size: $font-size-sm;
    font-weight: bold;
  }

  .recommend__content {
    width: auto;

    .content__image {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      img {
        display: block;
        width: auto;
        height: 100%;
      }
    }

    .content__number {
      font-family: $font-family-number;
      font-size: 2.0rem;
      // font-weight: bold;

      span {
        font-size: 0.6em;
        // font-weight: bold;
        vertical-align: baseline;
      }
    }
  }
}
