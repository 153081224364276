/*===========================================
    js-loading
===========================================*/
.js-loading {
  @include transition;
  opacity: 1;

  &.is-active {
    opacity: 0;
    pointer-events: none;
  }
}
