/*===========================================
    c-textarea
===========================================*/
[class*="c-textarea"] {
  textarea{
    height: 400px;

    @include media-query(sm){
      height: 100px;
    }
  }
}
