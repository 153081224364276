/*===========================================
    js-tab
===========================================*/
.js-tab {
}

.js-tab__navigation {
}

.js-tab__trigger {
}

.js-tab__content {
  position: relative;
}

.js-tab__item {
  @include transition;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;

  &.is-active {
    position: relative;
    opacity: 1;
    pointer-events: all;
  }
}
