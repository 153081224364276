@charset "UTF-8";
/*===========================================
    c-form
===========================================*/
[class*="c-form"] {
  width: 100%;
}

[class*="c-form"] input,
[class*="c-form"] select,
[class*="c-form"] textarea {
  border: none;
  outline: none;
  appearance: none;
}

[class*="c-form"] button {
  border: none;
  appearance: none;
}

[class*="c-form"] input[type="checkbox"] {
  margin-right: 0;
  appearance: checkbox;
  cursor: pointer;
}

[class*="c-form"] input[type="radio"] {
  margin-right: 0;
  appearance: radio;
  cursor: pointer;
}

[class*="c-form"] input[type="number"]::-webkit-outer-spin-button, [class*="c-form"] input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

[class*="c-form"] input[type="search"] {
  -webkit-appearance: textfield;
}

[class*="c-form"] input[type="search"]:focus {
  outline-offset: -2px;
}

[class*="c-form"] input[type="search"]::-webkit-search-cancel-button, [class*="c-form"] input[type="search"]::-webkit-search-decoration, [class*="c-form"] input[type="search"]::-webkit-search-results-button, [class*="c-form"] input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

[class*="c-form"] input[type="range"] {
  -webkit-appearance: none;
}

[class*="c-form"] input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
}

[class*="c-form"] textarea {
  resize: none;
}

[class*="c-form"] .form__inline .inline__input {
  width: calc(100% - 2.5rem - 1px);
}

[class*="c-form"] .form__inline .inline__input > * {
  border-radius: 4px 0 0 4px;
}

[class*="c-form"] .form__inline .inline__unit {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  border: solid 1px #c4c4c4;
  border-left: none;
  border-radius: 0 4px 4px 0;
  background-color: #f3f3f3;
  font-size: 0.733rem;
}

[class*="c-form"] *:after, [class*="c-form"] *:before {
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

[class*="c-form"] input,
[class*="c-form"] textarea,
[class*="c-form"] select {
  font: inherit;
}

[class*="c-form"] label {
  display: inline-block;
  cursor: pointer;
}

[class*="c-form"] select::-ms-expand {
  display: none;
}

[class*="c-form"] [class*="form__input"] {
  display: block;
  margin-right: 0;
}

[class*="c-form"] [class*="form__input"]:last-child {
  margin-right: 0;
}

[class*="c-form"] [class*="form__input"][class*="-inline"] {
  display: inline-block;
}

[class*="c-form"] input[type="text"],
[class*="c-form"] input[type="password"],
[class*="c-form"] input[type="tel"],
[class*="c-form"] input[type="url"],
[class*="c-form"] input[type="email"],
[class*="c-form"] input[type="search"],
[class*="c-form"] input[type="date"],
[class*="c-form"] input[type="time"],
[class*="c-form"] input[type="number"] {
  position: relative;
  display: block;
  width: 100%;
  padding: 0.4em 0.6em;
  background-color: #fff;
  border: solid 1px #c4c4c4;
  border-radius: 4px;
  color: #333;
  letter-spacing: 0.1em;
}

@media screen and (min-width: 601px), print {
  [class*="c-form"] input[type="text"],
  [class*="c-form"] input[type="password"],
  [class*="c-form"] input[type="tel"],
  [class*="c-form"] input[type="url"],
  [class*="c-form"] input[type="email"],
  [class*="c-form"] input[type="search"],
  [class*="c-form"] input[type="date"],
  [class*="c-form"] input[type="time"],
  [class*="c-form"] input[type="number"] {
    font-size: 15px;
  }
}

@media screen and (max-width: 600px) {
  [class*="c-form"] input[type="text"],
  [class*="c-form"] input[type="password"],
  [class*="c-form"] input[type="tel"],
  [class*="c-form"] input[type="url"],
  [class*="c-form"] input[type="email"],
  [class*="c-form"] input[type="search"],
  [class*="c-form"] input[type="date"],
  [class*="c-form"] input[type="time"],
  [class*="c-form"] input[type="number"] {
    font-size: 16px;
  }
}

[class*="c-form"] input[type="text"]:focus,
[class*="c-form"] input[type="password"]:focus,
[class*="c-form"] input[type="tel"]:focus,
[class*="c-form"] input[type="url"]:focus,
[class*="c-form"] input[type="email"]:focus,
[class*="c-form"] input[type="search"]:focus,
[class*="c-form"] input[type="date"]:focus,
[class*="c-form"] input[type="time"]:focus,
[class*="c-form"] input[type="number"]:focus {
  z-index: 100;
  border: solid 1px #009A98;
  background-color: rgba(0, 154, 152, 0.1);
  box-shadow: 0 0 5px rgba(0, 154, 152, 0.4);
}

[class*="c-form"] input[type="text"]:disabled,
[class*="c-form"] input[type="password"]:disabled,
[class*="c-form"] input[type="tel"]:disabled,
[class*="c-form"] input[type="url"]:disabled,
[class*="c-form"] input[type="email"]:disabled,
[class*="c-form"] input[type="search"]:disabled,
[class*="c-form"] input[type="date"]:disabled,
[class*="c-form"] input[type="time"]:disabled,
[class*="c-form"] input[type="number"]:disabled {
  background-color: #e6e6e6;
  color: #999999;
}

[class*="c-form"] input[type="text"]::placeholder,
[class*="c-form"] input[type="password"]::placeholder,
[class*="c-form"] input[type="tel"]::placeholder,
[class*="c-form"] input[type="url"]::placeholder,
[class*="c-form"] input[type="email"]::placeholder,
[class*="c-form"] input[type="search"]::placeholder,
[class*="c-form"] input[type="date"]::placeholder,
[class*="c-form"] input[type="time"]::placeholder,
[class*="c-form"] input[type="number"]::placeholder {
  color: #999999;
}

[class*="c-form"] input[type="text"].form__output,
[class*="c-form"] input[type="password"].form__output,
[class*="c-form"] input[type="tel"].form__output,
[class*="c-form"] input[type="url"].form__output,
[class*="c-form"] input[type="email"].form__output,
[class*="c-form"] input[type="search"].form__output,
[class*="c-form"] input[type="date"].form__output,
[class*="c-form"] input[type="time"].form__output,
[class*="c-form"] input[type="number"].form__output {
  margin-bottom: 0;
  padding: 0.3em 0;
  background-color: transparent;
  border: none;
  pointer-events: none;
}

[class*="c-form"] [class*="form__file"] {
  display: flex;
  flex-wrap: nowrap;
}

[class*="c-form"] [class*="form__file"] input {
  display: none;
}

[class*="c-form"] [class*="form__file"][class*="-inline"] {
  display: inline-block;
}

[class*="c-form"] [class*="form__file"] [class*="file__button"] {
  position: relative;
  display: flex;
  padding-left: 1.6em;
  border-radius: 4px 0 0 4px;
  border: solid 1px #c4c4c4;
  font-weight: normal;
}

@media screen and (min-width: 601px), print {
  [class*="c-form"] [class*="form__file"] [class*="file__button"] {
    font-size: 15px;
  }
}

@media screen and (max-width: 600px) {
  [class*="c-form"] [class*="form__file"] [class*="file__button"] {
    padding-left: 1em;
    font-size: 16px;
  }
}

[class*="c-form"] [class*="form__file"] [class*="file__button"]:after {
  content: '';
  position: absolute;
  top: 50%;
  right: 0.6em;
  z-index: 1;
  transform: translate(0, -50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0.3em 0.2em 0 0.2em;
  border-color: #333 transparent transparent transparent;
}

[class*="c-form"] [class*="form__file"] [class*="file__name"] {
  display: flex;
  align-items: center;
  overflow: hidden;
  width: calc(100% - 5em);
  padding: 0.4em 0.6em;
  background-color: #fff;
  border: solid 1px #c4c4c4;
  border-left: 0;
  border-radius: 0 4px 4px 0;
  color: #333;
  word-break: break-all;
}

@media screen and (min-width: 601px), print {
  [class*="c-form"] [class*="form__file"] [class*="file__name"] {
    font-size: 15px;
  }
}

@media screen and (max-width: 600px) {
  [class*="c-form"] [class*="form__file"] [class*="file__name"] {
    font-size: 16px;
  }
}

[class*="c-form"] [class*="form__number"] {
  display: flex;
  flex-wrap: nowrap;
}

[class*="c-form"] [class*="form__number"] input {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  display: flex;
  align-items: center;
  width: 6em;
  padding: 0.4em 0.6em;
  margin: 0;
  background-color: #fff;
  border: solid 1px #c4c4c4;
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  color: #333;
  text-align: center;
}

@media screen and (min-width: 601px), print {
  [class*="c-form"] [class*="form__number"] input {
    font-size: 15px;
  }
}

@media screen and (max-width: 600px) {
  [class*="c-form"] [class*="form__number"] input {
    font-size: 16px;
  }
}

[class*="c-form"] [class*="form__number"] input:focus {
  z-index: 100;
  border: solid 1px #009A98;
  background-color: rgba(0, 154, 152, 0.1);
  box-shadow: 0 0 5px rgba(0, 154, 152, 0.4);
}

[class*="c-form"] [class*="form__number"][class*="-inline"] {
  display: inline-block;
}

[class*="c-form"] [class*="form__number"] [class*="number__button"] {
  position: relative;
  display: flex;
  width: 3em;
  padding: 0;
  border: solid 1px #c4c4c4;
  font-size: 0.8rem;
  font-weight: normal;
}

@media screen and (max-width: 600px) {
  [class*="c-form"] [class*="form__number"] [class*="number__button"] {
    align-items: center;
    justify-content: center;
  }
}

[class*="c-form"] [class*="form__number"] [class*="number__button"][class*="-plus"] {
  border-radius: 4px 0 0 4px;
}

[class*="c-form"] [class*="form__number"] [class*="number__button"][class*="-minus"] {
  border-radius: 0 4px 4px 0;
}

[class*="c-form"] [class*="form__checkbox"] {
  position: relative;
  display: inline-block;
  padding-left: 1.8em;
}

@media screen and (min-width: 601px), print {
  [class*="c-form"] [class*="form__checkbox"] {
    font-size: 15px;
  }
}

@media screen and (max-width: 600px) {
  [class*="c-form"] [class*="form__checkbox"] {
    font-size: 16px;
  }
}

[class*="c-form"] [class*="form__checkbox"] [class*="checkbox__button"] {
  position: absolute;
  top: 0.9em;
  left: 0;
  transform: translate(0, -50%);
  display: block;
  width: 1.4em;
  height: 1.4em;
  border: solid 1px #c4c4c4;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
}

[class*="c-form"] [class*="form__checkbox"] [class*="checkbox__button"]:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -70%) rotate(-45deg) scale(0);
  width: 0.6em;
  height: 0.3em;
  border-left: solid 0.2em #009A98;
  border-bottom: solid 0.2em #009A98;
  line-height: 0;
  letter-spacing: 0;
  opacity: 0;
}

[class*="c-form"] [class*="form__checkbox"] span {
  font-weight: bold;
}

[class*="c-form"] [class*="form__checkbox"] input[type="checkbox"] {
  display: none;
}

[class*="c-form"] [class*="form__checkbox"] input[type="checkbox"]:checked + .checkbox__button:after {
  transform: translate(-50%, -70%) rotate(-45deg) scale(1);
  opacity: 1;
}

[class*="c-form"] [class*="form__checkbox"] input[type="checkbox"]:disabled + .checkbox__button, [class*="c-form"] [class*="form__checkbox"] input[type="checkbox"][disabled=""] + .checkbox__button {
  background-color: #e6e6e6;
}

[class*="c-form"] [class*="form__checkbox"] input[type="checkbox"]:disabled + .checkbox__button + span, [class*="c-form"] [class*="form__checkbox"] input[type="checkbox"][disabled=""] + .checkbox__button + span {
  opacity: 0.4;
}

[class*="c-form"] [class*="form__checkbox"][class*="-image"] {
  position: relative;
  padding-left: 0;
  text-align: center;
}

[class*="c-form"] [class*="form__checkbox"][class*="-image"] img {
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: relative;
  z-index: 0;
  display: block;
  margin-bottom: 0.5em;
}

[class*="c-form"] [class*="form__checkbox"][class*="-image"] img:hover {
  opacity: 0.6;
}

[class*="c-form"] [class*="form__checkbox"][class*="-image"] [class*="checkbox__button"] {
  position: absolute;
  top: 0.5em;
  left: 0.5em;
  z-index: 1;
  transform: translate(0);
}

[class*="c-form"] [class*="form__radio"] {
  position: relative;
  display: inline-block;
  padding-left: 1.8em;
}

@media screen and (min-width: 601px), print {
  [class*="c-form"] [class*="form__radio"] {
    font-size: 15px;
  }
}

@media screen and (max-width: 600px) {
  [class*="c-form"] [class*="form__radio"] {
    font-size: 16px;
  }
}

[class*="c-form"] [class*="form__radio"] [class*="radio__button"] {
  position: absolute;
  top: 0.9em;
  left: 0;
  transform: translate(0, -50%);
  display: block;
  width: 1.4em;
  height: 1.4em;
  border: solid 1px #c4c4c4;
  border-radius: 50%;
  background-color: #fff;
  cursor: pointer;
}

[class*="c-form"] [class*="form__radio"] [class*="radio__button"]:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
  background-color: #009A98;
  line-height: 0;
  letter-spacing: 0;
  opacity: 0;
}

[class*="c-form"] [class*="form__radio"] input[type="radio"] {
  display: none;
}

[class*="c-form"] [class*="form__radio"] input[type="radio"]:checked + .radio__button:after {
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
}

[class*="c-form"] [class*="form__radio"] input[type="radio"]:disabled + .checkbox__button, [class*="c-form"] [class*="form__radio"] input[type="radio"][disabled=""] + .checkbox__button {
  background-color: #e6e6e6;
}

[class*="c-form"] [class*="form__radio"] input[type="radio"]:disabled + .checkbox__button + span, [class*="c-form"] [class*="form__radio"] input[type="radio"][disabled=""] + .checkbox__button + span {
  opacity: 0.4;
}

[class*="c-form"] [class*="form__radio"][class*="-image"] {
  position: relative;
  padding-left: 0;
  text-align: center;
}

[class*="c-form"] [class*="form__radio"][class*="-image"] img {
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: relative;
  z-index: 0;
  display: block;
  margin-bottom: 0.5em;
}

[class*="c-form"] [class*="form__radio"][class*="-image"] img:hover {
  opacity: 0.6;
}

@media all and (-ms-high-contrast: none) {
  [class*="c-form"] [class*="form__radio"][class*="-image"] img img {
    max-width: 240px;
    height: auto;
  }
}

[class*="c-form"] [class*="form__radio"][class*="-image"] [class*="radio__button"] {
  position: absolute;
  top: 0.5em;
  left: 0.5em;
  z-index: 1;
  transform: translate(0);
}

[class*="c-form"] [class*="form__select"] {
  position: relative;
  display: block;
  margin-right: 0;
}

[class*="c-form"] [class*="form__select"]:last-child {
  margin-right: 0;
}

[class*="c-form"] [class*="form__select"][class*="-inline"] {
  display: inline-block;
}

[class*="c-form"] [class*="form__select"] select {
  position: relative;
  display: block;
  width: 100%;
  padding: 0.4em 0.6em;
  padding-right: 2em;
  background-color: #fff;
  border: solid 1px #c4c4c4;
  border-radius: 4px;
  color: #333;
  letter-spacing: 0.1em;
  cursor: pointer;
}

@media screen and (min-width: 601px), print {
  [class*="c-form"] [class*="form__select"] select {
    font-size: 15px;
  }
}

@media screen and (max-width: 600px) {
  [class*="c-form"] [class*="form__select"] select {
    font-size: 16px;
  }
}

[class*="c-form"] [class*="form__select"] select:focus {
  z-index: 100;
  background-color: rgba(0, 154, 152, 0.1);
  border: solid 1px #009A98;
  box-shadow: 0 0 5px rgba(0, 154, 152, 0.4);
}

[class*="c-form"] [class*="form__select"] select option:disabled {
  color: #999999;
}

[class*="c-form"] [class*="form__select"] select:disabled {
  background-color: #e6e6e6;
  color: #999999;
}

[class*="c-form"] [class*="form__select"]:before {
  content: '';
  position: absolute;
  top: 40%;
  right: 0.6em;
  z-index: 1;
  transform: translate(0, -50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0.2em 0.3em 0.2em;
  border-color: transparent transparent #333 transparent;
}

[class*="c-form"] [class*="form__select"]:after {
  content: '';
  position: absolute;
  top: 60%;
  right: 0.6em;
  z-index: 1;
  transform: translate(0, -50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0.3em 0.2em 0 0.2em;
  border-color: #333 transparent transparent transparent;
}

[class*="c-form"] [class*="form__select"][class*="-inline"] {
  display: inline-block;
}

[class*="c-form"] [class*="form__textarea"] {
  display: block;
  margin-right: 0;
}

[class*="c-form"] [class*="form__textarea"]:last-child {
  margin-right: 0;
}

[class*="c-form"] [class*="form__textarea"][class*="-inline"] {
  display: inline-block;
}

[class*="c-form"] [class*="form__textarea"] textarea {
  display: block;
  width: 100%;
  padding: 0.4em 0.6em;
  background-color: #fff;
  border: solid 1px #c4c4c4;
  border-radius: 4px;
  color: #333;
}

@media screen and (min-width: 601px), print {
  [class*="c-form"] [class*="form__textarea"] textarea {
    font-size: 15px;
  }
}

@media screen and (max-width: 600px) {
  [class*="c-form"] [class*="form__textarea"] textarea {
    font-size: 16px;
  }
}

[class*="c-form"] [class*="form__textarea"] textarea:focus {
  z-index: 100;
  background-color: rgba(0, 154, 152, 0.1);
  border: solid 1px #009A98;
  box-shadow: 0 0 5px rgba(0, 154, 152, 0.4);
}

[class*="c-form"] .form__error {
  position: relative;
  display: inline-block;
}

[class*="c-form"] .form__error .error__text p {
  color: #DE1816;
  font-size: 15px;
}

[class*="c-form"] .is-error {
  background: #fcf0f1 !important;
}

[class*="c-form"] .form__sizeItem {
  width: 15%;
}

@media screen and (max-width: 600px) {
  [class*="c-form"] .form__sizeItem {
    width: 6em;
  }
}

[class*="c-form"] .form__sizeFlame {
  width: 30%;
}

@media screen and (max-width: 600px) {
  [class*="c-form"] .form__sizeFlame {
    width: 40%;
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-form"] .form__half {
    width: 50%;
  }
}

[class*="c-form"] .form__logo {
  width: 30%;
}

@media screen and (max-width: 600px) {
  [class*="c-form"] .form__logo {
    width: 13rem;
  }
}

[class*="c-form"] .form__orderday {
  width: 15%;
}

@media screen and (max-width: 600px) {
  [class*="c-form"] .form__orderday {
    width: 6rem;
  }
}

[class*="c-form"] .form__orderday input {
  min-width: auto  !important;
}

@media screen and (max-width: 600px) {
  [class*="c-form"] .form__orderdata .form__input {
    width: 30% !important;
  }
}

/*===========================================
    c-heading
===========================================*/
[class*="c-heading"] {
  position: relative;
  margin-bottom: 0.8em;
  color: #333;
  font-weight: bold;
  line-height: 1.4;
}

[class*="c-heading"] [class*="heading__sub"] {
  color: #333;
  font-size: 0.8em;
  font-family: "メイリオ", Meiryo, "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "ＭＳ Ｐゴシック", sans-serif;
  font-weight: bold;
  vertical-align: middle;
}

[class*="c-heading"] [class*="heading__sub"][class*="-before"] {
  margin-right: 1em;
}

[class*="c-heading"] [class*="heading__sub"][class*="-after"] {
  margin-left: 1em;
}

[class*="c-heading"] [class*="heading__sub"][class*="-block"] {
  display: block;
  margin-top: 0.4em;
  margin-bottom: 0.4em;
  text-align: inherit;
}

[class*="c-heading"] [class*="heading__icon"] {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  font-size: 0.5em;
}

[class*="c-heading"] [class*="heading__image"] {
  width: 2em;
  height: 2em;
  margin-right: 0.2em;
  vertical-align: middle;
}

[class*="c-heading"][class*="-black"] {
  color: #000;
}

[class*="c-heading"][class*="-white"] {
  color: #fff;
}

[class*="c-heading"][class*="-primary"] {
  color: #009A98;
}

[class*="c-heading"][class*="-secondary"] {
  color: #1DAEE9;
}

@media screen and (min-width: 601px), print {
  [class*="c-heading"][class*="-xl"] {
    font-size: 2.285rem !important;
  }
}

@media screen and (max-width: 600px) {
  [class*="c-heading"][class*="-xl"] {
    font-size: 1.61rem !important;
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-heading"][class*="-lg"] {
    font-size: 2rem !important;
  }
}

@media screen and (max-width: 600px) {
  [class*="c-heading"][class*="-lg"] {
    font-size: 1.46rem !important;
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-heading"][class*="-md"] {
    font-size: 1.6rem !important;
  }
}

@media screen and (max-width: 600px) {
  [class*="c-heading"][class*="-md"] {
    font-size: 1.30rem !important;
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-heading"][class*="-sm"] {
    font-size: 1.2rem !important;
  }
}

@media screen and (max-width: 600px) {
  [class*="c-heading"][class*="-sm"] {
    font-size: 1.15rem !important;
  }
}

[class*="c-heading"][class*="-xs"] {
  font-size: 1.0rem !important;
}

[class*="c-heading"][class*="-center"] {
  text-align: center;
}

[class*="c-heading"][class*="-left"] {
  text-align: left;
}

[class*="c-heading"][class*="-right"] {
  text-align: right;
}

[class*="c-heading"][class*="-border"]::before {
  content: '';
  display: inline-block;
  width: 4px;
  height: 1em;
  margin-right: 0.5em;
  background-color: #009A98;
}

[class*="c-heading"][class*="-block"]::before {
  content: '■';
  margin-right: 0.2rem;
}

/*===========================================
    c-text
===========================================*/
[class*="c-text"] {
  font-weight: nomal;
}

[class*="c-text"][class*="-xl"] {
  font-size: 1.266rem !important;
}

[class*="c-text"][class*="-lg"] {
  font-size: 1.133rem !important;
}

[class*="c-text"][class*="-md"] {
  font-size: 1rem !important;
}

[class*="c-text"][class*="-sm"] {
  font-size: 0.866rem !important;
}

[class*="c-text"][class*="-xs"] {
  font-size: 0.733rem !important;
}

[class*="c-text"][class*="-center"] {
  text-align: center;
}

[class*="c-text"][class*="-left"] {
  text-align: left;
}

[class*="c-text"][class*="-right"] {
  text-align: right;
}

[class*="c-text"][class*="-number"] {
  font-family: "Roboto", sans-serif;
}

[class*="c-text"][class*="-weightBold"] {
  font-weight: bold;
}

[class*="c-text"][class*="-weightNomal"] {
  font-weight: nomal;
}

[class*="c-text"][class*="-italic"] {
  font-style: italic;
}

[class*="c-text"][class*="-underline"] {
  text-decoration: underline;
}

[class*="c-text"][class*="-highlight"] {
  background-color: rgba(102, 102, 102, 0.2);
}

[class*="c-text"][class*="-note"] {
  padding-left: 1em;
  text-indent: -1em;
}

[class*="c-text"][class*="-note"]:before {
  content: '※';
}

[class*="c-text"][class*="-primary"] {
  color: #009A98;
}

[class*="c-text"][class*="-secondary"] {
  color: #1DAEE9;
}

[class*="c-text"][class*="-success"] {
  color: #06CDB5 !important;
}

[class*="c-text"][class*="-warning"] {
  color: #E68857 !important;
}

[class*="c-text"][class*="-danger"] {
  color: #DE1816 !important;
}

/*===========================================
    c-subscript
===========================================*/
[class*="c-subscript"] {
  font-size: 0.75em;
  font-weight: inherit;
}

[class*="c-subscript"][class*="-t"] {
  vertical-align: top;
}

[class*="c-subscript"][class*="-m"] {
  vertical-align: middle;
}

[class*="c-subscript"][class*="-b"] {
  vertical-align: baseline;
}

/*===========================================
    c-link
===========================================*/
[class*="c-link"] {
  position: relative;
  color: #009A98;
}

[class*="c-link"] [class*="link__icon"] {
  margin-top: -0.3em;
  font-size: 0.8em;
  vertical-align: middle;
}

[class*="c-link"] [class*="link__icon"][class*="-before"] {
  margin-right: 0;
}

[class*="c-link"] [class*="link__icon"][class*="-after"] {
  margin-left: 0.4em;
}

/*===========================================
    c-list
===========================================*/
[class*="c-list"] .list__item {
  margin-bottom: 0.4em;
  padding-left: 0;
  text-indent: 0;
  list-style-position: inside;
}

[class*="c-list"] .list__item:last-child {
  margin-bottom: 0;
}

[class*="c-list"] .list__item > ul,
[class*="c-list"] .list__item > ol {
  margin: 0.5em 1em;
}

[class*="c-list"] .list__item > a {
  color: #009A98;
}

[class*="c-list"] .list__item th, [class*="c-list"] .list__item td {
  text-indent: 0;
}

[class*="c-list"][class*="-xl"] {
  font-size: 1.266rem;
}

[class*="c-list"][class*="-lg"] {
  font-size: 1.133rem;
}

[class*="c-list"][class*="-md"] {
  font-size: 1rem;
}

[class*="c-list"][class*="-sm"] {
  font-size: 0.866rem;
}

[class*="c-list"][class*="-xs"] {
  font-size: 0.733rem;
}

[class*="c-list"][class*="-disc"] .list__item {
  padding-left: 1em;
  text-indent: -1em;
  list-style-type: disc;
}

[class*="c-list"][class*="-number"] .list__item {
  padding-left: 1em;
  text-indent: -1em;
}

[class*="c-list"][class*="-number"] .list__item i {
  white-space: nowrap;
}

[class*="c-list"][class*="-note"] > .list__item {
  padding-left: 1em;
  text-indent: -1em;
}

[class*="c-list"][class*="-note"] > .list__item:before {
  content: '※';
}

[class*="c-list"][class*="-icon"] .list__item {
  position: relative;
  padding-left: 1.4em;
}

[class*="c-list"][class*="-icon"] .list__item i {
  position: absolute;
  left: 0;
  vertical-align: middle;
}

[class*="c-list"][class*="-block"] {
  overflow: hidden;
  border: solid 1px #ddd;
  border-radius: 4px;
  background-color: #fff;
}

[class*="c-list"][class*="-block"] .list__item {
  position: relative;
  margin: 0;
  border-bottom: solid 1px #ddd;
}

[class*="c-list"][class*="-block"] .list__item:last-child {
  border: none;
}

[class*="c-list"][class*="-block"] .list__item > * {
  padding: 0.6em 1em;
}

[class*="c-list"][class*="-block"] .list__item > a {
  display: block;
  color: #009A98;
}

[class*="c-list"][class*="-block"] .list__item > a:hover {
  text-decoration: none;
  background-color: #34fffc;
}

[class*="c-list"][class*="-block"] .list__item > ul,
[class*="c-list"][class*="-block"] .list__item > ol {
  margin: 0;
  padding: 0;
  border-top: solid 1px #ddd;
  background-color: #f7f7f7;
}

/*===========================================
    c-icon
===========================================*/
[class*="c-icon"] .icon__border, [class*="c-icon"][class*="-toggle"]:before, [class*="c-icon"][class*="-toggle"]:after, [class*="c-icon"][class*="-cross"]:before, [class*="c-icon"][class*="-cross"]:after, [class*="c-icon"][class*="-plus"]:before, [class*="c-icon"][class*="-plus"]:after, [class*="c-icon"][class*="-minus"]:before, [class*="c-icon"][class*="-arrow-t"]:before, [class*="c-icon"][class*="-arrow-t"]:after, [class*="c-icon"][class*="-arrow-r"]:before, [class*="c-icon"][class*="-arrow-r"]:after, [class*="c-icon"][class*="-arrow-b"]:before, [class*="c-icon"][class*="-arrow-b"]:after, [class*="c-icon"][class*="-arrow-l"]:before, [class*="c-icon"][class*="-arrow-l"]:after {
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  height: 2px;
  background-color: #333;
}

[class*="c-icon"] {
  position: relative;
  display: block;
  width: 2em;
  height: 2em;
  cursor: pointer;
}

a [class*="c-icon"] {
  display: inline-block;
  vertical-align: middle;
  margin-top: -0.1em;
}

a [class*="c-icon"]:hover {
  opacity: 0.6;
}

[class*="c-icon"][class*="-black"] .icon__border, [class*="c-icon"][class*="-black"]:before, [class*="c-icon"][class*="-black"]:after {
  background-color: #000;
}

[class*="c-icon"][class*="-white"] .icon__border, [class*="c-icon"][class*="-white"]:before, [class*="c-icon"][class*="-white"]:after {
  background-color: #fff;
}

[class*="c-icon"][class*="-primary"] .icon__border, [class*="c-icon"][class*="-primary"]:before, [class*="c-icon"][class*="-primary"]:after {
  background-color: #009A98;
}

[class*="c-icon"][class*="-secondary"] .icon__border, [class*="c-icon"][class*="-secondary"]:before, [class*="c-icon"][class*="-secondary"]:after {
  background-color: #1DAEE9;
}

[class*="c-icon"][class*="-tertiary"] .icon__border, [class*="c-icon"][class*="-tertiary"]:before, [class*="c-icon"][class*="-tertiary"]:after {
  background-color: #847DB6;
}

[class*="c-icon"][class*="-success"] .icon__border, [class*="c-icon"][class*="-success"]:before, [class*="c-icon"][class*="-success"]:after {
  background-color: #06CDB5;
}

[class*="c-icon"][class*="-warning"] .icon__border, [class*="c-icon"][class*="-warning"]:before, [class*="c-icon"][class*="-warning"]:after {
  background-color: #E68857;
}

[class*="c-icon"][class*="-danger"] .icon__border, [class*="c-icon"][class*="-danger"]:before, [class*="c-icon"][class*="-danger"]:after {
  background-color: #DE1816;
}

[class*="c-icon"][class*="-menu"] .icon__border:nth-child(1) {
  top: 35%;
}

[class*="c-icon"][class*="-menu"] .icon__border:nth-child(3) {
  top: 65%;
}

a:hover [class*="c-icon"][class*="-menu"] .icon__border:nth-child(1) {
  top: 30%;
}

a:hover [class*="c-icon"][class*="-menu"] .icon__border:nth-child(3) {
  top: 70%;
}

.is-active [class*="c-icon"][class*="-menu"] .icon__border:nth-child(1) {
  top: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
}

.is-active [class*="c-icon"][class*="-menu"] .icon__border:nth-child(2) {
  opacity: 0;
}

.is-active [class*="c-icon"][class*="-menu"] .icon__border:nth-child(3) {
  top: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
}

a.is-active:hover [class*="c-icon"][class*="-menu"] .icon__border {
  top: 50%;
}

[class*="c-icon"][class*="-toggle"] {
  transform: scale(0.8);
  border-radius: 50%;
  background-color: #009A98;
}

[class*="c-icon"][class*="-toggle"]:before {
  transform: translate(-50%, -50%) rotate(-90deg);
  background-color: #fff;
}

[class*="c-icon"][class*="-toggle"]:after {
  background-color: #fff;
}

a:hover [class*="c-icon"][class*="-toggle"]:before {
  transform: translate(-50%, -50%) rotate(180deg);
}

a:hover [class*="c-icon"][class*="-toggle"]:after {
  transform: translate(-50%, -50%) rotate(90deg);
}

a.is-active [class*="c-icon"][class*="-toggle"]:before {
  transform: translate(-50%, -50%);
}

a.is-active [class*="c-icon"][class*="-toggle"]:after {
  opacity: 0;
}

[class*="c-icon"][class*="-cross"]:before {
  transform: translate(-50%, -50%) rotate(45deg);
}

[class*="c-icon"][class*="-cross"]:after {
  transform: translate(-50%, -50%) rotate(135deg);
}

a.is-active [class*="c-icon"][class*="-cross"]:before,
a:hover [class*="c-icon"][class*="-cross"]:before {
  transform: translate(-50%, -50%) rotate(135deg);
}

a.is-active [class*="c-icon"][class*="-cross"]:after,
a:hover [class*="c-icon"][class*="-cross"]:after {
  transform: translate(-50%, -50%) rotate(225deg);
}

[class*="c-icon"][class*="-plus"]:before {
  transform: translate(-50%, -50%) rotate(90deg);
}

[class*="c-icon"][class*="-plus"]:after {
  transform: translate(-50%, -50%) rotate(0deg);
}

a.is-active [class*="c-icon"][class*="-plus"]:before,
a:hover [class*="c-icon"][class*="-plus"]:before {
  transform: translate(-50%, -50%) rotate(180deg);
}

a.is-active [class*="c-icon"][class*="-plus"]:after,
a:hover [class*="c-icon"][class*="-plus"]:after {
  transform: translate(-50%, -50%) rotate(90deg);
}

[class*="c-icon"][class*="-minus"]:before {
  transform: translate(-50%, -50%) rotate(0deg);
}

a.is-active [class*="c-icon"][class*="-minus"]:before,
a:hover [class*="c-icon"][class*="-minus"]:before {
  transform: translate(-50%, -50%) rotate(180deg);
}

[class*="c-icon"][class*="-arrow-t"]:before {
  width: 35%;
  transform: translate(-100%, -0.25em) rotate(-45deg);
  transform-origin: right;
}

[class*="c-icon"][class*="-arrow-t"]:after {
  width: 35%;
  transform: translate(0, -0.25em) rotate(45deg);
  transform-origin: left;
}

a:hover [class*="c-icon"][class*="-arrow-t"]:before {
  transform: translate(-100%, -0.4em) rotate(-45deg);
}

a:hover [class*="c-icon"][class*="-arrow-t"]:after {
  transform: translate(0, -0.4em) rotate(45deg);
}

a.is-active [class*="c-icon"][class*="-arrow-t"]:before {
  transform: translate(-100%, 0.25em) rotate(45deg);
  transform-origin: right;
}

a.is-active [class*="c-icon"][class*="-arrow-t"]:after {
  transform: translate(0, 0.25em) rotate(-45deg);
  transform-origin: left;
}

[class*="c-icon"][class*="-arrow-r"]:before {
  width: 1px;
  height: 35%;
  transform: translate(0.25em, -100%) rotate(-45deg);
  transform-origin: bottom;
}

[class*="c-icon"][class*="-arrow-r"]:after {
  width: 1px;
  height: 35%;
  transform: translate(0.25em, 0) rotate(45deg);
  transform-origin: top;
}

a:hover [class*="c-icon"][class*="-arrow-r"]:before {
  transform: translate(0.4em, -100%) rotate(-45deg);
}

a:hover [class*="c-icon"][class*="-arrow-r"]:after {
  transform: translate(0.4em, 0) rotate(45deg);
}

a.is-active [class*="c-icon"][class*="-arrow-r"]:before {
  transform: translate(-0.25em, -100%) rotate(45deg);
  transform-origin: bottom;
}

a.is-active [class*="c-icon"][class*="-arrow-r"]:after {
  transform: translate(-0.25em, 0) rotate(-45deg);
  transform-origin: top;
}

[class*="c-icon"][class*="-arrow-b"]:before {
  width: 35%;
  transform: translate(-100%, 0.25em) rotate(45deg);
  transform-origin: right;
}

[class*="c-icon"][class*="-arrow-b"]:after {
  width: 35%;
  transform: translate(0, 0.25em) rotate(-45deg);
  transform-origin: left;
}

a:hover [class*="c-icon"][class*="-arrow-b"]:before {
  transform: translate(-100%, 0.4em) rotate(45deg);
}

a:hover [class*="c-icon"][class*="-arrow-b"]:after {
  transform: translate(0, 0.4em) rotate(-45deg);
}

a.is-active [class*="c-icon"][class*="-arrow-b"]:before {
  transform: translate(-100%, -0.25em) rotate(-45deg);
  transform-origin: right;
}

a.is-active [class*="c-icon"][class*="-arrow-b"]:after {
  transform: translate(0, -0.25em) rotate(45deg);
  transform-origin: left;
}

[class*="c-icon"][class*="-arrow-l"]:before {
  width: 1px;
  height: 35%;
  transform: translate(-0.25em, -100%) rotate(45deg);
  transform-origin: bottom;
}

[class*="c-icon"][class*="-arrow-l"]:after {
  width: 1px;
  height: 35%;
  transform: translate(-0.25em, 0) rotate(-45deg);
  transform-origin: top;
}

a:hover [class*="c-icon"][class*="-arrow-l"]:before {
  transform: translate(-0.4em, -100%) rotate(45deg);
}

a:hover [class*="c-icon"][class*="-arrow-l"]:after {
  transform: translate(-0.4em, 0) rotate(-45deg);
}

a.is-active [class*="c-icon"][class*="-arrow-l"]:before {
  transform: translate(0.25em, -100%) rotate(-45deg);
  transform-origin: bottom;
}

a.is-active [class*="c-icon"][class*="-arrow-l"]:after {
  transform: translate(0.25em, 0) rotate(45deg);
  transform-origin: top;
}

[class*="c-icon"][class*="-triangle-t"] {
  width: 1em;
}

[class*="c-icon"][class*="-triangle-t"]:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0.4em 0.5em 0.4em;
  border-color: transparent transparent #009A98 transparent;
}

[class*="c-icon"][class*="-triangle-r"] {
  width: 1em;
}

[class*="c-icon"][class*="-triangle-r"]:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0.4em 0 0.4em 0.5em;
  border-color: transparent transparent transparent #009A98;
}

[class*="c-icon"][class*="-triangle-b"] {
  width: 1em;
}

[class*="c-icon"][class*="-triangle-b"]:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0.5em 0.4em 0 0.4em;
  border-color: #009A98 transparent transparent transparent;
}

[class*="c-icon"][class*="-triangle-l"] {
  width: 1em;
}

[class*="c-icon"][class*="-triangle-l"]:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0.4em 0.5em 0.4em 0;
  border-color: transparent #009A98 transparent transparent;
}

[class*="c-icon"][class*="-question"] {
  border-radius: 50%;
  background-color: #009A98;
}

[class*="c-icon"][class*="-question"]:before {
  content: "?";
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  line-height: 2;
}

[class*="c-icon"][class*="-number"] {
  display: inline-block;
  width: 1.4em;
  height: 1.4em;
  margin: 0.4em 0 0.2em 0;
  border-radius: 50%;
  background-color: #333;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 0.8em;
  font-weight: bold;
  text-align: center;
  line-height: 1.6;
  cursor: auto;
}

[class*="c-icon"][class*="-number"][class*="-primary"] {
  background-color: #009A98;
}

[class*="c-icon"][class*="-xl"] {
  font-size: 1.266rem;
}

[class*="c-icon"][class*="-lg"] {
  font-size: 1.133rem;
}

[class*="c-icon"][class*="-md"] {
  font-size: 1rem;
}

[class*="c-icon"][class*="-sm"] {
  font-size: 0.866rem;
}

[class*="c-icon"][class*="-xs"] {
  font-size: 0.733rem;
}

/*===========================================
    c-button
===========================================*/
[class*="c-button-"] {
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
  padding: 0.8em 2em;
  background-color: #333;
  border-radius: 4px;
  color: #fff;
  font-weight: bold;
  line-height: 1.4;
  letter-spacing: 0.1em;
  text-decoration: none !important;
  white-space: nowrap;
  cursor: pointer;
}

[class*="c-button-"]:hover {
  text-decoration: none;
}

@media screen and (min-width: 601px), print {
  [class*="c-button-"]:hover {
    opacity: 0.6;
  }
}

[class*="c-button-"].is-active {
  border: solid 0px #333;
  background-color: #333;
  color: #fff;
}

[class*="c-button-"].is-disabled {
  border: solid 2px #333 !important;
  background-color: trasparent !important;
  color: #999999 !important;
  opacity: 0.6;
  pointer-events: none;
}

[class*="c-button-"] > img {
  width: 2.5em !important;
  height: 2.5em !important;
  margin: 0 0.5em 0 0 !important;
}

[class*="c-button-"] [class*="button__icon"] {
  width: 2.5em;
  height: 2.5em;
}

[class*="c-button-"] [class*="button__icon"] img {
  display: block;
  width: auto;
  height: 100%;
}

[class*="c-button-"] [class*="button__icon"][class*="--before"], [class*="c-button-"] [class*="button__icon"][class*="--after"] {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

[class*="c-button-"] [class*="button__icon"][class*="--before"] {
  left: 0.5em;
}

[class*="c-button-"] [class*="button__icon"][class*="--after"] {
  right: 0.5em;
}

[class*="c-button-"] input {
  display: none;
}

[class*="c-button-"] span {
  margin: 0 0.1em;
  font-size: 0.8em;
  letter-spacing: 0.05em;
}

[class*="c-button-"][class*="-black"] {
  background-color: #000;
  color: #fff;
}

[class*="c-button-"][class*="-black"].is-active {
  border-color: #000;
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
}

[class*="c-button-"][class*="-black"].is-disabled {
  border: solid 1px #000 !important;
  background-color: transparent !important;
  color: #000 !important;
}

[class*="c-button-"][class*="-white"] {
  border: solid 1px #ddd;
  background-color: #fff;
  color: #333;
}

[class*="c-button-"][class*="-white"].is-active {
  border: solid 1px #ddd;
  border-color: #fff;
  background-color: rgba(255, 255, 255, 0.6);
  color: #333;
}

[class*="c-button-"][class*="-white"].is-disabled {
  border: solid 1px #fff !important;
  background-color: transparent !important;
  color: #fff !important;
}

[class*="c-button-"][class*="-gray"] {
  border: solid 1px #ddd;
  background-color: #e9e9e9;
  color: #333;
}

[class*="c-button-"][class*="-gray"].is-active {
  border: solid 1px #ddd;
  background-color: rgba(233, 233, 233, 0.6);
  color: #333;
}

[class*="c-button-"][class*="-gray"].is-disabled {
  border: solid 1px #ddd !important;
  background-color: transparent !important;
  color: #ddd !important;
}

[class*="c-button-"][class*="-primary"] {
  background-color: #009A98;
  color: #fff;
}

[class*="c-button-"][class*="-primary"].is-active {
  border-color: #009A98;
  background-color: rgba(0, 154, 152, 0.6);
  color: #fff;
}

[class*="c-button-"][class*="-primary"].is-disabled {
  border: solid 1px #009A98 !important;
  background-color: transparent !important;
  color: #009A98 !important;
}

[class*="c-button-"][class*="-secondary"] {
  background-color: #1DAEE9;
  color: #fff;
}

[class*="c-button-"][class*="-secondary"].is-active {
  border-color: #1DAEE9;
  background-color: rgba(29, 174, 233, 0.6);
  color: #fff;
}

[class*="c-button-"][class*="-secondary"].is-disabled {
  border: solid 1px #1DAEE9 !important;
  background-color: transparent !important;
  color: #1DAEE9 !important;
}

[class*="c-button-"][class*="-tertiary"] {
  background-color: #847DB6;
  color: #fff;
}

[class*="c-button-"][class*="-tertiary"].is-active {
  border-color: #847DB6;
  background-color: rgba(132, 125, 182, 0.6);
  color: #fff;
}

[class*="c-button-"][class*="-tertiary"].is-disabled {
  border: solid 1px #847DB6 !important;
  background-color: transparent !important;
  color: #847DB6 !important;
}

[class*="c-button-"][class*="-cart"] {
  background-color: #FD9B5B;
  color: #fff;
}

[class*="c-button-"][class*="-cart"].is-active {
  border-color: #FD9B5B;
  background-color: rgba(253, 155, 91, 0.6);
  color: #fff;
}

[class*="c-button-"][class*="-cart"].is-disabled {
  border: solid 1px #FD9B5B !important;
  background-color: transparent !important;
  color: #FD9B5B !important;
}

[class*="c-button-"][class*="-zumen"] {
  background-color: #1DAEE9;
  color: #fff;
}

[class*="c-button-"][class*="-zumen"].is-active {
  border-color: #1DAEE9;
  background-color: rgba(29, 174, 233, 0.6);
  color: #fff;
}

[class*="c-button-"][class*="-zumen"].is-disabled {
  border: solid 1px #1DAEE9 !important;
  background-color: transparent !important;
  color: #1DAEE9 !important;
}

[class*="c-button-"][class*="-mitsumori"] {
  background-color: #FD9B5B;
  color: #fff;
}

[class*="c-button-"][class*="-mitsumori"].is-active {
  border-color: #FD9B5B;
  background-color: rgba(253, 155, 91, 0.6);
  color: #fff;
}

[class*="c-button-"][class*="-mitsumori"].is-disabled {
  border: solid 1px #FD9B5B !important;
  background-color: transparent !important;
  color: #FD9B5B !important;
}

[class*="c-button-"][class*="-xl"] {
  padding: 1.6em 2em;
  font-size: 1.133rem;
}

[class*="c-button-"][class*="-lg"] {
  font-size: 1.133rem;
}

[class*="c-button-"][class*="-md"] {
  font-size: 1rem;
}

[class*="c-button-"][class*="-sm"] {
  font-size: 0.866rem;
}

[class*="c-button-"][class*="-xs"] {
  font-size: 0.733rem;
}

[class*="c-button-"][class*="-size"] {
  height: 6em;
  border: solid 1px #ddd;
  background-color: #fff;
  color: #333;
  font-size: 0.866rem;
  white-space: normal;
}

[class*="c-button-"][class*="-size"]:hover {
  background-color: #ddd;
  border: solid 1px #ddd;
  opacity: 1;
}

[class*="c-button-"][class*="-size"].is-active {
  background-color: #009A98;
  border: solid 1px #009A98;
  color: #fff;
}

[class*="c-button-"][class*="-size"].is-disabled {
  border: solid 1px #ddd !important;
  background-color: transparent !important;
  color: #333 !important;
}

[class*="c-button-"][class*="-tab"] {
  border-bottom: solid 1px #ddd;
  border-radius: 0;
  background-color: #fff;
  color: #333;
  font-size: 1rem;
  text-align: center;
}

@media screen and (max-width: 600px) {
  [class*="c-button-"][class*="-tab"] {
    white-space: normal;
  }
}

[class*="c-button-"][class*="-tab"]:after {
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  transform: scaleX(0);
  width: 100%;
  height: 3px;
  background-color: #009A98;
}

[class*="c-button-"][class*="-tab"]:hover {
  opacity: 1;
}

[class*="c-button-"][class*="-tab"]:hover:after {
  transform: scaleX(1);
}

[class*="c-button-"][class*="-tab"].is-active {
  color: #009A98;
}

[class*="c-button-"][class*="-tab"].is-active:after {
  transform: scaleX(1);
}

[class*="c-button-"][class*="-tab"].is-disabled {
  border: none !important;
  border-bottom: solid 1px #ddd !important;
  background-color: #fff !important;
  color: #999999 !important;
  pointer-events: none;
}

[class*="c-button-"][class*="-select"] {
  width: 50%;
}

@media screen and (max-width: 600px) {
  [class*="c-button-"][class*="-select"] {
    width: 100%;
  }
}

/*===========================================
    c-buttonGroup
===========================================*/
[class*="c-buttonGroup"] {
  display: flex;
  justify-content: flex-start;
}

@media screen and (max-width: 600px) {
  [class*="c-buttonGroup"] {
    width: 100%;
    overflow-x: scroll;
  }
}

[class*="c-buttonGroup"] [class*="c-button-"] {
  width: 100%;
  margin-bottom: 0 !important;
}

[class*="c-buttonGroup"] [class*="c-button-"]:not(.c-button--tab) {
  position: relative;
  border-radius: 0;
}

[class*="c-buttonGroup"] [class*="c-button-"]:not(.c-button--tab):after {
  content: '';
  position: absolute;
  top: 50%;
  right: 0;
  z-index: 1;
  transform: translate(0, -50%);
  width: 1px;
  height: 60%;
  background-color: #fff;
}

[class*="c-buttonGroup"] [class*="c-button-"]:not(.c-button--tab):first-child {
  border-radius: 4px 0 0 4px;
}

[class*="c-buttonGroup"] [class*="c-button-"]:not(.c-button--tab):last-child {
  border-radius: 0 4px 4px 0;
}

[class*="c-buttonGroup"] [class*="c-button-"]:not(.c-button--tab):last-child:after {
  content: none;
}

[class*="c-buttonGroup"][class*="-tab"] [class*="c-button--tab"] {
  position: relative;
  white-space: nowrap;
}

[class*="c-buttonGroup"][class*="-tab"] [class*="c-button--tab"]:before {
  content: '';
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
  width: 1px;
  height: 50%;
  background-color: #ddd;
}

[class*="c-buttonGroup"][class*="-tab"] [class*="c-button--tab"]:first-child {
  border-radius: 0;
}

[class*="c-buttonGroup"][class*="-tab"] [class*="c-button--tab"]:last-child {
  border-radius: 0;
}

[class*="c-buttonGroup"][class*="-tab"] [class*="c-button--tab"]:last-child:before {
  content: none;
}

/*===========================================
    c-button
===========================================*/
a {
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  text-decoration: none !important;
}

a > [class*="c-label"] {
  width: 100%;
  text-decoration: none  !important;
}

a:hover {
  text-decoration: none !important;
  opacity: 0.8;
}

[class*="c-label"] {
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 2.0em;
  width: auto;
  height: auto;
  padding: 0.4em 0.8em;
  background-color: #e6e6e6;
  border-radius: 4px;
  color: #333;
  font-weight: bold;
  line-height: 1.6;
  letter-spacing: 0.05em;
  text-decoration: none;
  white-space: nowrap;
}

[class*="c-label"][class*="-black"] {
  background-color: #e6e6e6;
  color: #333;
}

[class*="c-label"][class*="-white"] {
  background-color: #fff;
  color: #333;
}

[class*="c-label"][class*="-primary"] {
  background-color: #00b4b1;
  color: #fff;
}

[class*="c-label"][class*="-secondary"] {
  background-color: #4bbfee;
  color: #fff;
}

[class*="c-label"][class*="-kojin"] {
  background-color: #EA494B;
  color: #fff;
}

[class*="c-label"][class*="-enpou"] {
  background-color: #EA494B;
  color: #fff;
}

[class*="c-label"][class*="-oogata"] {
  background-color: #009A98;
  color: #fff;
}

[class*="c-label"][class*="-soryo"] {
  padding-left: 2.5rem;
  background-color: #e6e6e6;
  background-image: url("../images/icon/icon_truck_b.svg");
  background-repeat: no-repeat;
  background-size: 2em auto;
  background-position: 0.2em center;
  color: #333;
}

[class*="c-label"][class*="-xl"] {
  font-size: 1rem;
}

[class*="c-label"][class*="-lg"] {
  font-size: 0.875rem;
}

[class*="c-label"][class*="-md"] {
  font-size: 0.75rem;
}

[class*="c-label"][class*="-sm"] {
  font-size: 0.625rem;
}

a[class*="c-label-"] {
  cursor: pointer;
}

a[class*="c-label-"]:hover {
  text-decoration: none;
  opacity: 0.6;
}

/*===========================================
    c-table
===========================================*/
[class*="c-table"] {
  transition: none;
  position: relative;
  width: 100%;
  font-size: 0.866rem;
}

@media screen and (max-width: 600px) {
  [class*="c-table"] {
    display: block;
    table-layout: fixed;
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

[class*="c-table"] thead {
  width: 100%;
}

[class*="c-table"] thead th {
  background-color: #e9e9e9;
}

[class*="c-table"] tbody {
  width: 100%;
  background-color: #fff;
}

[class*="c-table"] tr {
  width: 100%;
  background-color: #fff;
  border-bottom: solid 1px #ddd;
}

[class*="c-table"] tr:first-child {
  border-top: solid 1px #ddd;
}

[class*="c-table"] tr:last-child td,
[class*="c-table"] tr:last-child th {
  border-bottom: none;
}

@media all and (-ms-high-contrast: none) {
  [class*="c-table"] tr td:not(.c-text--md-left),
  [class*="c-table"] tr th:not(.c-text--md-left) {
    position: static;
  }
}

@supports (-ms-ime-align: auto) {
  [class*="c-table"] tr td:not(.c-text--md-left),
  [class*="c-table"] tr th:not(.c-text--md-left) {
    position: static;
  }
}

[class*="c-table"] tr td,
[class*="c-table"] tr th {
  min-width: 2em;
  padding: 0.8em;
  border-left: solid 1px #ddd;
  border-right: solid 1px #ddd;
  vertical-align: middle;
}

@media screen and (max-width: 600px) {
  [class*="c-table"] tr td,
  [class*="c-table"] tr th {
    overflow-wrap: break-word;
  }
}

[class*="c-table"] tr td img,
[class*="c-table"] tr th img {
  display: block;
  min-width: 64px;
  width: 100%;
}

@media screen and (max-width: 600px) {
  [class*="c-table"] tr td input[type="text"],
  [class*="c-table"] tr th input[type="text"] {
    min-width: 10em;
  }
}

[class*="c-table"] tr th {
  background-color: #e9e9e9;
  font-weight: bold;
}

@media screen and (min-width: 601px), print {
  [class*="c-table"] tr th:first-child {
    border-right: solid 1px #ddd;
  }
}

[class*="c-table"] tr .table__nopadding {
  padding: 0;
}

[class*="c-table"][class*="-sm"] {
  font-size: 0.866rem;
}

[class*="c-table"][class*="-sm"] td,
[class*="c-table"][class*="-sm"] th {
  padding: 0.6em;
}

[class*="c-table"][class*="-xs"] {
  font-size: 0.733rem;
}

[class*="c-table"][class*="-xs"] td,
[class*="c-table"][class*="-xs"] th {
  padding: 0.4em;
}

[class*="c-table"][class*="-color"] tr:nth-child(2n) {
  background-color: rgba(221, 221, 221, 0.2);
}

@media screen and (max-width: 600px) {
  [class*="c-table"][class*="-scroll"] {
    position: relative;
    display: flex;
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  [class*="c-table"][class*="-scroll"] thead {
    position: relative;
    z-index: 1;
    width: auto;
    border-bottom: none;
    box-shadow: 2px 0 4px rgba(0, 0, 0, 0.1);
  }
}

@media screen and (max-width: 600px) {
  [class*="c-table"][class*="-scroll"] thead tr td,
  [class*="c-table"][class*="-scroll"] thead tr th {
    border-right: none;
  }
}

@media screen and (max-width: 600px) {
  [class*="c-table"][class*="-scroll"] tbody {
    position: relative;
    z-index: 0;
    display: flex;
    width: 100%;
    overflow-x: scroll;
  }
}

@media screen and (max-width: 600px) {
  [class*="c-table"][class*="-scroll"] tr {
    display: flex;
    flex-flow: column;
    width: 100%;
    border-bottom: none;
  }
}

@media screen and (max-width: 600px) {
  [class*="c-table"][class*="-scroll"] tr:last-child td,
  [class*="c-table"][class*="-scroll"] tr:last-child th {
    border-right: none;
  }
}

@media screen and (max-width: 600px) {
  [class*="c-table"][class*="-scroll"] tr td,
  [class*="c-table"][class*="-scroll"] tr th {
    width: 100%;
    border-bottom: solid 1px #ddd;
    border-left: none;
    border-right: dotted 1px #aaaaaa;
    white-space: nowrap;
  }
}

@media screen and (max-width: 600px) {
  [class*="c-table"][class*="-card"] {
    display: table;
  }
}

@media screen and (max-width: 600px) {
  [class*="c-table"][class*="-card"] thead {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  [class*="c-table"][class*="-card"] tbody {
    background-color: transparent;
  }
}

@media screen and (max-width: 600px) {
  [class*="c-table"][class*="-card"] tr {
    display: block;
    overflow: hidden;
    border: solid 1px #ddd;
    border-color: #ddd;
    border-radius: 4px;
    margin-bottom: 1em;
  }
}

[class*="c-table"][class*="-card"] tr:last-child {
  margin-bottom: 0;
}

@media screen and (max-width: 600px) {
  [class*="c-table"][class*="-card"] tr td,
  [class*="c-table"][class*="-card"] tr th {
    padding: 1em 2em;
    border-right: none;
    border-left: none;
    border-bottom: solid 1px #ddd;
    border-color: #ddd;
    text-align: left;
  }
}

@media screen and (max-width: 600px) {
  [class*="c-table"][class*="-card"] tr td:last-child,
  [class*="c-table"][class*="-card"] tr th:last-child {
    border: none;
  }
}

@media screen and (max-width: 600px) {
  [class*="c-table"][class*="-card"] tr th {
    display: block;
    background-color: #eaeaea;
  }
}

@media screen and (max-width: 600px) {
  [class*="c-table"][class*="-card"] tr td {
    display: flex;
    justify-content: space-between;
    background-color: #fff;
  }
}

@media screen and (max-width: 600px) {
  [class*="c-table"][class*="-card"] tr td:before {
    content: attr(data-label);
    display: block;
    margin-right: 0.5em;
    font-size: 0.88em;
    font-weight: bold;
  }
}

[class*="c-table"][class*="-border"] {
  display: table;
  border-left: none;
  border-right: none;
  border-color: #ddd;
  background-color: transparent;
}

[class*="c-table"][class*="-border"] thead {
  background-color: transparent;
}

[class*="c-table"][class*="-border"] thead tr {
  border-color: #333;
}

[class*="c-table"][class*="-border"] thead th {
  border: none;
}

[class*="c-table"][class*="-border"] tbody {
  background-color: transparent;
}

[class*="c-table"][class*="-border"] tbody th {
  background-color: transparent;
}

[class*="c-table"][class*="-border"] tr {
  border-color: #ddd;
  background-color: transparent;
}

[class*="c-table"][class*="-border"] td,
[class*="c-table"][class*="-border"] th {
  border: none;
  background-color: transparent;
  word-break: break-all;
}

@media screen and (max-width: 600px) {
  [class*="c-table"][class*="-border"][class*="-cart"] tbody {
    display: block;
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  [class*="c-table"][class*="-border"][class*="-cart"] tr {
    display: block;
    width: 100%;
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-table"][class*="-border"][class*="-cart"] th {
    width: 30%;
  }
}

@media screen and (max-width: 600px) {
  [class*="c-table"][class*="-border"][class*="-cart"] td,
  [class*="c-table"][class*="-border"][class*="-cart"] th {
    display: block;
    width: 100%;
  }
}

[class*="c-table"][class*="-form"] {
  border: none;
}

.is-ie11 [class*="c-table"][class*="-form"] {
  table-layout: fixed;
}

[class*="c-table"][class*="-form"] thead {
  display: none;
}

@media screen and (max-width: 600px) {
  [class*="c-table"][class*="-form"] tbody {
    display: block;
    width: 100%;
  }
}

[class*="c-table"][class*="-form"] tr {
  border: none;
}

@media screen and (max-width: 600px) {
  [class*="c-table"][class*="-form"] tr {
    display: block;
    width: 100%;
  }
}

[class*="c-table"][class*="-form"] tr:nth-child(2n) {
  background-color: rgba(221, 221, 221, 0.2);
}

[class*="c-table"][class*="-form"] td,
[class*="c-table"][class*="-form"] th {
  position: relative;
  min-width: 80px;
  max-width: 100%;
  border: none;
  vertical-align: top;
}

@media screen and (max-width: 600px) {
  [class*="c-table"][class*="-form"] td,
  [class*="c-table"][class*="-form"] th {
    display: block;
    width: 100%;
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-table"][class*="-form"] th {
    width: 30%;
    padding: 1.8em 5em 1.8em 1.4em;
  }
}

@media screen and (max-width: 600px) {
  [class*="c-table"][class*="-form"] th {
    width: 100%;
    padding: 1.8em 4em 0 1.4em;
  }
}

[class*="c-table"][class*="-form"] td {
  padding: 1.4em 4em 1.4em 1.4em;
}

[class*="c-table"][class*="-form"] tbody th {
  background-color: transparent;
}

[class*="c-table"][class*="-form"] .is-required:after {
  content: '必須';
  position: absolute;
  top: 1.8rem;
  right: 1.4rem;
  padding: 0.2em 0.8em;
  background-color: #DE1816;
  color: #fff;
  font-size: 0.733rem;
  vertical-align: middle;
}

[class*="c-table"][class*="-form"] .table__note {
  display: block;
  margin: 1em 0;
}

[class*="c-table"][class*="-form"] .table__question {
  position: absolute;
  top: 50%;
  right: 1.4em;
  transform: translateY(-50%);
}

[class*="c-table"][class*="-form"] span {
  vertical-align: middle;
}

[class*="c-table"][class*="-form"][class*="-cart"] td {
  padding: 1.4em;
}

@media screen and (min-width: 601px), print {
  [class*="c-table"][class*="-form"][class*="-cart"] .cart__total th {
    width: 20%;
  }
}

@media screen and (max-width: 600px) {
  [class*="c-table"][class*="-form"][class*="-cart"] .cart__total th {
    padding: 1.4em;
  }
}

@media screen and (max-width: 600px) {
  [class*="c-table"][class*="-list"] {
    display: block;
  }
}

@media screen and (max-width: 600px) {
  [class*="c-table"][class*="-list"] thead {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  [class*="c-table"][class*="-list"] tbody {
    display: block;
    width: 100%;
    border-top: solid 1px #ddd;
    background-color: transparent;
  }
}

@media screen and (max-width: 600px) {
  [class*="c-table"][class*="-list"] tbody tr {
    display: block;
    width: 100%;
    border: none;
    padding: 1em;
    border-bottom: solid 1px #ddd;
  }
}

@media screen and (max-width: 600px) {
  [class*="c-table"][class*="-list"] tbody td,
  [class*="c-table"][class*="-list"] tbody th {
    width: 100%;
    padding: 0;
    margin-bottom: 0.5em;
    border: none;
  }
}

[class*="c-table"][class*="-list"] tbody td:last-child,
[class*="c-table"][class*="-list"] tbody th:last-child {
  margin-bottom: 0;
}

@media screen and (max-width: 600px) {
  [class*="c-table"][class*="-list"] tbody td {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    padding-left: 1em;
    background-color: #fff;
  }
}

@media screen and (max-width: 600px) {
  [class*="c-table"][class*="-list"] tbody td:before {
    content: attr(data-label);
    display: block;
    width: 8rem;
    margin-right: 0.5em;
    font-size: 0.88em;
    font-weight: bold;
  }
}

/*===========================================
    c-flame
===========================================*/
[class*="c-flame"] {
  position: relative;
  background-color: #fff;
  border-radius: 0.3rem;
}

[class*="c-flame"] > * {
  margin-top: 1rem;
}

[class*="c-flame"] > *:first-child {
  margin-top: 0;
}

[class*="c-flame"] a {
  text-decoration: underline;
}

[class*="c-flame"] .flame__close {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

@media screen and (max-width: 600px) {
  [class*="c-flame"] .flame__close {
    top: 0.5rem;
    right: 0.5rem;
  }
}

[class*="c-flame"] .flame__close i {
  width: 2rem;
  height: 2rem;
}

@media screen and (min-width: 601px), print {
  [class*="c-flame"] [class*="flame__content"] {
    margin-top: 0 !important;
  }
}

[class*="c-flame"][class*="-lg"] {
  padding: 4rem;
}

@media screen and (max-width: 600px) {
  [class*="c-flame"][class*="-lg"] {
    padding: 2rem;
  }
}

[class*="c-flame"][class*="-md"] {
  padding: 3rem;
}

@media screen and (max-width: 600px) {
  [class*="c-flame"][class*="-md"] {
    padding: 2rem;
  }
}

[class*="c-flame"][class*="-sm"] {
  padding: 2rem;
}

[class*="c-flame"][class*="-xs"] {
  padding: 1rem 1.4rem;
}

[class*="c-flame"][class*="-primary"] {
  background-color: #cdfffe;
}

[class*="c-flame"][class*="-primary"] * {
  color: #009A98;
}

[class*="c-flame"][class*="-secondary"] {
  background-color: #c0e8f9;
  color: #1DAEE9;
}

[class*="c-flame"][class*="-secondary"] * {
  color: inherit;
}

[class*="c-flame"][class*="-secondary"] a {
  color: inherit !important;
}

[class*="c-flame"][class*="-tertiary"] {
  background-color: #d1cfe4;
  color: #847DB6;
}

[class*="c-flame"][class*="-tertiary"] * {
  color: inherit;
}

[class*="c-flame"][class*="-tertiary"] a {
  color: inherit !important;
}

[class*="c-flame"][class*="-gray"] {
  background-color: #f3f3f3;
}

[class*="c-flame"][class*="-success"] {
  background-color: #bbfdf5;
  color: #06CDB5;
}

[class*="c-flame"][class*="-success"] * {
  color: inherit;
}

[class*="c-flame"][class*="-success"] a {
  color: inherit !important;
}

[class*="c-flame"][class*="-warning"] {
  background-color: #fae6dc;
  color: #E68857;
}

[class*="c-flame"][class*="-warning"] * {
  color: inherit;
}

[class*="c-flame"][class*="-warning"] a {
  color: inherit !important;
}

[class*="c-flame"][class*="-danger"] {
  background-color: #fcdede;
  color: #DE1816;
}

[class*="c-flame"][class*="-danger"] * {
  color: inherit;
}

[class*="c-flame"][class*="-danger"] a {
  color: inherit !important;
}

[class*="c-flame"][class*="-border"] {
  border: solid 4px #f3f3f3;
}

[class*="c-flame"][class*="-semiorder"] {
  border: solid 8px #f3f3f3;
}

[class*="c-flame"][class*="-acrylic"] {
  border: solid 8px #f3f3f3;
}

[class*="c-flame"][class*="-polyca"] {
  border: solid 8px #f3f3f3;
}

[class*="c-flame"][class*="-column"] {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
}

[class*="c-flame"][class*="-column"][class*="-2"] [class*="flame__content"] {
  width: calc((100% - 1px) / 2);
  padding-right: 1.2rem;
  border-right: solid 1px #ddd;
}

@media screen and (min-width: 601px), print {
  [class*="c-flame"][class*="-column"][class*="-2"] [class*="flame__content"][class*="-w0"] {
    width: 0%;
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-flame"][class*="-column"][class*="-2"] [class*="flame__content"][class*="-w5"] {
    width: 5%;
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-flame"][class*="-column"][class*="-2"] [class*="flame__content"][class*="-w10"] {
    width: 10%;
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-flame"][class*="-column"][class*="-2"] [class*="flame__content"][class*="-w15"] {
    width: 15%;
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-flame"][class*="-column"][class*="-2"] [class*="flame__content"][class*="-w20"] {
    width: 20%;
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-flame"][class*="-column"][class*="-2"] [class*="flame__content"][class*="-w25"] {
    width: 25%;
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-flame"][class*="-column"][class*="-2"] [class*="flame__content"][class*="-w30"] {
    width: 30%;
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-flame"][class*="-column"][class*="-2"] [class*="flame__content"][class*="-w35"] {
    width: 35%;
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-flame"][class*="-column"][class*="-2"] [class*="flame__content"][class*="-w40"] {
    width: 40%;
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-flame"][class*="-column"][class*="-2"] [class*="flame__content"][class*="-w45"] {
    width: 45%;
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-flame"][class*="-column"][class*="-2"] [class*="flame__content"][class*="-w50"] {
    width: 50%;
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-flame"][class*="-column"][class*="-2"] [class*="flame__content"][class*="-w55"] {
    width: 55%;
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-flame"][class*="-column"][class*="-2"] [class*="flame__content"][class*="-w60"] {
    width: 60%;
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-flame"][class*="-column"][class*="-2"] [class*="flame__content"][class*="-w65"] {
    width: 65%;
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-flame"][class*="-column"][class*="-2"] [class*="flame__content"][class*="-w70"] {
    width: 70%;
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-flame"][class*="-column"][class*="-2"] [class*="flame__content"][class*="-w75"] {
    width: 75%;
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-flame"][class*="-column"][class*="-2"] [class*="flame__content"][class*="-w80"] {
    width: 80%;
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-flame"][class*="-column"][class*="-2"] [class*="flame__content"][class*="-w85"] {
    width: 85%;
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-flame"][class*="-column"][class*="-2"] [class*="flame__content"][class*="-w90"] {
    width: 90%;
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-flame"][class*="-column"][class*="-2"] [class*="flame__content"][class*="-w95"] {
    width: 95%;
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-flame"][class*="-column"][class*="-2"] [class*="flame__content"][class*="-w100"] {
    width: 100%;
  }
}

[class*="c-flame"][class*="-column"][class*="-2"] [class*="flame__content"]:nth-child(2n) {
  margin-right: 0;
  padding-left: 1.2rem;
  padding-right: 0;
  border: none;
}

[class*="c-flame"][class*="-column"][class*="-3"] [class*="flame__content"] {
  margin-right: 1.2rem;
  padding-right: 1.2rem;
  border-right: solid 1px #ddd;
}

@media screen and (min-width: 601px), print {
  [class*="c-flame"][class*="-column"][class*="-3"] [class*="flame__content"][class*="-w0"] {
    width: calc((0% - (1.2rem * 3 - 1px * 2)) / 3);
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-flame"][class*="-column"][class*="-3"] [class*="flame__content"][class*="-w5"] {
    width: calc((5% - (1.2rem * 3 - 1px * 2)) / 3);
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-flame"][class*="-column"][class*="-3"] [class*="flame__content"][class*="-w10"] {
    width: calc((10% - (1.2rem * 3 - 1px * 2)) / 3);
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-flame"][class*="-column"][class*="-3"] [class*="flame__content"][class*="-w15"] {
    width: calc((15% - (1.2rem * 3 - 1px * 2)) / 3);
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-flame"][class*="-column"][class*="-3"] [class*="flame__content"][class*="-w20"] {
    width: calc((20% - (1.2rem * 3 - 1px * 2)) / 3);
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-flame"][class*="-column"][class*="-3"] [class*="flame__content"][class*="-w25"] {
    width: calc((25% - (1.2rem * 3 - 1px * 2)) / 3);
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-flame"][class*="-column"][class*="-3"] [class*="flame__content"][class*="-w30"] {
    width: calc((30% - (1.2rem * 3 - 1px * 2)) / 3);
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-flame"][class*="-column"][class*="-3"] [class*="flame__content"][class*="-w35"] {
    width: calc((35% - (1.2rem * 3 - 1px * 2)) / 3);
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-flame"][class*="-column"][class*="-3"] [class*="flame__content"][class*="-w40"] {
    width: calc((40% - (1.2rem * 3 - 1px * 2)) / 3);
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-flame"][class*="-column"][class*="-3"] [class*="flame__content"][class*="-w45"] {
    width: calc((45% - (1.2rem * 3 - 1px * 2)) / 3);
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-flame"][class*="-column"][class*="-3"] [class*="flame__content"][class*="-w50"] {
    width: calc((50% - (1.2rem * 3 - 1px * 2)) / 3);
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-flame"][class*="-column"][class*="-3"] [class*="flame__content"][class*="-w55"] {
    width: calc((55% - (1.2rem * 3 - 1px * 2)) / 3);
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-flame"][class*="-column"][class*="-3"] [class*="flame__content"][class*="-w60"] {
    width: calc((60% - (1.2rem * 3 - 1px * 2)) / 3);
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-flame"][class*="-column"][class*="-3"] [class*="flame__content"][class*="-w65"] {
    width: calc((65% - (1.2rem * 3 - 1px * 2)) / 3);
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-flame"][class*="-column"][class*="-3"] [class*="flame__content"][class*="-w70"] {
    width: calc((70% - (1.2rem * 3 - 1px * 2)) / 3);
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-flame"][class*="-column"][class*="-3"] [class*="flame__content"][class*="-w75"] {
    width: calc((75% - (1.2rem * 3 - 1px * 2)) / 3);
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-flame"][class*="-column"][class*="-3"] [class*="flame__content"][class*="-w80"] {
    width: calc((80% - (1.2rem * 3 - 1px * 2)) / 3);
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-flame"][class*="-column"][class*="-3"] [class*="flame__content"][class*="-w85"] {
    width: calc((85% - (1.2rem * 3 - 1px * 2)) / 3);
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-flame"][class*="-column"][class*="-3"] [class*="flame__content"][class*="-w90"] {
    width: calc((90% - (1.2rem * 3 - 1px * 2)) / 3);
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-flame"][class*="-column"][class*="-3"] [class*="flame__content"][class*="-w95"] {
    width: calc((95% - (1.2rem * 3 - 1px * 2)) / 3);
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-flame"][class*="-column"][class*="-3"] [class*="flame__content"][class*="-w100"] {
    width: calc((100% - (1.2rem * 3 - 1px * 2)) / 3);
  }
}

[class*="c-flame"][class*="-column"][class*="-3"] [class*="flame__content"]:nth-child(3n) {
  margin-right: 0;
  padding-right: 0;
  border: none;
}

[class*="c-flame"][class*="-column"][class*="-4"] [class*="flame__content"] {
  margin-right: 1.2rem;
  padding-right: 1.2rem;
  border-right: solid 1px #ddd;
}

@media screen and (min-width: 601px), print {
  [class*="c-flame"][class*="-column"][class*="-4"] [class*="flame__content"][class*="-w0"] {
    width: calc((0% - (1.2rem * 4 - 1px * 2)) / 4);
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-flame"][class*="-column"][class*="-4"] [class*="flame__content"][class*="-w5"] {
    width: calc((5% - (1.2rem * 4 - 1px * 2)) / 4);
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-flame"][class*="-column"][class*="-4"] [class*="flame__content"][class*="-w10"] {
    width: calc((10% - (1.2rem * 4 - 1px * 2)) / 4);
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-flame"][class*="-column"][class*="-4"] [class*="flame__content"][class*="-w15"] {
    width: calc((15% - (1.2rem * 4 - 1px * 2)) / 4);
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-flame"][class*="-column"][class*="-4"] [class*="flame__content"][class*="-w20"] {
    width: calc((20% - (1.2rem * 4 - 1px * 2)) / 4);
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-flame"][class*="-column"][class*="-4"] [class*="flame__content"][class*="-w25"] {
    width: calc((25% - (1.2rem * 4 - 1px * 2)) / 4);
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-flame"][class*="-column"][class*="-4"] [class*="flame__content"][class*="-w30"] {
    width: calc((30% - (1.2rem * 4 - 1px * 2)) / 4);
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-flame"][class*="-column"][class*="-4"] [class*="flame__content"][class*="-w35"] {
    width: calc((35% - (1.2rem * 4 - 1px * 2)) / 4);
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-flame"][class*="-column"][class*="-4"] [class*="flame__content"][class*="-w40"] {
    width: calc((40% - (1.2rem * 4 - 1px * 2)) / 4);
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-flame"][class*="-column"][class*="-4"] [class*="flame__content"][class*="-w45"] {
    width: calc((45% - (1.2rem * 4 - 1px * 2)) / 4);
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-flame"][class*="-column"][class*="-4"] [class*="flame__content"][class*="-w50"] {
    width: calc((50% - (1.2rem * 4 - 1px * 2)) / 4);
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-flame"][class*="-column"][class*="-4"] [class*="flame__content"][class*="-w55"] {
    width: calc((55% - (1.2rem * 4 - 1px * 2)) / 4);
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-flame"][class*="-column"][class*="-4"] [class*="flame__content"][class*="-w60"] {
    width: calc((60% - (1.2rem * 4 - 1px * 2)) / 4);
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-flame"][class*="-column"][class*="-4"] [class*="flame__content"][class*="-w65"] {
    width: calc((65% - (1.2rem * 4 - 1px * 2)) / 4);
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-flame"][class*="-column"][class*="-4"] [class*="flame__content"][class*="-w70"] {
    width: calc((70% - (1.2rem * 4 - 1px * 2)) / 4);
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-flame"][class*="-column"][class*="-4"] [class*="flame__content"][class*="-w75"] {
    width: calc((75% - (1.2rem * 4 - 1px * 2)) / 4);
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-flame"][class*="-column"][class*="-4"] [class*="flame__content"][class*="-w80"] {
    width: calc((80% - (1.2rem * 4 - 1px * 2)) / 4);
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-flame"][class*="-column"][class*="-4"] [class*="flame__content"][class*="-w85"] {
    width: calc((85% - (1.2rem * 4 - 1px * 2)) / 4);
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-flame"][class*="-column"][class*="-4"] [class*="flame__content"][class*="-w90"] {
    width: calc((90% - (1.2rem * 4 - 1px * 2)) / 4);
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-flame"][class*="-column"][class*="-4"] [class*="flame__content"][class*="-w95"] {
    width: calc((95% - (1.2rem * 4 - 1px * 2)) / 4);
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-flame"][class*="-column"][class*="-4"] [class*="flame__content"][class*="-w100"] {
    width: calc((100% - (1.2rem * 4 - 1px * 2)) / 4);
  }
}

[class*="c-flame"][class*="-column"][class*="-4"] [class*="flame__content"]:nth-child(4n) {
  margin-right: 0;
  padding-right: 0;
  border: none;
}

[class*="c-flame"][class*="-column"][class*="-center"] {
  align-items: center;
}

@media screen and (min-width: 601px), print {
  [class*="c-flame"][class*="-column"][class*="-reverse"] {
    flex-direction: row-reverse;
  }
}

@media screen and (max-width: 600px) {
  [class*="c-flame"][class*="-column"][class*="-vertical"] {
    display: block;
  }
}

@media screen and (max-width: 600px) {
  [class*="c-flame"][class*="-column"][class*="-vertical"] [class*="flame__content"] {
    width: 100%;
    margin-right: 0;
    margin-bottom: 1.4rem;
    padding: 0 0 1.4rem;
    border-right: none;
    border-bottom: solid 1px #ddd;
  }
}

@media screen and (max-width: 600px) {
  [class*="c-flame"][class*="-column"][class*="-vertical"] [class*="flame__content"]:last-child {
    margin: 0;
    padding: 0;
    border: none;
  }
}

/*===========================================
    c-window
===========================================*/
[class*="c-window"] {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 4px 4px 32px rgba(0, 0, 0, 0.1);
}

[class*="c-window"] .window__close {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

@media screen and (max-width: 600px) {
  [class*="c-window"] .window__close {
    top: 0.5rem;
    right: 0.5rem;
  }
}

[class*="c-window"] .window__close i {
  width: 2rem;
  height: 2rem;
}

[class*="c-window"] > img {
  display: block;
  max-width: 100%;
}

[class*="c-window"][class*="-lg"] {
  padding: 4rem;
}

@media screen and (max-width: 600px) {
  [class*="c-window"][class*="-lg"] {
    padding: 2rem;
  }
}

[class*="c-window"][class*="-md"] {
  padding: 3rem;
}

@media screen and (max-width: 600px) {
  [class*="c-window"][class*="-md"] {
    padding: 2rem;
  }
}

[class*="c-window"][class*="-sm"] {
  padding: 2rem;
}

[class*="c-window"][class*="-xs"] {
  padding: 1rem 1.4rem;
}

[class*="c-window"][class*="-full"] {
  overflow: hidden;
  padding: 0;
}

[class*="c-window"][class*="-primary"] {
  background-color: #e7ffff;
}

[class*="c-window"][class*="-primary"] * {
  color: #009A98;
}

[class*="c-window"][class*="-secondary"] {
  background-color: #c0e8f9;
}

[class*="c-window"][class*="-secondary"] * {
  color: #1DAEE9;
}

[class*="c-window"][class*="-success"] {
  background-color: #bbfdf5;
}

[class*="c-window"][class*="-success"] * {
  color: #06CDB5;
}

[class*="c-window"][class*="-warning"] {
  background-color: white;
}

[class*="c-window"][class*="-warning"] * {
  color: #E68857;
}

[class*="c-window"][class*="-danger"] {
  background-color: #fcdede;
}

[class*="c-window"][class*="-danger"] * {
  color: #DE1816;
}

[class*="c-window"][class*="-balloon"] {
  content: '';
  position: relative;
  padding: 0.6em 1em;
  background-color: #fff;
  line-height: 1.4;
}

[class*="c-window"][class*="-balloon"]:after {
  content: '';
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
}

[class*="c-window"][class*="-balloon"][class*="-balloon-t"]:after {
  top: -0.625rem;
  left: 50%;
  margin-left: -0.3125rem;
  border-width: 0 0.3125rem 0.625rem 0.3125rem;
  border-color: transparent transparent #fff transparent;
}

[class*="c-window"][class*="-balloon"][class*="-balloon-r"]:after {
  top: 50%;
  right: -0.625rem;
  margin-top: -0.3125rem;
  border-width: 0.3125rem 0 0.3125rem 0.625rem;
  border-color: transparent transparent transparent #fff;
}

[class*="c-window"][class*="-balloon"][class*="-balloon-b"]:after {
  bottom: -0.625rem;
  left: 50%;
  margin-left: -0.3125rem;
  border-width: 0.625rem 0.3125rem 0 0.3125rem;
  border-color: #fff transparent transparent transparent;
}

[class*="c-window"][class*="-balloon"][class*="-balloon-l"]:after {
  top: 50%;
  left: -0.625rem;
  margin-top: -0.3125rem;
  border-width: 0.3125rem 0.625rem 0.3125rem 0;
  border-color: transparent #fff transparent transparent;
}

/*===========================================
    c-image
===========================================*/
[class*="c-image"] > img {
  display: block;
  max-width: 100%;
}

[class*="c-image"][class*="-fullWidth"] > img {
  width: 100%;
}

[class*="c-image"][class*="-fullHeight"] > img {
  width: auto;
  height: 100%;
}

[class*="c-image"][class*="-center"] > img {
  margin-left: auto;
  margin-right: auto;
}

[class*="c-image"][class*="-left"] > img {
  margin-right: auto;
}

[class*="c-image"][class*="-right"] > img {
  margin-left: auto;
}

/*===========================================
    c-card
===========================================*/
[class*="c-card-"] {
  position: relative;
  display: flex;
  flex-direction: column;
  color: #333;
  text-decoration: none;
}

[class*="c-card-"] .card__head {
  position: relative;
}

@media all and (-ms-high-contrast: none) {
  [class*="c-card-"] .card__head {
    min-height: 0%;
  }
}

[class*="c-card-"] .card__head .card__new {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  padding: 0.2em 0.6em;
  background-color: #DE1816;
  color: #fff;
  font-weight: bold;
  font-size: 0.733rem;
}

@media screen and (max-width: 600px) {
  [class*="c-card-"] .card__body > * {
    line-height: 1.4;
  }
}

[class*="c-card-"] .card__body > *:first-child {
  margin-top: 0 !important;
}

[class*="c-card-"] .card__body > *:last-child {
  margin-bottom: 0 !important;
}

[class*="c-card-"] .card__image {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: auto;
}

[class*="c-card-"] .card__image:before {
  content: "";
  display: block;
  padding-top: 66%;
}

.is-ie11 [class*="c-card-"] .card__image {
  background-color: #fff;
}

[class*="c-card-"] .card__image img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.is-ie11 [class*="c-card-"] .card__image img {
  width: 100%;
  height: auto;
  object-fit: contain;
}

[class*="c-card-"] .card__category {
  margin-bottom: 0.4em;
  color: #595959;
  line-height: 1.6;
}

[class*="c-card-"] .card__title {
  margin-bottom: 0.4em;
}

[class*="c-card-"] .card__discription {
  margin-bottom: 0.4em;
  color: #595959;
}

[class*="c-card-"] .card__time {
  display: block;
  margin-bottom: 0.4em;
  color: #595959;
}

[class*="c-card-"] .card__price {
  margin: 0.2em 0;
}

[class*="c-card-"] .card__tag {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}

[class*="c-card-"] .card__tag > * {
  margin: 0 0.5em 0.5em 0;
}

@media screen and (min-width: 601px), print {
  [class*="c-card-"][class*="-lg"] .card__body {
    margin-top: 2.5rem;
  }
}

@media screen and (max-width: 600px) {
  [class*="c-card-"][class*="-lg"] .card__body {
    padding: 1.5rem;
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-card-"][class*="-md"] .card__body {
    margin-top: 1.5rem;
  }
}

@media screen and (max-width: 600px) {
  [class*="c-card-"][class*="-md"] .card__body {
    padding: 1rem;
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-card-"][class*="-sm"] .card__body {
    margin-top: 0.5rem;
  }
}

@media screen and (max-width: 600px) {
  [class*="c-card-"][class*="-sm"] .card__body {
    padding: 0.75rem;
  }
}

[class*="c-card-"][class*="-category"] {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

[class*="c-card-"][class*="-category"] .card__head {
  width: 104px;
  height: 104px;
}

[class*="c-card-"][class*="-category"] .card__head img {
  display: block;
  width: 100%;
}

[class*="c-card-"][class*="-category"] .card__body {
  width: calc(100% - 104px - 20px);
}

[class*="c-card-"][class*="-category"] .card__body--full {
  width: 100%;
}

[class*="c-card-"][class*="-category"] .card__information {
  padding-left: 1em;
}

[class*="c-card-"][class*="-category"] .card__information .information__label {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 0.4em;
}

[class*="c-card-"][class*="-category"] .card__information .information__label > * {
  margin-right: 0.5em;
  margin-bottom: 0.5em;
}

[class*="c-card-"][class*="-color"] .card__head img {
  display: block;
  width: 100%;
}

[class*="c-card-"][class*="-color"] .card__body {
  position: relative;
  margin-top: 1em;
}

@media all and (-ms-high-contrast: none) {
  [class*="c-card-"][class*="-color"] .card__body {
    min-height: 0%;
  }
}

[class*="c-card-"][class*="-color"] .card__label {
  display: flex;
}

[class*="c-card-"][class*="-color"] .card__label > * {
  width: 100%;
  margin-right: 0.5em;
}

[class*="c-card-"][class*="-color"] .card__label > *:last-child {
  margin-right: 0;
}

[class*="c-card-"][class*="-color"] .card__information {
  width: 100%;
  margin: 1em 0;
}

[class*="c-card-"][class*="-color"] .card__information dl {
  display: flex;
  width: 100%;
  padding: 0.5em 0;
  border-bottom: solid 1px #ddd;
  font-size: 0.866rem;
  line-height: 1.4;
}

[class*="c-card-"][class*="-color"] .card__information dl dt {
  width: 5em;
  margin-right: 1em;
  font-weight: bold;
}

[class*="c-card-"][class*="-color"] .card__information dl dt:after {
  content: '：';
}

[class*="c-card-"][class*="-color"] .card__information dl dd {
  width: calc(100% - 6em);
  word-wrap: break-word;
}

[class*="c-card-"][class*="-color"] .card__information .information__discription {
  width: 100%;
  border-bottom: solid 1px #ddd;
}

[class*="c-card-"][class*="-voice"] {
  position: relative;
  background-color: #fff;
}

@media screen and (min-width: 601px), print {
  [class*="c-card-"][class*="-voice"] {
    margin-bottom: 2rem;
    padding: 2rem;
  }
}

@media screen and (max-width: 600px) {
  [class*="c-card-"][class*="-voice"] {
    margin-bottom: 1rem;
    padding: 1.5rem;
  }
}

[class*="c-card-"][class*="-voice"] .card__user {
  width: 40px;
}

[class*="c-card-"][class*="-voice"] .card__name {
  margin-bottom: 0.4em;
}

[class*="c-card-"][class*="-voice"] .card__star {
  display: flex;
  align-items: center;
}

[class*="c-card-"][class*="-voice"] .card__star img {
  width: 20px;
}

[class*="c-card-"][class*="-voice"][class*="-md"] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 1px #ddd;
}

@media screen and (min-width: 601px), print {
  [class*="c-card-"][class*="-voice"][class*="-md"] {
    flex-direction: row;
  }
}

@media screen and (max-width: 600px) {
  [class*="c-card-"][class*="-voice"][class*="-md"] {
    flex-direction: column;
  }
}

[class*="c-card-"][class*="-voice"][class*="-md"] .card__head {
  display: flex;
  align-items: center;
}

@media screen and (min-width: 601px), print {
  [class*="c-card-"][class*="-voice"][class*="-md"] .card__head {
    margin-right: 3rem;
  }
}

@media screen and (max-width: 600px) {
  [class*="c-card-"][class*="-voice"][class*="-md"] .card__head {
    width: 100%;
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-card-"][class*="-voice"][class*="-md"] .card__body {
    width: 50%;
  }
}

@media screen and (max-width: 600px) {
  [class*="c-card-"][class*="-voice"][class*="-md"] .card__body {
    width: 100%;
  }
}

[class*="c-card-"][class*="-voice"][class*="-md"] .card__user {
  width: 40px;
  margin-right: 2rem;
}

@media screen and (min-width: 601px), print {
  [class*="c-card-"][class*="-voice"][class*="-md"] .card__star {
    margin: 0 auto;
  }
}

@media screen and (max-width: 600px) {
  [class*="c-card-"][class*="-voice"][class*="-md"] .card__star {
    margin-left: auto;
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-card-"][class*="-voice"][class*="-md"][class*="-simple"] .card__head {
    width: calc(20% - 3rem);
  }
}

@media screen and (max-width: 600px) {
  [class*="c-card-"][class*="-voice"][class*="-md"][class*="-simple"] .card__head {
    width: 100%;
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-card-"][class*="-voice"][class*="-md"][class*="-simple"] .card__body {
    width: 80%;
  }
}

@media screen and (max-width: 600px) {
  [class*="c-card-"][class*="-voice"][class*="-md"][class*="-simple"] .card__body {
    width: 100%;
  }
}

[class*="c-card-"][class*="-voice"][class*="-sm"] {
  border: solid 1px #ddd;
  border-radius: 4px;
}

[class*="c-card-"][class*="-voice"][class*="-sm"] .card__head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

[class*="c-card-"][class*="-voice"][class*="-sm"] .card__user {
  width: 40px;
}

[class*="c-card-"][class*="-voice"][class*="-sm"] .card__infomation {
  width: calc(100% - 40px - 100px);
  padding: 0 16px;
}

[class*="c-card-"][class*="-case"] .card__body {
  margin-top: 1rem;
}

[class*="c-card-"][class*="-blog"] .card__body {
  position: relative;
  margin-top: 1em;
}

[class*="c-card-"][class*="-blog"] .card__category {
  position: absolute;
  top: 0;
  right: 0;
  text-align: right;
}

[class*="c-card-"][class*="-blog"][class*="-sm"] {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

[class*="c-card-"][class*="-blog"][class*="-sm"] .card__head {
  width: 96px;
  height: 96px;
  margin-right: 16px;
}

[class*="c-card-"][class*="-blog"][class*="-sm"] .card__body {
  width: calc(100% - 96px - 16px);
  margin: 0;
}

[class*="c-card-"][class*="-blog"][class*="-sm"] .card__image {
  overflow: hidden;
  width: 96px;
  height: 96px;
}

[class*="c-card-"][class*="-blog"][class*="-sm"] .card__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.is-ie11 [class*="c-card-"][class*="-blog"][class*="-sm"] .card__image img {
  width: 100%;
  height: auto;
  object-fit: contain;
}

[class*="c-card-"][class*="-blog"][class*="-sm"] .card__category {
  position: relative;
  text-align: left;
}

[class*="c-card-"][class*="-step"] {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #f4f0e8;
  border-radius: 0.4rem;
  border: none;
}

@media screen and (max-width: 600px) {
  [class*="c-card-"][class*="-step"] {
    margin-bottom: 1rem;
  }
}

[class*="c-card-"][class*="-step"] img {
  display: block;
  width: 100%;
}

[class*="c-card-"][class*="-step"] .card__body {
  padding: 0;
}

[class*="c-card-"][class*="-step"] .card__title {
  margin-bottom: 1em;
}

[class*="c-card-"][class*="-step"] .card__step {
  display: inline-block;
  width: auto;
  padding: 0.2em 0.8em;
  margin-right: 1em;
  margin-bottom: 1em;
  border-radius: 0.4rem;
  background-color: #009A98;
  color: #fff;
  font-size: 0.8em;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  vertical-align: middle;
}

[class*="c-card-"][class*="-step"] .card__step-secondary {
  display: inline-block;
  width: auto;
  padding: 0.2em 0.8em;
  margin-right: 1em;
  margin-bottom: 1em;
  border-radius: 0.4rem;
  background-color: #1DAEE9;
  color: #fff;
  font-size: 0.8em;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  vertical-align: middle;
}

[class*="c-card-"][class*="-step"] .card__discription {
  margin: 0;
}

[class*="c-card-"][class*="-step"] .card__discription > * {
  margin-bottom: 1em;
}

[class*="c-card-"][class*="-step"] .card__discription > *:last-child {
  margin-bottom: 0;
}

[class*="c-card-"][class*="-step"] .card__discription .discription__sub {
  margin-top: 1.5em;
  margin-bottom: 1.5em;
}

[class*="c-card-"][class*="-step"] .card__discription .discription__sub:last-child {
  margin-bottom: 0;
}

[class*="c-card-"][class*="-step"] .card__discription .discription__sub > * {
  margin-bottom: 0.5em;
}

[class*="c-card-"][class*="-step"] .card__discription .discription__sub > *:last-child {
  margin-bottom: 0;
}

@media screen and (min-width: 601px), print {
  [class*="c-card-"][class*="-step"][class*="-horizontal"] {
    justify-content: space-between;
    flex-direction: row;
    padding: 2rem 4rem;
  }
}

@media screen and (max-width: 600px) {
  [class*="c-card-"][class*="-step"][class*="-horizontal"] {
    flex-direction: column;
    padding: 2rem 2rem;
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-card-"][class*="-step"][class*="-horizontal"] .card__head {
    width: calc((100% - 4rem) / 2);
    margin-right: 4rem;
  }
}

@media screen and (max-width: 600px) {
  [class*="c-card-"][class*="-step"][class*="-horizontal"] .card__head {
    width: 100%;
    margin-bottom: 2rem;
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-card-"][class*="-step"][class*="-horizontal"] .card__body {
    width: calc((100% - 4rem) / 2);
  }
}

@media screen and (max-width: 600px) {
  [class*="c-card-"][class*="-step"][class*="-horizontal"] .card__body {
    width: 80%;
    margin: 0 auto;
  }
}

[class*="c-card-"][class*="-step"][class*="-vertical"] {
  flex-direction: column;
  padding: 2rem 2rem;
}

[class*="c-card-"][class*="-step"][class*="-vertical"] .card__head {
  width: 100%;
  margin-right: 0;
  margin-bottom: 1rem;
}

[class*="c-card-"][class*="-step"][class*="-vertical"] .card__body {
  width: 100%;
}

a[class*="c-card-"]:hover {
  text-decoration: none !important;
  opacity: 0.6;
}

/*===========================================
    c-cardGroup
===========================================*/
[class*="c-cardGroup-"] {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (min-width: 601px), print {
  [class*="c-cardGroup-"] > * {
    margin-right: 2rem;
    margin-bottom: 2rem;
  }
}

@media screen and (max-width: 600px) {
  [class*="c-cardGroup-"] > * {
    margin-right: 1rem;
    margin-bottom: 2rem;
  }
}

[class*="c-cardGroup-"][class*="-full"] {
  flex-wrap: nowrap;
}

@media screen and (max-width: 600px) {
  [class*="c-cardGroup-"][class*="-full"] {
    flex-wrap: wrap;
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-cardGroup-"][class*="-6"] > * {
    width: calc((100% - 2rem * 5) / 6 - 0.01px);
  }
}

@media screen and (max-width: 600px) {
  [class*="c-cardGroup-"][class*="-6"] > * {
    width: calc((100% - 1rem * 1) / 2);
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-cardGroup-"][class*="-6"] > *:nth-child(6n) {
    margin-right: 0;
  }
}

@media screen and (max-width: 600px) {
  [class*="c-cardGroup-"][class*="-6"] > *:nth-child(2n) {
    margin-right: 0;
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-cardGroup-"][class*="-6"] > *:nth-last-child(6), [class*="c-cardGroup-"][class*="-6"] > *:nth-last-child(5), [class*="c-cardGroup-"][class*="-6"] > *:nth-last-child(4), [class*="c-cardGroup-"][class*="-6"] > *:nth-last-child(3), [class*="c-cardGroup-"][class*="-6"] > *:nth-last-child(2), [class*="c-cardGroup-"][class*="-6"] > *:nth-last-child(1) {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-cardGroup-"][class*="-5"] > * {
    width: calc((100% - 2rem * 4) / 5 - 0.01px);
  }
}

@media screen and (max-width: 600px) {
  [class*="c-cardGroup-"][class*="-5"] > * {
    width: calc((100% - 1rem * 1) / 2);
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-cardGroup-"][class*="-5"] > *:nth-child(5n) {
    margin-right: 0;
  }
}

@media screen and (max-width: 600px) {
  [class*="c-cardGroup-"][class*="-5"] > *:nth-child(2n) {
    margin-right: 0;
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-cardGroup-"][class*="-5"] > *:nth-last-child(5), [class*="c-cardGroup-"][class*="-5"] > *:nth-last-child(4), [class*="c-cardGroup-"][class*="-5"] > *:nth-last-child(3), [class*="c-cardGroup-"][class*="-5"] > *:nth-last-child(2), [class*="c-cardGroup-"][class*="-5"] > *:nth-last-child(1) {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-cardGroup-"][class*="-4"] > * {
    width: calc((100% - 2rem * 3) / 4 - 0.01px);
  }
}

@media screen and (max-width: 600px) {
  [class*="c-cardGroup-"][class*="-4"] > * {
    width: calc((100% - 1rem * 1) / 2);
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-cardGroup-"][class*="-4"] > *:nth-child(4n) {
    margin-right: 0;
  }
}

@media screen and (max-width: 600px) {
  [class*="c-cardGroup-"][class*="-4"] > *:nth-child(2n) {
    margin-right: 0;
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-cardGroup-"][class*="-4"] > *:nth-last-child(4), [class*="c-cardGroup-"][class*="-4"] > *:nth-last-child(3), [class*="c-cardGroup-"][class*="-4"] > *:nth-last-child(2), [class*="c-cardGroup-"][class*="-4"] > *:nth-last-child(1) {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-cardGroup-"][class*="-3"] > * {
    width: calc((100% - 2rem * 2) / 3 - 0.01px);
  }
}

@media screen and (max-width: 600px) {
  [class*="c-cardGroup-"][class*="-3"] > * {
    width: 100%;
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-cardGroup-"][class*="-3"] > *:nth-child(3n) {
    margin-right: 0;
  }
}

@media screen and (max-width: 600px) {
  [class*="c-cardGroup-"][class*="-3"] > *:nth-child(2n) {
    margin-right: 0;
  }
}

@media screen and (min-width: 601px), print {
  [class*="c-cardGroup-"][class*="-3"] > *:nth-last-child(3), [class*="c-cardGroup-"][class*="-3"] > *:nth-last-child(2), [class*="c-cardGroup-"][class*="-3"] > *:nth-last-child(1) {
    margin-bottom: 0;
  }
}

[class*="c-cardGroup-"][class*="-2"] > * {
  width: calc((100% - 2rem * 1) / 2 - 0.01px);
}

[class*="c-cardGroup-"][class*="-2"] > *:nth-child(2n) {
  margin-right: 0;
}

@media screen and (min-width: 601px), print {
  [class*="c-cardGroup-"][class*="-2"] > *:nth-last-child(2), [class*="c-cardGroup-"][class*="-2"] > *:nth-last-child(1) {
    margin-bottom: 0;
  }
}

[class*="c-cardGroup-"][class*="-1"] > * {
  width: 100%;
  margin-right: 0;
  margin-bottom: 1rem;
}

[class*="c-cardGroup-"][class*="-1"] > *:nth-last-child(1) {
  margin-bottom: 0;
}

@media screen and (max-width: 600px) {
  [class*="c-cardGroup-"][class*="-vertical"] > * {
    width: 100%;
    margin-right: 0 !important;
  }
}

/*===========================================
    c-pankuzu
===========================================*/
[class*="c-pankuzu"] {
  margin-bottom: 1rem;
}

[class*="c-pankuzu"] .pankuzu__list {
  display: flex;
  flex-wrap: wrap;
  font-size: 0.875rem;
}

[class*="c-pankuzu"] .pankuzu__list li {
  white-space: nowrap;
}

[class*="c-pankuzu"] .pankuzu__list li:after {
  content: '>';
  padding: 0 0.6em;
}

[class*="c-pankuzu"] .pankuzu__list li:last-child:after {
  content: none;
}

[class*="c-pankuzu"] .pankuzu__list li a {
  white-space: inherit;
}

[class*="c-pankuzu"][class*="-scroll"] {
  width: 100%;
}

@media screen and (min-width: 601px), print {
  [class*="c-pankuzu"][class*="-scroll"] {
    overflow-x: hidden;
  }
}

@media screen and (max-width: 600px) {
  [class*="c-pankuzu"][class*="-scroll"] {
    overflow-y: hidden;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

@media screen and (max-width: 600px) {
  [class*="c-pankuzu"][class*="-scroll"]::-webkit-scrollbar {
    display: none;
  }
}

[class*="c-pankuzu"][class*="-scroll"] .pankuzu__list {
  flex-wrap: nowrap;
}

/*===========================================
    c-pagenation
===========================================*/
[class*="c-pagenation"] {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
}

[class*="c-pagenation"] ol {
  display: flex;
  margin: 0 1rem;
  font-size: 1rem;
}

[class*="c-pagenation"] ol li {
  padding: 0 0.5em;
}

@media screen and (max-width: 600px) {
  [class*="c-pagenation"] ol li {
    padding: 0 0.3em;
  }
}

[class*="c-pagenation"] ol li a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2em;
  height: 2em;
  color: #333;
  font-weight: bold;
}

[class*="c-pagenation"] ol li a:hover {
  color: #009A98;
  text-decoration: none;
}

[class*="c-pagenation"] ol li .is-active {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2em;
  height: 2em;
  background-color: #009A98;
  border-radius: 50%;
  color: #fff;
}

[class*="c-pagenation"] .pagenation__prev,
[class*="c-pagenation"] .pagenation__next {
  width: 2em;
  height: 2em;
}

[class*="c-pagenation"] .pagenation__prev i,
[class*="c-pagenation"] .pagenation__next i {
  width: 2em;
  height: 2em;
}

/*===========================================
    c-overlay
===========================================*/
[class*="c-overlay"] {
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 101;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 0;
  pointer-events: none;
}

[class*="c-overlay"].is-active {
  opacity: 1;
  pointer-events: all;
}

/*===========================================
    c-iframe
===========================================*/
[class*="c-iframe"] {
  position: relative;
}

[class*="c-iframe"]:before {
  content: '';
  position: relative;
  display: block;
  width: 100%;
  padding-top: 56.25%;
}

[class*="c-iframe"] iframe {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
}

/*===========================================
    c-textarea
===========================================*/
[class*="c-textarea"] textarea {
  height: 400px;
}

@media screen and (max-width: 600px) {
  [class*="c-textarea"] textarea {
    height: 100px;
  }
}

/*===========================================
    c-dl
===========================================*/
[class*="c-dl"] {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 0.6em;
  margin-bottom: 0;
  font-size: 0.866rem;
}

[class*="c-dl"] dt {
  width: 50%;
  padding: 0.4em;
}

[class*="c-dl"] dd {
  width: 50%;
  padding: 0.4em;
}

[class*="c-dl"][class*="-border"] {
  border-bottom: 2px solid #e9e9e9;
  padding-bottom: 24px;
  margin-bottom: 24px;
}

@media screen and (max-width: 600px) {
  [class*="c-dl"][class*="-vertical"] {
    flex-direction: column;
    align-items: stretch;
  }
}

@media screen and (max-width: 600px) {
  [class*="c-dl"][class*="-vertical"] dt, [class*="c-dl"][class*="-vertical"] dd {
    width: 100%;
  }
}

[class*="c-dl"] .form__input [type="text"] {
  display: block;
  width: 100%;
  padding: 0;
  border-color: #fff;
  border-radius: 4px;
  color: #333;
  font-size: 0.866rem;
  letter-spacing: 0.1em;
}

/*===========================================
    c-price
===========================================*/
[class*="c-price"] {
  font-family: "Roboto", sans-serif !important;
  font-size: 1.6rem;
  font-weight: 600;
  letter-spacing: 0 !important;
}

[class*="c-price"] span {
  margin-left: 0.2em;
  font-size: 0.6em;
  vertical-align: baseline;
}

[class*="c-price"][class*="-xl"] {
  font-size: 2.4rem !important;
}

[class*="c-price"][class*="-lg"] {
  font-size: 2rem !important;
}

[class*="c-price"][class*="-md"] {
  font-size: 1.6rem !important;
}

[class*="c-price"][class*="-sm"] {
  font-size: 1.2rem !important;
}

[class*="c-price"][class*="-xs"] {
  font-size: 0.8rem !important;
}

/*===========================================
    c-toolbar
===========================================*/
.c-toolbar {
  display: flex;
  align-items: center;
  width: 100%;
}

.c-toolbar .toolbar__search {
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  margin-right: 1rem;
  font-size: 1rem;
}

.c-toolbar .toolbar__search > * {
  min-height: 1px;
}

.c-toolbar .toolbar__search .search__select {
  min-width: 120px;
}

.c-toolbar .toolbar__search .search__select select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

@media screen and (max-width: 600px) {
  .c-toolbar .toolbar__search .search__select select {
    min-height: 55px;
  }
}

.c-toolbar .toolbar__search .search__input {
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 1em;
  width: 100%;
  margin-left: -1px;
}

.c-toolbar .toolbar__search .search__input input {
  border-radius: 0;
}

@media screen and (min-width: 601px), print {
  .c-toolbar .toolbar__search .search__input input {
    height: 100%;
  }
}

@media screen and (max-width: 600px) {
  .c-toolbar .toolbar__search .search__input input {
    min-height: 55px;
  }
}

.is-ie11 .c-toolbar .toolbar__search .search__input input {
  height: 100%;
}

.c-toolbar .toolbar__search .search__button {
  width: 4rem;
  height: auto;
  padding: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

@media screen and (max-width: 600px) {
  .c-toolbar .toolbar__search .search__button {
    min-height: 55px;
  }
}

/*===========================================
    c-flow
===========================================*/
[class*="c-flow"] {
  position: relative;
}

[class*="c-flow"]:before,
[class*="c-flow"] .flow__progress {
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: calc(100% - 1rem);
  margin-top: -1px;
  margin-left: calc( (100% - 1rem) / -2);
  height: 2px;
}

[class*="c-flow"]:before {
  background-color: #ddd;
}

[class*="c-flow"] .flow__progress {
  transform: scale(0);
  transform-origin: left;
  background-color: #009A98;
}

[class*="c-flow"] .flow__step {
  display: flex;
  justify-content: space-between;
}

[class*="c-flow"] .flow__step a {
  position: relative;
  display: block;
  width: 0.6rem;
  height: 0.6rem;
  margin: 0;
  color: #fff;
}

[class*="c-flow"] .flow__step a:before {
  content: '';
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #ddd;
}

[class*="c-flow"] .flow__step a.is-active:before, [class*="c-flow"] .flow__step a.is-on:before {
  background-color: #009A98;
}

[class*="c-flow"] .flow__balloon {
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute;
  bottom: 1.4rem;
  left: 50%;
  transform: translate(-50%, 10%);
  background-color: #009A98;
  color: #fff;
  white-space: nowrap;
  opacity: 0;
}

[class*="c-flow"] .flow__balloon:after {
  border-color: #009A98 transparent transparent transparent !important;
}

[class*="c-flow"] .flow__balloon.is-active {
  transform: translate(-50%, 0);
  opacity: 1;
}

/*===========================================
    c-listNavigation
===========================================*/
[class*="c-listNavigation"] {
  position: relative;
}

@media screen and (min-width: 601px), print {
  [class*="c-listNavigation"] {
    min-height: 32px;
  }
}

[class*="c-listNavigation"] .listNavigation__infomation {
  font-size: 1.133rem;
  font-weight: bold;
}

@media screen and (min-width: 601px), print {
  [class*="c-listNavigation"] .listNavigation__infomation {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
}

@media screen and (max-width: 600px) {
  [class*="c-listNavigation"] .listNavigation__infomation {
    margin-bottom: 2rem;
  }
}

[class*="c-listNavigation"] .listNavigation__infomation span {
  font-size: 0.8em;
  vertical-align: baseline;
}

[class*="c-listNavigation"] .listNavigation__pagenation {
  margin: 0 auto;
}

@media screen and (min-width: 601px), print {
  [class*="c-listNavigation"] .listNavigation__pagenation {
    width: 400px;
  }
}

@media screen and (max-width: 600px) {
  [class*="c-listNavigation"] .listNavigation__pagenation {
    width: 100%;
  }
}

@media screen and (min-width: 601px), print {
  .is-ie11 [class*="c-listNavigation"] .listNavigation__pagenation {
    width: auto;
  }
}

/*===========================================
    c-section
===========================================*/
[class*="c-section"] {
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  border-bottom: solid 1px #ddd;
}

[class*="c-section"]:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

[class*="c-section"] > * {
  margin-bottom: 2rem;
}

[class*="c-section"] > *:last-child {
  margin-bottom: 0;
}

@media screen and (max-width: 600px) {
  [class*="c-section"][class*="--noPadding"] {
    padding-left: 0;
    padding-right: 0;
  }
}

@media screen and (max-width: 600px) {
  [class*="c-section"][class*="--noPadding"] .section__title {
    padding: 0 16px;
  }
}

[class*="c-section"][class*="-cart"] {
  border-bottom: none;
}

[class*="c-section"] .section__sub {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

[class*="c-section"] .section__sub > * {
  margin-bottom: 2rem;
}

[class*="c-section"] .section__sub > *:last-child {
  margin-bottom: 0;
}

[class*="c-section"] .section__sub:first-child {
  margin-top: 0;
}

[class*="c-section"] .section__sub:last-child {
  margin-bottom: 0;
}

[class*="c-section"] .section__title {
  margin-bottom: 0.8em;
}

[class*="c-section"] .section__caption {
  margin-bottom: 0.6em;
}

/*===========================================
    c-pageIndex
===========================================*/
[class*="c-pageIndex"] {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 3rem;
}

[class*="c-pageIndex"] > * {
  margin: 0.5em !important;
}

/*===========================================
    c-recommend
===========================================*/
[class*="c-recommend"] {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: auto;
}

[class*="c-recommend"] .recommend__title {
  margin-right: 0.5em;
  font-size: 0.866rem;
  font-weight: bold;
}

[class*="c-recommend"] .recommend__content {
  width: auto;
}

[class*="c-recommend"] .recommend__content .content__image {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

[class*="c-recommend"] .recommend__content .content__image img {
  display: block;
  width: auto;
  height: 100%;
}

[class*="c-recommend"] .recommend__content .content__number {
  font-family: "Roboto", sans-serif;
  font-size: 2.0rem;
}

[class*="c-recommend"] .recommend__content .content__number span {
  font-size: 0.6em;
  vertical-align: baseline;
}

/*===========================================
    c-newsList
===========================================*/
.c-newsList .newsList__item {
  border-top: solid 1px #ddd;
}

.c-newsList .newsList__item:first-child {
  border: none;
}

.c-newsList .newsList__item a {
  display: block;
  color: #333;
  font-weight: normal;
  text-decoration: none;
}

.c-newsList .newsList__item a:hover {
  background-color: #f3f3f3;
}

.c-newsList .newsList__item .item__inner {
  display: flex;
  align-items: flex-start;
  padding: 1em;
}

@media screen and (max-width: 600px) {
  .c-newsList .newsList__item .item__inner {
    flex-wrap: wrap;
  }
}

.c-newsList .newsList__item .item__time {
  width: 5em;
}

.c-newsList .newsList__item .item__time,
.c-newsList .newsList__item .item__category {
  margin-bottom: 0 !important;
  margin-right: 2em;
}

@media screen and (max-width: 600px) {
  .c-newsList .newsList__item .item__title {
    width: 100%;
    margin-top: 1em;
  }
}

/*===========================================
    c-faqList
===========================================*/
.c-faqList .faqList__item {
  padding: 1.5rem 1rem;
  border-top: solid 1px #ddd;
}

.c-faqList .faqList__item:first-child {
  border: none;
}

.c-faqList .faqList__item .item__question {
  position: relative;
  display: flex;
  align-items: center;
  padding-right: calc(20px + 4em);
  color: #333;
  font-size: 1.133rem;
  text-decoration: none;
}

.c-faqList .faqList__item .item__question:hover {
  opacity: 0.6;
}

.c-faqList .faqList__item .item__question .question__icon {
  margin-right: 1em;
  font-size: 2.4rem;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
}

.c-faqList .faqList__item .item__question .question__plus {
  position: absolute;
  top: 50%;
  right: 1.5rem;
  transform: translateY(-50%);
  width: 2em;
  height: 2em;
}

.c-faqList .faqList__item .item__answer {
  position: relative;
  display: flex;
  align-items: center;
  padding-top: 1.5rem;
  padding-right: calc(1.5rem + 4em);
  color: #333;
  font-size: 1rem;
  text-decoration: none;
}

.c-faqList .faqList__item .item__answer .answer__icon {
  margin-right: 1em;
  color: #DE1816;
  font-size: 2.4rem;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
}

.c-faqList .faqList__item .item__answer .answer__title {
  padding: 1.5rem;
  background-color: #f3f3f3;
  border-radius: 8px;
}

.c-faqList .faqList__item .item__answer .answer__arrow {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 2em;
  height: 2em;
}

/*===========================================
    c-historyList
===========================================*/
.c-historyList .historyList__item {
  display: flex;
  justify-content: flex-start;
  font-size: 0.866rem;
}

.c-historyList .historyList__item:last-child {
  border-bottom: none;
}

.c-historyList .historyList__item time {
  width: 5rem;
  margin-bottom: 0 !important;
  margin-right: 1rem;
}

.c-historyList .historyList__item .item__title {
  width: calc(100% - 6rem);
}

/*===========================================
    c-companyList
===========================================*/
.c-companyList .companyList__item {
  padding: 2rem 1rem;
  border-top: solid 1px #ddd;
}

.c-companyList .companyList__item:first-child {
  border: none;
}

@media screen and (min-width: 601px), print {
  .c-companyList .companyList__item .item__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

@media screen and (min-width: 601px), print {
  .c-companyList .companyList__item .item__image {
    width: 200px;
    margin-right: 2rem;
  }
}

@media screen and (max-width: 600px) {
  .c-companyList .companyList__item .item__image {
    max-width: 200px;
    width: 80%;
    margin: 0 auto;
  }
}

.c-companyList .companyList__item .item__image img {
  width: 100%;
}

@media screen and (min-width: 601px), print {
  .c-companyList .companyList__item .item__company {
    margin-right: 2rem;
  }
}

@media screen and (max-width: 600px) {
  .c-companyList .companyList__item .item__company {
    width: 100%;
    margin: 1rem auto;
  }
}

@media screen and (min-width: 601px), print {
  .c-companyList .companyList__item .item__information {
    width: 200px;
    padding-left: 2rem;
    border-left: solid 1px #ddd;
  }
}

@media screen and (max-width: 600px) {
  .c-companyList .companyList__item .item__information {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}

@media screen and (min-width: 601px), print {
  .c-companyList .companyList__item .item__information .information__tel a[href^="tel:"] {
    pointer-events: none;
  }
}

.c-companyList .companyList__item .item__information .information__tel:before {
  content: 'Tel:';
  margin-right: 0.5em;
}

@media screen and (max-width: 600px) {
  .c-companyList .companyList__item .item__information .item__category {
    text-align: right;
  }
}

/*===========================================
    c-inportantNews
===========================================*/
.c-inportantNews {
  position: relative;
  padding: 1.4rem;
  margin-bottom: 2rem;
  border: solid 4px #f06b6a;
  border-radius: 0.3rem;
}

.c-inportantNews.is-hide {
  display: none;
}

.c-inportantNews * {
  color: #DE1816;
}

.c-inportantNews .inportantNews__close {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

/*===========================================
    u-clearfix
===========================================*/
.u-clearfix:after {
  display: block;
  clear: both;
  height: 0;
  font-size: 0.1em;
  line-height: 0;
  content: "";
}

/*===========================================
    u-display
===========================================*/
.u-sagyou--hide {
  display: none !important;
}

[class*="u-display"][class*="-block"] {
  display: block !important;
}

[class*="u-display"][class*="-inline"] {
  display: inline !important;
}

[class*="u-display"][class*="-inlineBlock"] {
  display: inline-block !important;
}

[class*="u-display"][class*="-flex"] {
  display: flex !important;
  align-items: flex-start !important;
}

[class*="u-display"][class*="-flex"][class*="-center"] {
  align-items: center !important;
}

[class*="u-display"][class*="-flex"][class*="-wrap"] {
  flex-wrap: wrap !important;
}

[class*="u-display"][class*="-flex"] > * {
  margin-bottom: 0 !important;
}

[class*="u-display"][class*="-flex"][class*="-end"] {
  justify-content: flex-end !important;
}

@media screen and (max-width: 600px) {
  [class*="u-display"][class*="-none"][class*="-sm"] {
    display: none !important;
  }
}

@media screen and (min-width: 601px) and (max-width: 600px) {
  [class*="u-display"][class*="-none"][class*="-md"] {
    display: none !important;
  }
}

@media screen and (min-width: 601px), print {
  [class*="u-display"][class*="-none"][class*="-lg"] {
    display: none !important;
  }
}

[class*="u-display"][class*="-none"][class*="-all"] {
  display: none !important;
}

/*===========================================
    u-width
===========================================*/
.u-width--0 {
  width: 0% !important;
}

.u-width--5 {
  width: 5% !important;
}

.u-width--10 {
  width: 10% !important;
}

.u-width--15 {
  width: 15% !important;
}

.u-width--20 {
  width: 20% !important;
}

.u-width--25 {
  width: 25% !important;
}

.u-width--30 {
  width: 30% !important;
}

.u-width--35 {
  width: 35% !important;
}

.u-width--40 {
  width: 40% !important;
}

.u-width--45 {
  width: 45% !important;
}

.u-width--50 {
  width: 50% !important;
}

.u-width--55 {
  width: 55% !important;
}

.u-width--60 {
  width: 60% !important;
}

.u-width--65 {
  width: 65% !important;
}

.u-width--70 {
  width: 70% !important;
}

.u-width--75 {
  width: 75% !important;
}

.u-width--80 {
  width: 80% !important;
}

.u-width--85 {
  width: 85% !important;
}

.u-width--90 {
  width: 90% !important;
}

.u-width--95 {
  width: 95% !important;
}

.u-width--100 {
  width: 100% !important;
}

.u-width--0rem {
  width: 0rem !important;
}

.u-width--1rem {
  width: 1rem !important;
}

.u-width--2rem {
  width: 2rem !important;
}

.u-width--3rem {
  width: 3rem !important;
}

.u-width--4rem {
  width: 4rem !important;
}

.u-width--5rem {
  width: 5rem !important;
}

.u-width--6rem {
  width: 6rem !important;
}

.u-width--7rem {
  width: 7rem !important;
}

.u-width--8rem {
  width: 8rem !important;
}

.u-width--9rem {
  width: 9rem !important;
}

.u-width--10rem {
  width: 10rem !important;
}

.u-width--11rem {
  width: 11rem !important;
}

.u-width--12rem {
  width: 12rem !important;
}

.u-width--13rem {
  width: 13rem !important;
}

.u-width--14rem {
  width: 14rem !important;
}

.u-width--15rem {
  width: 15rem !important;
}

.u-width--16rem {
  width: 16rem !important;
}

.u-width--17rem {
  width: 17rem !important;
}

.u-width--18rem {
  width: 18rem !important;
}

.u-width--19rem {
  width: 19rem !important;
}

.u-width--20rem {
  width: 20rem !important;
}

.u-width--21rem {
  width: 21rem !important;
}

.u-width--22rem {
  width: 22rem !important;
}

.u-width--23rem {
  width: 23rem !important;
}

.u-width--24rem {
  width: 24rem !important;
}

.u-width--25rem {
  width: 25rem !important;
}

.u-width--26rem {
  width: 26rem !important;
}

.u-width--27rem {
  width: 27rem !important;
}

.u-width--28rem {
  width: 28rem !important;
}

.u-width--29rem {
  width: 29rem !important;
}

.u-width--30rem {
  width: 30rem !important;
}

.u-width--31rem {
  width: 31rem !important;
}

.u-width--32rem {
  width: 32rem !important;
}

.u-width--33rem {
  width: 33rem !important;
}

.u-width--34rem {
  width: 34rem !important;
}

.u-width--35rem {
  width: 35rem !important;
}

.u-width--36rem {
  width: 36rem !important;
}

.u-width--37rem {
  width: 37rem !important;
}

.u-width--38rem {
  width: 38rem !important;
}

.u-width--39rem {
  width: 39rem !important;
}

.u-width--40rem {
  width: 40rem !important;
}

.u-width--auto {
  width: auto !important;
}

/*===========================================
    u-margin
===========================================*/
.u-margin--top-0 {
  margin-top: 0rem !important;
}

.u-margin--top-2 {
  margin-top: 0.14rem !important;
}

.u-margin--top-4 {
  margin-top: 0.29rem !important;
}

.u-margin--top-6 {
  margin-top: 0.43rem !important;
}

.u-margin--top-8 {
  margin-top: 0.57rem !important;
}

.u-margin--top-10 {
  margin-top: 0.71rem !important;
}

.u-margin--top-12 {
  margin-top: 0.86rem !important;
}

.u-margin--top-14 {
  margin-top: 1rem !important;
}

.u-margin--top-16 {
  margin-top: 1.14rem !important;
}

.u-margin--top-18 {
  margin-top: 1.29rem !important;
}

.u-margin--top-20 {
  margin-top: 1.43rem !important;
}

.u-margin--top-22 {
  margin-top: 1.57rem !important;
}

.u-margin--top-24 {
  margin-top: 1.71rem !important;
}

.u-margin--top-26 {
  margin-top: 1.86rem !important;
}

.u-margin--top-28 {
  margin-top: 2rem !important;
}

.u-margin--top-30 {
  margin-top: 2.14rem !important;
}

.u-margin--top-32 {
  margin-top: 2.29rem !important;
}

.u-margin--top-34 {
  margin-top: 2.43rem !important;
}

.u-margin--top-36 {
  margin-top: 2.57rem !important;
}

.u-margin--top-38 {
  margin-top: 2.71rem !important;
}

.u-margin--top-40 {
  margin-top: 2.86rem !important;
}

.u-margin--top-42 {
  margin-top: 3rem !important;
}

.u-margin--top-44 {
  margin-top: 3.14rem !important;
}

.u-margin--top-46 {
  margin-top: 3.29rem !important;
}

.u-margin--top-48 {
  margin-top: 3.43rem !important;
}

.u-margin--top-50 {
  margin-top: 3.57rem !important;
}

.u-margin--top-52 {
  margin-top: 3.71rem !important;
}

.u-margin--top-54 {
  margin-top: 3.86rem !important;
}

.u-margin--top-56 {
  margin-top: 4rem !important;
}

.u-margin--top-58 {
  margin-top: 4.14rem !important;
}

.u-margin--top-60 {
  margin-top: 4.29rem !important;
}

.u-margin--top-62 {
  margin-top: 4.43rem !important;
}

.u-margin--top-64 {
  margin-top: 4.57rem !important;
}

.u-margin--top-66 {
  margin-top: 4.71rem !important;
}

.u-margin--top-68 {
  margin-top: 4.86rem !important;
}

.u-margin--top-70 {
  margin-top: 5rem !important;
}

.u-margin--top-72 {
  margin-top: 5.14rem !important;
}

.u-margin--top-74 {
  margin-top: 5.29rem !important;
}

.u-margin--top-76 {
  margin-top: 5.43rem !important;
}

.u-margin--top-78 {
  margin-top: 5.57rem !important;
}

.u-margin--top-80 {
  margin-top: 5.71rem !important;
}

.u-margin--right-0 {
  margin-right: 0rem !important;
}

.u-margin--right-2 {
  margin-right: 0.14rem !important;
}

.u-margin--right-4 {
  margin-right: 0.29rem !important;
}

.u-margin--right-6 {
  margin-right: 0.43rem !important;
}

.u-margin--right-8 {
  margin-right: 0.57rem !important;
}

.u-margin--right-10 {
  margin-right: 0.71rem !important;
}

.u-margin--right-12 {
  margin-right: 0.86rem !important;
}

.u-margin--right-14 {
  margin-right: 1rem !important;
}

.u-margin--right-16 {
  margin-right: 1.14rem !important;
}

.u-margin--right-18 {
  margin-right: 1.29rem !important;
}

.u-margin--right-20 {
  margin-right: 1.43rem !important;
}

.u-margin--right-22 {
  margin-right: 1.57rem !important;
}

.u-margin--right-24 {
  margin-right: 1.71rem !important;
}

.u-margin--right-26 {
  margin-right: 1.86rem !important;
}

.u-margin--right-28 {
  margin-right: 2rem !important;
}

.u-margin--right-30 {
  margin-right: 2.14rem !important;
}

.u-margin--right-32 {
  margin-right: 2.29rem !important;
}

.u-margin--right-34 {
  margin-right: 2.43rem !important;
}

.u-margin--right-36 {
  margin-right: 2.57rem !important;
}

.u-margin--right-38 {
  margin-right: 2.71rem !important;
}

.u-margin--right-40 {
  margin-right: 2.86rem !important;
}

.u-margin--right-42 {
  margin-right: 3rem !important;
}

.u-margin--right-44 {
  margin-right: 3.14rem !important;
}

.u-margin--right-46 {
  margin-right: 3.29rem !important;
}

.u-margin--right-48 {
  margin-right: 3.43rem !important;
}

.u-margin--right-50 {
  margin-right: 3.57rem !important;
}

.u-margin--right-52 {
  margin-right: 3.71rem !important;
}

.u-margin--right-54 {
  margin-right: 3.86rem !important;
}

.u-margin--right-56 {
  margin-right: 4rem !important;
}

.u-margin--right-58 {
  margin-right: 4.14rem !important;
}

.u-margin--right-60 {
  margin-right: 4.29rem !important;
}

.u-margin--right-62 {
  margin-right: 4.43rem !important;
}

.u-margin--right-64 {
  margin-right: 4.57rem !important;
}

.u-margin--right-66 {
  margin-right: 4.71rem !important;
}

.u-margin--right-68 {
  margin-right: 4.86rem !important;
}

.u-margin--right-70 {
  margin-right: 5rem !important;
}

.u-margin--right-72 {
  margin-right: 5.14rem !important;
}

.u-margin--right-74 {
  margin-right: 5.29rem !important;
}

.u-margin--right-76 {
  margin-right: 5.43rem !important;
}

.u-margin--right-78 {
  margin-right: 5.57rem !important;
}

.u-margin--right-80 {
  margin-right: 5.71rem !important;
}

.u-margin--bottom-0 {
  margin-bottom: 0rem !important;
}

.u-margin--bottom-2 {
  margin-bottom: 0.14rem !important;
}

.u-margin--bottom-4 {
  margin-bottom: 0.29rem !important;
}

.u-margin--bottom-6 {
  margin-bottom: 0.43rem !important;
}

.u-margin--bottom-8 {
  margin-bottom: 0.57rem !important;
}

.u-margin--bottom-10 {
  margin-bottom: 0.71rem !important;
}

.u-margin--bottom-12 {
  margin-bottom: 0.86rem !important;
}

.u-margin--bottom-14 {
  margin-bottom: 1rem !important;
}

.u-margin--bottom-16 {
  margin-bottom: 1.14rem !important;
}

.u-margin--bottom-18 {
  margin-bottom: 1.29rem !important;
}

.u-margin--bottom-20 {
  margin-bottom: 1.43rem !important;
}

.u-margin--bottom-22 {
  margin-bottom: 1.57rem !important;
}

.u-margin--bottom-24 {
  margin-bottom: 1.71rem !important;
}

.u-margin--bottom-26 {
  margin-bottom: 1.86rem !important;
}

.u-margin--bottom-28 {
  margin-bottom: 2rem !important;
}

.u-margin--bottom-30 {
  margin-bottom: 2.14rem !important;
}

.u-margin--bottom-32 {
  margin-bottom: 2.29rem !important;
}

.u-margin--bottom-34 {
  margin-bottom: 2.43rem !important;
}

.u-margin--bottom-36 {
  margin-bottom: 2.57rem !important;
}

.u-margin--bottom-38 {
  margin-bottom: 2.71rem !important;
}

.u-margin--bottom-40 {
  margin-bottom: 2.86rem !important;
}

.u-margin--bottom-42 {
  margin-bottom: 3rem !important;
}

.u-margin--bottom-44 {
  margin-bottom: 3.14rem !important;
}

.u-margin--bottom-46 {
  margin-bottom: 3.29rem !important;
}

.u-margin--bottom-48 {
  margin-bottom: 3.43rem !important;
}

.u-margin--bottom-50 {
  margin-bottom: 3.57rem !important;
}

.u-margin--bottom-52 {
  margin-bottom: 3.71rem !important;
}

.u-margin--bottom-54 {
  margin-bottom: 3.86rem !important;
}

.u-margin--bottom-56 {
  margin-bottom: 4rem !important;
}

.u-margin--bottom-58 {
  margin-bottom: 4.14rem !important;
}

.u-margin--bottom-60 {
  margin-bottom: 4.29rem !important;
}

.u-margin--bottom-62 {
  margin-bottom: 4.43rem !important;
}

.u-margin--bottom-64 {
  margin-bottom: 4.57rem !important;
}

.u-margin--bottom-66 {
  margin-bottom: 4.71rem !important;
}

.u-margin--bottom-68 {
  margin-bottom: 4.86rem !important;
}

.u-margin--bottom-70 {
  margin-bottom: 5rem !important;
}

.u-margin--bottom-72 {
  margin-bottom: 5.14rem !important;
}

.u-margin--bottom-74 {
  margin-bottom: 5.29rem !important;
}

.u-margin--bottom-76 {
  margin-bottom: 5.43rem !important;
}

.u-margin--bottom-78 {
  margin-bottom: 5.57rem !important;
}

.u-margin--bottom-80 {
  margin-bottom: 5.71rem !important;
}

.u-margin--left-0 {
  margin-left: 0rem !important;
}

.u-margin--left-2 {
  margin-left: 0.14rem !important;
}

.u-margin--left-4 {
  margin-left: 0.29rem !important;
}

.u-margin--left-6 {
  margin-left: 0.43rem !important;
}

.u-margin--left-8 {
  margin-left: 0.57rem !important;
}

.u-margin--left-10 {
  margin-left: 0.71rem !important;
}

.u-margin--left-12 {
  margin-left: 0.86rem !important;
}

.u-margin--left-14 {
  margin-left: 1rem !important;
}

.u-margin--left-16 {
  margin-left: 1.14rem !important;
}

.u-margin--left-18 {
  margin-left: 1.29rem !important;
}

.u-margin--left-20 {
  margin-left: 1.43rem !important;
}

.u-margin--left-22 {
  margin-left: 1.57rem !important;
}

.u-margin--left-24 {
  margin-left: 1.71rem !important;
}

.u-margin--left-26 {
  margin-left: 1.86rem !important;
}

.u-margin--left-28 {
  margin-left: 2rem !important;
}

.u-margin--left-30 {
  margin-left: 2.14rem !important;
}

.u-margin--left-32 {
  margin-left: 2.29rem !important;
}

.u-margin--left-34 {
  margin-left: 2.43rem !important;
}

.u-margin--left-36 {
  margin-left: 2.57rem !important;
}

.u-margin--left-38 {
  margin-left: 2.71rem !important;
}

.u-margin--left-40 {
  margin-left: 2.86rem !important;
}

.u-margin--left-42 {
  margin-left: 3rem !important;
}

.u-margin--left-44 {
  margin-left: 3.14rem !important;
}

.u-margin--left-46 {
  margin-left: 3.29rem !important;
}

.u-margin--left-48 {
  margin-left: 3.43rem !important;
}

.u-margin--left-50 {
  margin-left: 3.57rem !important;
}

.u-margin--left-52 {
  margin-left: 3.71rem !important;
}

.u-margin--left-54 {
  margin-left: 3.86rem !important;
}

.u-margin--left-56 {
  margin-left: 4rem !important;
}

.u-margin--left-58 {
  margin-left: 4.14rem !important;
}

.u-margin--left-60 {
  margin-left: 4.29rem !important;
}

.u-margin--left-62 {
  margin-left: 4.43rem !important;
}

.u-margin--left-64 {
  margin-left: 4.57rem !important;
}

.u-margin--left-66 {
  margin-left: 4.71rem !important;
}

.u-margin--left-68 {
  margin-left: 4.86rem !important;
}

.u-margin--left-70 {
  margin-left: 5rem !important;
}

.u-margin--left-72 {
  margin-left: 5.14rem !important;
}

.u-margin--left-74 {
  margin-left: 5.29rem !important;
}

.u-margin--left-76 {
  margin-left: 5.43rem !important;
}

.u-margin--left-78 {
  margin-left: 5.57rem !important;
}

.u-margin--left-80 {
  margin-left: 5.71rem !important;
}

.u-margin--auto {
  margin-left: auto;
  margin-right: auto;
}

/*===========================================
    u-indent
===========================================*/
.u-indent--1 {
  padding-left: 0.5em !important;
  text-indent: -0.5em !important;
}

.u-indent--2 {
  padding-left: 1em !important;
  text-indent: -1em !important;
}

.u-indent--3 {
  padding-left: 1.5em !important;
  text-indent: -1.5em !important;
}

.u-indent--4 {
  padding-left: 2em !important;
  text-indent: -2em !important;
}

/*===========================================
    u-whitespace
===========================================*/
.u-whitespace--nomal {
  white-space: normal !important;
}

.u-whitespace--nowrap {
  white-space: nowrap !important;
}

/*===========================================
    u-preformatted
===========================================*/
.u-preformatted * {
  font-size: 0.875rem !important;
  line-height: 1.2em !important;
}

.u-preformatted code {
  padding: 2rem !important;
}

/*===========================================
    js-accordion
===========================================*/
@media screen and (max-width: 600px) {
  .js-accordion[data-type="lg"] .js-accordion__trigger {
    pointer-events: none;
  }
}

@media screen and (max-width: 600px) {
  .js-accordion[data-type="lg"] .js-accordion__content {
    display: block !important;
  }
}

@media screen and (min-width: 601px), print {
  .js-accordion[data-type="sm"] .js-accordion__trigger {
    pointer-events: none;
  }
}

@media screen and (min-width: 601px), print {
  .js-accordion[data-type="sm"] .js-accordion__content {
    display: block !important;
  }
}

.js-accordion__trigger {
  cursor: pointer;
}

.js-accordion__content {
  display: none;
  margin-top: 1.4rem;
}

.js-accordion__content.is-active {
  display: block;
  pointer-events: all;
}

/*===========================================
    js-dropdown
===========================================*/
.js-dropdown {
  position: relative;
  cursor: pointer;
}

@media screen and (min-width: 601px), print {
  [class*="js-dropdown__content-"] {
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: ease;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    position: absolute;
    z-index: 1000;
    pointer-events: none;
    opacity: 0;
  }
}

@media screen and (min-width: 601px), print {
  [class*="js-dropdown__content-"][class*="-top"] {
    bottom: 100%;
    right: 0;
    transform: translate(0, 1rem);
  }
}

@media screen and (min-width: 601px), print {
  [class*="js-dropdown__content-"][class*="-bottom"] {
    top: 100%;
    right: 0;
    transform: translate(0, -1rem);
  }
}

@media screen and (min-width: 601px), print {
  [class*="js-dropdown__content-"][class*="-left"] {
    top: -1.2rem;
    right: 100%;
    transform: translate(1em, 0);
  }
}

@media screen and (min-width: 601px), print {
  [class*="js-dropdown__content-"][class*="-right"] {
    top: -1.2rem;
    left: 100%;
    transform: translate(-1em, 0);
  }
}

[class*="js-dropdown__content-"].is-active {
  visibility: visible;
  transform: translate(0, 0);
  pointer-events: all;
  opacity: 1;
}

/*===========================================
    js-modal
===========================================*/
.js-modal--image {
  cursor: pointer;
}

.js-modal--image:hover {
  opacity: 0.6;
}

.js-modal__trigger--open {
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  cursor: pointer;
}

.js-modal__trigger--open:hover {
  opacity: 0.6;
}

.js-modal__wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2001;
  display: none;
  overflow: scroll;
  width: 100%;
  height: 100%;
  padding: 0;
}

.js-modal__overlay {
  z-index: 2000;
}

.js-modal__content {
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute;
  z-index: 2001;
  transform: scale(0.9, 0.9);
  margin: 0 auto;
  padding: 40px;
  background: #fff;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.2);
  opacity: 0;
  pointer-events: none;
}

@media screen and (min-width: 601px), print {
  .js-modal__content {
    max-width: 1000px;
  }
}

@media screen and (max-width: 600px) {
  .js-modal__content {
    max-width: calc(100% - 32px);
  }
}

.js-modal__content img {
  display: block;
  width: auto;
  height: auto;
}

.js-modal__content.is-active {
  transform: scale(1, 1);
  opacity: 1;
  pointer-events: all;
}

.js-modal__origin {
  display: none;
}

/*===========================================
    js-tab
===========================================*/
.js-tab__content {
  position: relative;
}

.js-tab__item {
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
}

.js-tab__item.is-active {
  position: relative;
  opacity: 1;
  pointer-events: all;
}

/*===========================================
    js-loading
===========================================*/
.js-loading {
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  opacity: 1;
}

.js-loading.is-active {
  opacity: 0;
  pointer-events: none;
}

/*===========================================
    js-slick
===========================================*/
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-list,
.slick-slider .slick-track {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
}

.slick-track:after, .slick-track:before {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  min-height: 1px;
  height: 100%;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

/*===========================================
    js-slick
===========================================*/
.slick-next,
.slick-prev {
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 100;
  padding: 0;
  background-color: transparent;
  font-size: 0;
  cursor: pointer;
}

@media screen and (min-width: 601px), print {
  .slick-next,
  .slick-prev {
    width: 48px;
    height: 48px;
  }
}

@media screen and (max-width: 600px) {
  .slick-next,
  .slick-prev {
    width: 32px;
    height: 32px;
  }
}

.slick-next > *,
.slick-prev > * {
  display: block;
  width: 100%;
  height: 100%;
}

@media screen and (min-width: 601px), print {
  .slick-prev {
    left: -56px;
  }
}

@media screen and (max-width: 600px) {
  .slick-prev {
    left: -24px;
  }
}

@media screen and (min-width: 601px), print {
  .slick-next {
    right: -56px;
  }
}

@media screen and (max-width: 600px) {
  .slick-next {
    right: -24px;
  }
}

.slick-dots {
  position: absolute;
  left: 50%;
  bottom: -1em;
  z-index: 0;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  transform: translateX(-50%);
  width: auto;
  height: 4px;
  margin: 0 auto;
  text-align: center;
  list-style: none;
}

@media screen and (max-width: 600px) {
  .slick-dots {
    padding: 0 88px;
  }
}

.slick-dots li {
  position: relative;
  overflow: hidden;
  width: 8px;
  height: 8px;
  margin: 0 8px;
  padding: 0;
  border-radius: 50%;
  cursor: pointer;
}

.slick-dots li button {
  display: block;
  width: 100%;
  height: 100%;
  border: 0;
  background: #ddd;
  color: transparent;
  font-size: 0;
  line-height: 0;
  outline: none;
  cursor: pointer;
}

.slick-dots li.slick-active button {
  background: #009A98;
}

.shop__slick .slick-prev,
.shop__slick .slick-next {
  top: 40%;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-size: 16px;
  background-position: center;
  background-color: palettes(black);
}

.shop__slick .slick-prev {
  left: 6.5%;
}

.shop__slick .slick-prev i:before {
  transform: rotate(-180deg);
}

.shop__slick .slick-next {
  right: 6.5%;
}

/*===========================================
    js-imageSwitch
===========================================*/
.js-imageSwitch__target {
  position: relative;
  margin-bottom: 16px;
}

.js-imageSwitch__target > * {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  overflow: hidden;
  width: 100%;
  opacity: 0;
}

.js-imageSwitch__target > *.is-active {
  position: relative;
  z-index: 1;
  opacity: 1;
}

.js-imageSwitch__thumbnail {
  display: flex;
  justify-content: center;
}

.js-imageSwitch__thumbnail > * {
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: relative;
  overflow: hidden;
  width: 48px;
  margin-right: 8px;
  cursor: pointer;
}

.js-imageSwitch__thumbnail > *:after {
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(1.4);
  display: block;
  width: calc(100% - 8px);
  height: calc(100% - 8px);
  border: solid 4px #009A98;
}

.js-imageSwitch__thumbnail > *:last-child {
  margin-right: 0;
}

.js-imageSwitch__thumbnail > *:hover {
  opacity: 0.6;
}

.js-imageSwitch__thumbnail > *.is-active:after {
  transform: scale(1);
}

.js-imageSwitch__thumbnail > * img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

[class*="js-imageSwitch__item"] {
  position: relative;
}

[class*="js-imageSwitch__item"]:before {
  content: '';
  display: block;
  width: 100%;
  padding-top: calc(100% * 0.637);
}

[class*="js-imageSwitch__item"] img, [class*="js-imageSwitch__item"] iframe {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

[class*="js-imageSwitch__item"] img {
  display: block;
  width: auto;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

[class*="js-imageSwitch__item"] iframe {
  display: block;
  width: 100%;
  height: 100%;
}

[class*="js-imageSwitch__item"][class*="-thumb"]:before {
  padding-top: 100%;
}

[class*="js-imageSwitch__item"][class*="-thumb"] img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/*===========================================
    js-imageZoom
===========================================*/
.js-imageZoom__content {
  position: absolute;
  top: 0;
  left: 100%;
  width: 100%;
  height: 100%;
  background-color: #000;
}

/*===========================================
    js-scrollHidden
===========================================*/
.js-scrollHidden {
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

/*===========================================
    js-scrollFixed
===========================================*/
.js-scrollFixed {
  position: relative;
}

.js-scrollFixed__target {
  transition-property: all;
  transition-duration: 0;
  transition-timing-function: ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

@media screen and (min-width: 601px), print {
  .js-scrollFixed__target {
    position: absolute;
    left: 0;
    right: auto !important;
    z-index: 100;
  }
}

@media screen and (max-width: 600px) {
  .js-scrollFixed__target {
    position: relative !important;
    left: 0 !important;
  }
}

@media screen and (min-width: 601px), print {
  .js-scrollFixed__target.is-fixed {
    position: fixed  !important;
  }
}

/*===========================================
    js-addContent
===========================================*/
.js-addContent__content {
  display: none;
}

.js-addContent__content.is-active {
  display: block;
}
