/*===========================================
    c-form
===========================================*/
$color-form-primary:    $color-primary;
$color-form-text:       $color-text;
$color-form-border:     darken($color-border, 10%);
$color-form-background: $color-white;
$color-form-success:    $color-success;
$color-form-warning:    $color-warning;
$color-form-danger:     $color-danger;

$margin-right-form:     0;
$padding-form:          0.4em 0.6em;
$border-form:           solid 1px $color-form-border;
$border-radius-form:    4px;
$fontsize-form:         15px;
$fontsize-form-sm:      16px;
// $fontsize-scale:        scale(0.8125);

[class*="c-form"] {
  width: 100%;

  //-----------------------------------------
  //  form reset
  //-----------------------------------------
  input,
  select,
  textarea {
    border: none;
    //border-radius: 0;
    outline: none;
    appearance: none;
  }

  button {
    border: none;
    //border-radius: 0;
    // outline: none;
    appearance: none;
  }

  input[type="checkbox"] {
    margin-right: $margin-right-form;
    appearance: checkbox;
    cursor: pointer;
  }

  input[type="radio"] {
    margin-right: $margin-right-form;
    appearance: radio;
    cursor: pointer;
  }

  input[type="number"] {

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  input[type="search"] {
    -webkit-appearance: textfield;

    &:focus {
      outline-offset: -2px;
    }

    &::-webkit-search-cancel-button,
    &::-webkit-search-decoration,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
      display: none;
    }
  }

  input[type="range"] {
    -webkit-appearance: none;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
    }
  }

  textarea {
    resize: none;
  }

  .form__inline {

    .inline__input {
      width: calc(100% - 2.5rem - 1px);
      & > * {
        border-radius: $border-radius-form 0 0 $border-radius-form;
      }
    }

    .inline__unit {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2.5rem;
      border: solid 1px $color-form-border;
      border-left: none;
      border-radius: 0 $border-radius-form $border-radius-form 0;
      background-color: $color-background;
      font-size: $font-size-xs;
    }
  }

  //-----------------------------------------
  //  form custom
  //-----------------------------------------
  * {
    // @include transition;
    // position: relative;

    &:after,
    &:before {
      @include transition;
    }
  }

  input,
  textarea,
  select {
    font: inherit;
  }

  label {
    display: inline-block;
    cursor: pointer;
  }

  select::-ms-expand{
    display: none;
  }

  //  input
  //-----------------------------------------
  [class*="form__input"] {
    display: block;
    margin-right: $margin-right-form;

    &:last-child {
      margin-right: 0;
    }

    &[class*="-inline"] {
      display: inline-block;
    }
  }

  input[type="text"],
  input[type="password"],
  input[type="tel"],
  input[type="url"],
  input[type="email"],
  input[type="search"],
  input[type="date"],
  input[type="time"],
  input[type="number"]{
    position: relative;
    display: block;
    width: 100%;
    padding: $padding-form;
    background-color: $color-form-background;
    border: $border-form;
    border-radius: $border-radius-form;
    color: $color-form-text;
    letter-spacing: 0.1em;
    @include media-query(lg) {
      font-size: $fontsize-form;
    }
    @include media-query(sm) {
      font-size: $fontsize-form-sm;
      // min-height: 55px !important;
    }

    &:focus {
      z-index: 100;
      border: solid 1px $color-form-primary;
      background-color: rgba($color-primary, 0.1);
      box-shadow: 0 0 5px rgba($color-form-primary, 0.4);
    }

    &:disabled {
      background-color: darken($color-form-background, 10%);
      color: lighten($color-text, 40%);
    }

    &::placeholder {
      color: lighten($color-text, 40%);
    }

    &.form__output {
      margin-bottom: 0;
      padding: 0.3em 0;
      background-color: transparent;
      border: none;
      pointer-events: none;
    }
  }

  //  input file
  //-----------------------------------------
  [class*="form__file"] {
    display: flex;
    flex-wrap: nowrap;

    input {
      display: none;
    }

    &[class*="-inline"] {
      display: inline-block;
    }

    [class*="file__button"] {
      position: relative;
      display: flex;
      // width: 10em;
      padding-left: 1.6em;
      // margin-right: $margin-right-form;
      border-radius: $border-radius-form 0 0 $border-radius-form;
      border: $border-form;
      font-weight: normal;
      @include media-query(lg) {
        font-size: $fontsize-form;
      }
      @include media-query(sm){
        padding-left: 1em;
        font-size: $fontsize-form-sm;
      }

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        right: 0.6em;
        z-index: 1;
        transform: translate(0, -50%);
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0.3em 0.2em 0 0.2em;
        border-color: $color_form_text transparent transparent transparent;
      }
    }

    [class*="file__name"] {
      display: flex;
      align-items: center;
      overflow: hidden;
      width: calc(100% - 5em);
      padding: $padding-form;
      background-color: $color-form-background;
      border: $border-form;
      border-left: 0;
      border-radius: 0 $border-radius-form $border-radius-form 0;
      color: $color-form-text;
      word-break: break-all;
      @include media-query(lg) {
        font-size: $fontsize-form;
      }
      @include media-query(sm) {
        font-size: $fontsize-form-sm;
      }
    }
  }

  //  input number
  //-----------------------------------------
  [class*="form__number"] {
    display: flex;
    flex-wrap: nowrap;

    input {
      -webkit-appearance: none;
      -moz-appearance: textfield;
      display: flex;
      align-items: center;
      width: 6em;
      padding: $padding-form;
      margin: 0;
      background-color: $color-form-background;
      border: $border-form;
      border-left: 0;
      border-right: 0;
      border-radius: 0;
      color: $color-form-text;
      text-align: center;
      @include media-query(lg) {
        font-size: $fontsize-form;
      }
      @include media-query(sm) {
        font-size: $fontsize-form-sm;
      }

      &:focus {
        z-index: 100;
        border: solid 1px $color-form-primary;
        background-color: rgba($color-primary, 0.1);
        box-shadow: 0 0 5px rgba($color-form-primary, 0.4);
      }
    }

    &[class*="-inline"] {
      display: inline-block;
    }

    [class*="number__button"] {
      position: relative;
      display: flex;
      width: 3em;
      padding: 0;
      // margin-right: $margin-right-form;
      border: $border-form;
      font-size: 0.8rem;
      font-weight: normal;

      @include media-query(sm){
        align-items: center;
        justify-content: center;
      }

      &[class*="-plus"] {
        border-radius: $border-radius-form 0 0 $border-radius-form;
      }

      &[class*="-minus"] {
        border-radius: 0 $border-radius-form $border-radius-form 0;
      }
    }
  }


  //  input checkbox
  //-----------------------------------------
  [class*="form__checkbox"] {
    position: relative;
    display: inline-block;
    padding-left: 1.8em;
    @include media-query(lg) {
      font-size: $fontsize-form;
    }
    @include media-query(sm) {
      font-size: $fontsize-form-sm;
    }

    [class*="checkbox__button"] {
      position: absolute;
      top: 0.9em;
      left: 0;
      transform: translate(0 , -50%);
      display: block;
      width: 1.4em;
      height: 1.4em;
      border: $border-form;
      border-radius: $border-radius-form;
      background-color: $color-form-background;
      cursor: pointer;

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -70%) rotate(-45deg) scale(0);
        width: 0.6em;
        height: 0.3em;
        border-left: solid 0.2em $color-form-primary;
        border-bottom: solid 0.2em $color-form-primary;
        line-height: 0;
        letter-spacing: 0;
        opacity: 0;
      }
    }

    span {
      font-weight: bold;
    }

    input[type="checkbox"] {
      display: none;

      &:checked {
        & + .checkbox__button {
          &:after {
            transform: translate(-50%, -70%) rotate(-45deg) scale(1);
            opacity: 1;
          }
        }
      }

      &:disabled,
      &[disabled=""] {

        & + .checkbox__button {
          background-color: darken($color-form-background, 10%);

          & + span {
            opacity: 0.4;
          }
        }
      }
    }

    &[class*="-image"] {
      position: relative;
      padding-left: 0;
      text-align: center;

      img {
        @include transition;
        position: relative;
        z-index: 0;
        display: block;
        margin-bottom: 0.5em;

        &:hover {
          opacity: 0.6;
        }
      }

      [class*="checkbox__button"] {
        position: absolute;
        top: 0.5em;
        left: 0.5em;
        z-index: 1;
        transform: translate(0);
      }
    }
  }

  //  input radio
  //-----------------------------------------
  [class*="form__radio"] {
    position: relative;
    display: inline-block;
    padding-left: 1.8em;
    @include media-query(lg) {
      font-size: $fontsize-form;
    }
    @include media-query(sm) {
      font-size: $fontsize-form-sm;
    }

    [class*="radio__button"] {
      position: absolute;
      top: 0.9em;
      left: 0;
      transform: translate(0 , -50%);
      display: block;
      width: 1.4em;
      height: 1.4em;
      border: $border-form;
      border-radius: 50%;
      background-color: $color-form-background;
      cursor: pointer;

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(0);
        width: 0.5em;
        height: 0.5em;
        border-radius: 50%;
        background-color: $color-form-primary;
        line-height: 0;
        letter-spacing: 0;
        opacity: 0;
      }
    }

    input[type="radio"] {
      display: none;

      &:checked {
        & + .radio__button {
          &:after {
            transform: translate(-50%, -50%) scale(1);
            opacity: 1;
          }
        }
      }

      &:disabled,
      &[disabled=""] {

        & + .checkbox__button {
          background-color: darken($color-form-background, 10%);

          & + span {
            opacity: 0.4;
          }
        }
      }
    }

    &[class*="-image"] {
      position: relative;
      padding-left: 0;
      text-align: center;

      img {
        @include transition;
        position: relative;
        z-index: 0;
        display: block;
        margin-bottom: 0.5em;

        &:hover {
          opacity: 0.6;
        }

        @media all and (-ms-high-contrast: none){
          img {
            max-width: 240px;
            height: auto;
          }
        }
      }

      [class*="radio__button"] {
        position: absolute;
        top: 0.5em;
        left: 0.5em;
        z-index: 1;
        transform: translate(0);
      }
    }
  }

  //  select
  //-----------------------------------------
  [class*="form__select"] {
    position: relative;
    display: block;
    margin-right: $margin-right-form;

    &:last-child {
      margin-right: 0;
    }

    &[class*="-inline"] {
      display: inline-block;
    }

    select {
      position: relative;
      display: block;
      width: 100%;
      padding: $padding-form;
      padding-right: 2em;
      background-color: $color-form-background;
      border: $border-form;
      border-radius: $border-radius-form;
      color: $color-form-text;
      letter-spacing: 0.1em;
      cursor: pointer;
      @include media-query(lg) {
        font-size: $fontsize-form;
      }
      @include media-query(sm) {
        font-size: $fontsize-form-sm;
      }

      // [mane="cat"]{
      //   @include media-query(sm){
      //     min-height: 55px !important;
      //   }
      // }

      &:focus {
        z-index: 100;
        background-color: rgba($color-primary, 0.1);
        border: solid 1px $color-form-primary;
        box-shadow: 0 0 5px rgba($color-form-primary, 0.4);
      }

      option {

        &:disabled {
          color: lighten($color-text, 40%);
        }
      }

      &:disabled {
        background-color: darken($color-form-background, 10%);
        color: lighten($color-text, 40%);
      }
    }

    &:before {
      content: '';
      position: absolute;
      top: 40%;
      right: 0.6em;
      z-index: 1;
      transform: translate(0, -50%);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 0.2em 0.3em 0.2em;
      border-color: transparent transparent $color_form_text transparent;
    }

    &:after {
      content: '';
      position: absolute;
      top: 60%;
      right: 0.6em;
      z-index: 1;
      transform: translate(0, -50%);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0.3em 0.2em 0 0.2em;
      border-color: $color_form_text transparent transparent transparent;
    }

    &[class*="-inline"] {
      display: inline-block;
    }
  }

  //  textarea
  //-----------------------------------------
  [class*="form__textarea"] {
    display: block;
    margin-right: $margin-right-form;

    &:last-child {
      margin-right: 0;
    }

    &[class*="-inline"] {
      display: inline-block;
    }

    textarea {
      display: block;
      width: 100%;
      padding: $padding-form;
      background-color: $color-form-background;
      border: $border-form;
      border-radius: $border-radius-form;
      color: $color-form-text;
      @include media-query(lg) {
        font-size: $fontsize-form;
      }
      @include media-query(sm) {
        font-size: $fontsize-form-sm;
      }

      &:focus {
        z-index: 100;
        background-color: rgba($color-primary, 0.1);
        border: solid 1px $color-form-primary;
        box-shadow: 0 0 5px rgba($color-form-primary, 0.4);
      }
    }
  }

  // error
  //-----------------------------------------
  .form__error {
    position: relative;
    display: inline-block;
    //width: 100%;

    .error__text {

      p {
        color: $color-form-danger;
        font-size: $fontsize-form;
      }
    }
  }

  // custom
  //-----------------------------------------

  .is-error{
    background: #fcf0f1 !important;
  }

  .form__sizeItem{
    width: 15%;

    @include media-query(sm){
      width: 6em;
    }
  }

  .form__sizeFlame{
    width: 30%;

    @include media-query(sm){
      width: 40%;
    }
  }

  .form__half{
    @include media-query(lg){
      width: 50%;
    }
  }

  .form__logo{
    width: 30%;

    @include media-query(sm){
      width: 13rem;
    }
  }

  .form__orderday{
    width: 15%;

    @include media-query(sm){
      width: 6rem;
    }

    input {
      min-width: auto  !important;
    }
  }

  .form__orderdata{
    .form__input{
      @include media-query(sm){
        width: 30% !important;
      }
    }
  }
}
