/*===========================================
    c-window
===========================================*/

$color-whindow: $color-white;
$size-balloon: 0.625rem;

//-------------------------------------------

[class*="c-window"] {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: $color-whindow;
  border-radius: 4px;
  box-shadow: 4px 4px 32px rgba($color-black, 0.1);

  .window__close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    @include media-query(sm) {
      top: 0.5rem;
      right: 0.5rem;
    }

    i {
      width: 2rem;
      height: 2rem;
    }
  }

  > img {
    display: block;
    max-width: 100%;
  }

  //-----------------------------------------
  //  サイズ
  //-----------------------------------------
  &[class*="-lg"] {
    padding: 4rem;
    @include media-query(sm) {
      padding: 2rem;
    }
  }

  &[class*="-md"] {
    padding: 3rem;
    @include media-query(sm) {
      padding: 2rem;
    }
  }

  &[class*="-sm"] {
    padding: 2rem;
  }

  &[class*="-xs"] {
    padding: 1rem 1.4rem;
  }

  &[class*="-full"] {
    overflow: hidden;
    padding: 0;
  }


  //-----------------------------------------
  //  カラー
  //-----------------------------------------
  &[class*="-primary"] {
    background-color: lighten($color-primary, 65%);
    * {
      color: $color-primary;
    }
  }

  &[class*="-secondary"] {
    background-color: lighten($color-secondary, 35%);
    * {
      color: $color-secondary;
    }
  }

  &[class*="-success"] {
    background-color: lighten($color-success, 45%);

    * {
      color: $color-success;
    }
  }

  &[class*="-warning"] {
    background-color: lighten($color-warning, 45%);

    * {
      color: $color-warning;
    }
  }

  &[class*="-danger"] {
    background-color: lighten($color-danger, 45%);

    * {
      color: $color-danger;
    }
  }


  //-----------------------------------------
  //  balloon
  //-----------------------------------------
  &[class*="-balloon"] {
    content: '';
    position: relative;
    padding: 0.6em 1em;
    background-color: $color-whindow;
    line-height: 1.4;

    &:after {
      content: '';
      position: absolute;
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
    }

    &[class*="-balloon-t"] {
      &:after {
        top: - $size-balloon;
        left: 50%;
        margin-left: - #{$size-balloon/2};
        border-width: 0 #{$size-balloon/2} $size-balloon #{$size-balloon/2};
        border-color: transparent transparent $color-whindow transparent;
      }
    }

    &[class*="-balloon-r"] {
      &:after {
        top: 50%;
        right: - $size-balloon;
        margin-top: - #{$size-balloon/2};
        border-width: #{$size-balloon/2} 0 #{$size-balloon/2} $size-balloon;
        border-color: transparent transparent transparent $color-whindow;
      }
    }

    &[class*="-balloon-b"] {
      &:after {
        bottom: - $size-balloon;
        left: 50%;
        margin-left: - #{$size-balloon/2};
        border-width: $size-balloon #{$size-balloon/2} 0 #{$size-balloon/2};
        border-color: $color-whindow transparent transparent transparent;
      }
    }

    &[class*="-balloon-l"] {
      &:after {
        top: 50%;
        left: - $size-balloon;
        margin-top: - #{$size-balloon/2};
        border-width: #{$size-balloon/2} $size-balloon #{$size-balloon/2} 0;
        border-color: transparent $color-whindow transparent transparent;
      }
    }
  }
}
