/*===========================================
    c-table
===========================================*/
$border-th: solid 1px $color-border;
$border-td: solid 1px $color-border;

[class*="c-table"] {
  transition: none;
  position: relative;
  // table-layout: auto;
  width: 100%;
  // border: solid 1px $color-border;
  font-size: $font-size-sm;
  @include media-query(sm) {
    display: block;
    table-layout: fixed;
    -webkit-overflow-scrolling: touch;
    // white-space: nowrap;
    overflow-x: auto;
  }

  thead {
    width: 100%;

    th {
      background-color: $color-gray;
    }
  }

  tbody {
    width: 100%;
    background-color: $color-white;
  }

  tr {
    width: 100%;
    background-color: $color-white;
    border-bottom: $border-td;

    &:nth-child(2n) {
      // background-color: rgba($color-border, 0.2);
    }

    &:first-child {
      border-top: $border-td;
    }

    &:last-child {
      td,
      th {
        border-bottom: none;
      }
    }

    @media all and (-ms-high-contrast: none) {
      td:not(.c-text--md-left),
      th:not(.c-text--md-left) {
        position: static;
      }
    }

    @supports (-ms-ime-align: auto) {
      td:not(.c-text--md-left),
      th:not(.c-text--md-left) {
        position: static;
      }
    }

    td,
    th {
      // position: relative;
      // z-index: -1;
      min-width: 2em;
      // width: 100%;
      padding: 0.8em;
      border-left: solid 1px $color-border;
      border-right: solid 1px $color-border;
      vertical-align: middle;
      @include media-query(sm) {
        overflow-wrap : break-word;
      }

      img {
        display: block;
        min-width: 64px;
        // max-width: 128px;
        width: 100%;
      }

      input[type="text"] {
        @include media-query(sm) {
          min-width: 10em;
        }
      }
    }

    th {
      background-color: $color-gray;
      font-weight: bold;
      // @include media-query(lg){
      //   width: 40%;
      // }
      &:first-child {
        @include media-query(lg) {
          border-right: solid 1px $color-border;
        }
      }
    }

    td {}

    .table__nopadding {
      padding: 0;
    }
  }
  //-----------------------------------------
  // color
  //-----------------------------------------
  &[class*="-sm"] {
    font-size: $font-size-sm;

    td,
    th {
      padding: 0.6em;
    }
  }

  &[class*="-xs"] {
    font-size: $font-size-xs;

    td,
    th {
      padding: 0.4em;
    }
  }
  //-----------------------------------------
  // color
  //-----------------------------------------
  &[class*="-color"] {
    tr {
      &:nth-child(2n) {
        background-color: rgba($color-border, 0.2);
      }
    }
  }
  //-----------------------------------------
  // scroll
  //-----------------------------------------
  &[class*="-scroll"] {
    @include media-query(sm) {
      position: relative;
      display: flex;
      width: 100%;
    }

    thead {
      @include media-query(sm) {
        position: relative;
        z-index: 1;
        width: auto;
        border-bottom: none;
        box-shadow: 2px 0 4px rgba($color-black, 0.1);
      }

      tr {
        td,
        th {
          @include media-query(sm) {
            border-right: none;
          }
        }
      }
    }

    tbody {
      @include media-query(sm) {
        position: relative;
        z-index: 0;
        display: flex;
        width: 100%;
        overflow-x: scroll;
      }
    }

    tr {
      @include media-query(sm) {
        display: flex;
        flex-flow: column;
        width: 100%;
        border-bottom: none;
      }

      &:last-child {
        td,
        th {
          @include media-query(sm) {
            border-right: none;
          }
        }
      }

      td,
      th {
        @include media-query(sm) {
          width: 100%;
          border-bottom: $border-td;
          border-left: none;
          border-right: dotted 1px darken($color-border, 20%);
          white-space: nowrap;
        }

        &:last-child {
          @include media-query(sm) {
            // border: none;
          }
        }
      }
    }
  }
  //-----------------------------------------
  // swipe
  //-----------------------------------------
  &[class*="-swipe"] {}
  //-----------------------------------------
  // card
  //-----------------------------------------
  &[class*="-card"] {
    @include media-query(sm) {
      display: table;
    }

    thead {
      @include media-query(sm) {
        display: none;
      }
    }

    tbody {
      @include media-query(sm) {
        background-color: transparent;
      }
    }

    tr {
      @include media-query(sm) {
        display: block;
        overflow: hidden;
        border: $border-td;
        border-color: $color-border;
        border-radius: 4px;
        margin-bottom: 1em;
      }

      &:last-child {
        margin-bottom: 0;
      }

      td,
      th {
        @include media-query(sm) {
          padding: 1em 2em;
          border-right: none;
          border-left: none;
          border-bottom: $border-td;
          border-color: $color-border;
          text-align: left;
        }

        &:last-child {
          @include media-query(sm) {
            border: none;
          }
        }
      }

      th {
        @include media-query(sm) {
          display: block;
          background-color: lighten($color-border, 5%);
          // background-color: rgba($color-border, 0.2);
        }
      }

      td {
        @include media-query(sm) {
          display: flex;
          justify-content: space-between;
          //display: block;
          background-color: $color-white;
        }

        &:before {
          @include media-query(sm) {
            content: attr(data-label);
            display: block;
            margin-right: 0.5em;
            font-size: 0.88em;
            font-weight: bold;
          }
        }
      }
    }
  }
  //-----------------------------------------
  // border
  //-----------------------------------------
  &[class*="-border"] {
    display: table;
    border-left: none;
    border-right: none;
    border-color: $color-border;
    background-color: transparent;

    thead {
      background-color: transparent;

      tr {
        border-color: $color-text;
      }

      th {
        border: none;
      }
    }

    tbody {
      background-color: transparent;

      th {
        background-color: transparent;
      }
    }

    tr {
      border-color: $color-border;
      background-color: transparent;
    }

    td,
    th {
      border: none;
      background-color: transparent;
      word-break: break-all;
    }

    &[class*="-cart"] {
      tbody {
        @include media-query(sm) {
          display: block;
          width: 100%;
        }
      }

      tr {
        @include media-query(sm) {
          display: block;
          width: 100%;
        }
      }

      th {
        @include media-query(lg) {
          width: 30%;
        }
      }

      td,
      th {
        @include media-query(sm) {
          display: block;
          width: 100%;
        }
      }
    }
  }
  //-----------------------------------------
  // form
  //-----------------------------------------
  &[class*="-form"] {
    border: none;

    .is-ie11 & {
      table-layout: fixed;
    }

    thead {
      display: none;
    }

    tbody {
      @include media-query(sm) {
        display: block;
        width: 100%;
      }
    }

    tr {
      border: none;
      @include media-query(sm) {
        display: block;
        width: 100%;
      }

      &:nth-child(2n) {
        background-color: rgba($color-border, 0.2);
      }
    }

    td,
    th {
      position: relative;
      min-width: 80px;
      max-width: 100%;
      border: none;
      vertical-align: top;
      // vertical-align: center;
      @include media-query(sm) {
        display: block;
        width: 100%;
      }


    }

    th {
      @include media-query(lg) {
        width: 30%;
        padding: 1.8em 5em 1.8em 1.4em;
      }
      @include media-query(sm) {
        width: 100%;
        padding: 1.8em 4em 0 1.4em;
      }
    }

    td {
      padding: 1.4em 4em 1.4em 1.4em;
    }

    tbody {
      th {
        background-color: transparent;
      }
    }

    .is-required {
      &:after {
        content: '必須';
        position: absolute;
        top: 1.8rem;
        right: 1.4rem;
        // transform: translateY(-50%);
        padding: 0.2em 0.8em;
        background-color: $color-danger;
        color: $color-white;
        font-size: $font-size-xs;
        vertical-align: middle;
      }
    }

    .table__note {
      display: block;
      margin: 1em 0;
    }

    .table__question {
      position: absolute;
      top: 50%;
      right: 1.4em;
      transform: translateY(-50%);
    }

    span {
      vertical-align: middle;
    }
    //-----------------------------------------
    // cart
    //-----------------------------------------
    &[class*="-cart"] {
      td {
        padding: 1.4em;
      }

      .cart__total {
        th {
          @include media-query(lg) {
            width: 20%;
          }
          @include media-query(sm) {
            padding: 1.4em;
          }
        }
      }
    }
  }
  //-----------------------------------------
  // list
  //-----------------------------------------
  &[class*="-list"] {
    @include media-query(sm) {
      display: block;
    }

    thead {
      @include media-query(sm) {
        display: none;
      }
    }

    tbody {
      @include media-query(sm) {
        display: block;
        width: 100%;
        border-top: $border-td;
        background-color: transparent;
      }

      tr {
        @include media-query(sm) {
          display: block;
          width: 100%;
          border: none;
          padding: 1em;
          border-bottom: $border-td;
        }
      }

      td,
      th {
        @include media-query(sm) {
          width: 100%;
          padding: 0;
          margin-bottom: 0.5em;
          border: none;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      th {}

      td {
        @include media-query(sm) {
          display: flex;
          justify-content: flex-start;
          //display: block;
          width: 100%;
          padding-left: 1em;
          background-color: $color-white;
        }

        &:before {
          @include media-query(sm) {
            content: attr(data-label);
            display: block;
            width: 8rem;
            margin-right: 0.5em;
            font-size: 0.88em;
            font-weight: bold;
          }
        }
      }
    }
  }
}
// @media all and (-ms-high-contrast: none) {
//   [class*="c-table"] {
//     border-collapse: separate;
//     /* IE10以上 */
//     border-spacing: 1px;
//
//     tr td,
//     tr th {
//       border-left: none;
//     }
//   }
// }
