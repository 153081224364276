/*===========================================
    c-dl
===========================================*/

[class*="c-dl"] {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 0.6em;
  margin-bottom: 0;
  font-size: $font-size-sm;
  @include media-query(sm) {
  }

  dt{
    width: 50%;
    padding: 0.4em;
  }

  dd{
    width: 50%;
    padding: 0.4em;
  }

  //-----------------------------------------
  // border
  //-----------------------------------------
  &[class*="-border"]{
    border-bottom: 2px solid $color-gray;
    padding-bottom: 24px;
    margin-bottom: 24px;
  }

  //-----------------------------------------
  // vertical
  //-----------------------------------------
  &[class*="-vertical"]{
    @include media-query(sm){
      flex-direction: column;
      align-items: stretch;
    }

    dt,dd{
      @include media-query(sm){
        width: 100%;
      }
    }
  }

  //-----------------------------------------
  // form__input
  //-----------------------------------------
  .form__input{
    [type="text"]{
      display: block;
      width: 100%;
      // padding: 0.6em 1em;
      padding: 0;
      border-color: $color-white;
      // border: solid 1px $color-border;
      border-radius: 4px;
      color: #333;
      font-size: $font-size-sm;
      letter-spacing: 0.1em;
    }
  }
}
