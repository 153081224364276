/*===========================================
    c-companyList
===========================================*/
.c-companyList {

  .companyList__item {
    padding: 2rem 1rem;
    border-top: solid 1px $color-border;

    &:first-child {
      border: none;
    }

    &:hover {
      // background-color: $color-background;
    }

    .item__inner {
      @include media-query(lg) {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    .item__image {
      @include media-query(lg) {
        width: 200px;
        margin-right: 2rem;
      }
      @include media-query(sm) {
        max-width: 200px;
        width: 80%;
        margin: 0 auto;
      }

      img {
        width: 100%;
      }
    }

    .item__company {
      @include media-query(lg) {
        // width: calc(100% - 4rem - 400px);
        margin-right: 2rem;
      }
      @include media-query(sm) {
        width: 100%;
        margin: 1rem auto;
      }
    }

    .item__information {
      @include media-query(lg) {
        width: 200px;
        padding-left: 2rem;
        border-left: solid 1px $color-border;
      }
      @include media-query(sm) {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }

      > * {
        // @include media-query(sm) {
        //   width: 50%;
        // }
      }

      .information__tel {
        @include media-query(lg) {
          a[href^="tel:"]{
            pointer-events: none;
          }
        }

        &:before {
          content: 'Tel:';
          margin-right: 0.5em;
        }
      }

      .item__category {
        @include media-query(sm) {
          text-align: right;
        }
      }
    }
  }
}
