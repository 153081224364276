/*===========================================
    c-image
===========================================*/
[class*="c-image"] {

  > img {
    display: block;
    max-width: 100%;
  }

  //-----------------------------------------
  //  サイズ
  //-----------------------------------------
  &[class*="-fullWidth"] {

    > img {
      width: 100%;
    }
  }

  &[class*="-fullHeight"] {

    > img {
      width: auto;
      height: 100%;
    }
  }

  //-----------------------------------------
  //  揃え
  //-----------------------------------------
  &[class*="-center"] {

    > img {
      margin-left: auto;
      margin-right: auto;
    }
  }

  &[class*="-left"] {

    > img {
      margin-right: auto;
    }
  }

  &[class*="-right"] {

    > img {
      margin-left: auto;
    }
  }
}
