/*===========================================
    c-heading
===========================================*/
[class*="c-heading"] {
  position: relative;
  margin-bottom: 0.8em;
  color: $color-text;
  font-weight: bold;
  line-height: 1.4;

  [class*="heading__sub"] {
    color: $color-text;
    font-size: 0.8em;
    font-family: $font-family-primary;
    font-weight: bold;

    vertical-align: middle;

    &[class*="-before"] {
      margin-right: 1em;
    }

    &[class*="-after"] {
      margin-left: 1em;
    }

    &[class*="-block"] {
      display: block;
      margin-top: 0.4em;
      margin-bottom: 0.4em;
      text-align: inherit;
    }
  }

  [class*="heading__icon"] {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    font-size: 0.5em;
  }

  [class*="heading__image"] {
    width: 2em;
    height: 2em;
    margin-right: 0.2em;
    vertical-align: middle;
  }


  //-----------------------------------------
  //  色
  //-----------------------------------------
  &[class*="-black"] {
    color: $color-black;
  }

  &[class*="-white"] {
    color: $color-white;
  }

  &[class*="-primary"] {
    color: $color-primary;
  }

  &[class*="-secondary"] {
    color: $color-secondary;
  }


  //-----------------------------------------
  //  サイズ
  //-----------------------------------------
  &[class*="-xl"] {
    @include media-query(lg) {
      font-size: 2.285rem !important;//36px
    }
    @include media-query(sm) {
      font-size: 1.61rem !important;//21px
    }
  }

  &[class*="-lg"] {
    @include media-query(lg) {
      font-size: 2rem !important;//30px
    }
    @include media-query(sm) {
      font-size: 1.46rem !important;//19px
    }
  }

  &[class*="-md"] {
    @include media-query(lg) {
      font-size: 1.6rem !important;//24px
    }
    @include media-query(sm) {
      font-size: 1.30rem !important;//17px
    }
  }

  &[class*="-sm"] {
    @include media-query(lg) {
      font-size: 1.2rem !important;//18px
    }
    @include media-query(sm) {
      font-size: 1.15rem !important;//15px
    }
  }

  &[class*="-xs"] {
    font-size: 1.0rem !important;//15px、13px
  }


  //-----------------------------------------
  //  揃え
  //-----------------------------------------
  &[class*="-center"] {
    text-align: center;
  }

  &[class*="-left"] {
    text-align: left;
  }

  &[class*="-right"] {
    text-align: right;
  }


  //-----------------------------------------
  //  装飾
  //-----------------------------------------
  &[class*="-border"] {

    &::before {
      content: '';
      display: inline-block;
      width: 4px;
      height: 1em;
      margin-right: 0.5em;
      background-color: $color-primary;
    }
  }

  &[class*="-block"] {
    &::before{
      content: '■';
      margin-right: 0.2rem;
    }
  }
}
