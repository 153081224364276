/*===========================================
    c-subscript
===========================================*/
[class*="c-subscript"] {
  font-size: 0.75em;
  font-weight: inherit;

  //-----------------------------------------
  //  位置
  //-----------------------------------------
  &[class*="-t"] {
    vertical-align: top;
  }

  &[class*="-m"] {
    vertical-align: middle;
  }

  &[class*="-b"] {
    vertical-align: baseline;
  }
}
