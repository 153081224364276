/*===========================================
    c-historyList
===========================================*/
.c-historyList {

  .historyList__item {
    display: flex;
    justify-content: flex-start;
    // border-bottom: solid 1px $color-border;
    font-size: $font-size-sm;

    &:last-child {
      border-bottom: none;
    }

    time {
      width: 5rem;
      margin-bottom: 0 !important;
      margin-right: 1rem;
    }

    .item__title {
      width: calc(100% - 6rem);
    }
  }
}
