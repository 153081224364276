/*===========================================
    c-buttonGroup
===========================================*/
[class*="c-buttonGroup"] {
  display: flex;
  justify-content: flex-start;
  @include media-query(sm) {
    width: 100%;
    overflow-x: scroll;
  }

  [class*="c-button-"] {
    width: 100%;
    margin-bottom: 0 !important;

    &:not(.c-button--tab) {
      position: relative;
      // border: none;
      border-radius: 0;

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        z-index: 1;
        transform: translate(0, -50%);
        width: 1px;
        height: 60%;
        background-color: $color-white;
      }

      &:first-child {
        border-radius: 4px 0 0 4px;
      }

      &:last-child {
        border-radius: 0 4px 4px 0;

        &:after {
          content: none;
        }
      }
    }
  }

  &[class*="-tab"] {

    [class*="c-button--tab"] {
      position: relative;
      white-space: nowrap;
      
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(0, -50%);
        width: 1px;
        height: 50%;
        background-color: $color-border;
      }

      &:first-child {
        border-radius: 0;
      }

      &:last-child {
        border-radius: 0;

        &:before {
          content: none;
        }
      }
    }
  }
}
