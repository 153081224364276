/*===========================================
    c-overlay
===========================================*/

$color-overlay: $color-black;

//-------------------------------------------

[class*="c-overlay"] {
  @include transition;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 101;
  width: 100%;
  height: 100%;
  background-color: rgba($color-overlay, 0.6);
  opacity: 0;
  pointer-events: none;

  &.is-active {
    opacity: 1;
    pointer-events: all;
  }
}
