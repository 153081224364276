/*===========================================
    c-flame
===========================================*/

$margin-content: 1.2rem;
$width-value: 5;

//-------------------------------------------

[class*="c-flame"] {
  position: relative;
  background-color: $color-white;
  border-radius: 0.3rem;

  > * {
    margin-top: 1rem;

    &:first-child {
      margin-top: 0;
    }
  }

  a {
    text-decoration: underline;
  }

  .flame__close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    @include media-query(sm) {
      top: 0.5rem;
      right: 0.5rem;
    }

    i {
      width: 2rem;
      height: 2rem;
    }
  }

  [class*="flame__content"] {
    @include media-query(lg) {
      margin-top: 0 !important;
    }
  }

  //-----------------------------------------
  //  サイズ
  //-----------------------------------------
  &[class*="-lg"] {
    padding: 4rem;
    @include media-query(sm) {
      padding: 2rem;
    }
  }

  &[class*="-md"] {
    padding: 3rem;
    @include media-query(sm) {
      padding: 2rem;
    }
  }

  &[class*="-sm"] {
    padding: 2rem;
  }

  &[class*="-xs"] {
    padding: 1rem 1.4rem;
  }


  //-----------------------------------------
  //  カラー
  //-----------------------------------------
  &[class*="-primary"] {
    background-color: lighten($color-primary, 60%);

    * {
      color: $color-primary;
    }
  }

  &[class*="-secondary"] {
    background-color: lighten($color-secondary, 35%);
    color: $color-secondary;

    * {
      color: inherit;
    }

    a {
      color: inherit !important;
    }
  }

  &[class*="-tertiary"] {
    background-color: lighten($color-tertiary, 25%);
    color: $color-tertiary;

    * {
      color: inherit;
    }

    a {
      color: inherit !important;
    }
  }

  &[class*="-gray"] {
    background-color: $color-background;

    * {
      // color: $color-text;
    }
  }

  &[class*="-success"] {
    background-color: lighten($color-success, 45%);
    color: $color-success;

    * {
      color: inherit;
    }

    a {
      color: inherit !important;
    }
  }

  &[class*="-warning"] {
    background-color: lighten($color-warning, 30%);
    color: $color-warning;

    * {
      color: inherit;
    }

    a {
      color: inherit !important;
    }
  }

  &[class*="-danger"] {
    background-color: lighten($color-danger, 45%);
    color: $color-danger;

    * {
      color: inherit;
    }

    a {
      color: inherit !important;
    }
  }

  &[class*="-border"] {
    border: solid 4px $color-background;
  }

  &[class*="-semiorder"] {
    border: solid 8px $color-background;
  }

  &[class*="-acrylic"] {
    border: solid 8px $color-background;
    // border: solid 8px lighten($color-secondary, 20%);
  }

  &[class*="-polyca"] {
    border: solid 8px $color-background;
    // border: solid 8px lighten($color-tertiary, 20%);
  }


  //-----------------------------------------
  //  カラム
  //-----------------------------------------
  &[class*="-column"] {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;

    &[class*="-2"] {

      [class*="flame__content"] {
        width: calc((100% - 1px) / 2);
        padding-right: $margin-content;
        border-right: solid 1px $color-border;

        @for $i from 0 through 20 {
          &[class*="-w#{$i * $width-value}"] {
            @include media-query(lg) {
              width: #{$i * $width-value + %}
            }
          }
        }

        &:nth-child(2n) {
          margin-right: 0;
          padding-left: $margin-content;
          padding-right: 0;
          border: none;
        }
      }
    }

    &[class*="-3"] {

      [class*="flame__content"] {
        // width: calc((100% - #{$margin-content} * 2 - 1px) / 2);
        margin-right: $margin-content;
        padding-right: $margin-content;
        border-right: solid 1px $color-border;

        @for $i from 0 through 20 {
          &[class*="-w#{$i * $width-value}"] {
            @include media-query(lg) {
              width: calc((#{$i * $width-value}% - (#{$margin-content} * 3 - 1px * 2)) / 3);
            }
          }
        }

        &:nth-child(3n) {
          margin-right: 0;
          padding-right: 0;
          border: none;
        }
      }
    }

    &[class*="-4"] {

      [class*="flame__content"] {
        // width: calc((100% - #{$margin-content} * 2 - 1px) / 2);
        margin-right: $margin-content;
        padding-right: $margin-content;
        border-right: solid 1px $color-border;

        @for $i from 0 through 20 {
          &[class*="-w#{$i * $width-value}"] {
            @include media-query(lg) {
              width: calc((#{$i * $width-value}% - (#{$margin-content} * 4 - 1px * 2)) / 4);
            }
          }
        }

        &:nth-child(4n) {
          margin-right: 0;
          padding-right: 0;
          border: none;
        }
      }
    }

    //-----------------------------------------
    //  揃え
    //-----------------------------------------
    &[class*="-center"] {
      align-items: center;
    }

    //-----------------------------------------
    //  タイプ
    //-----------------------------------------
    &[class*="-reverse"] {
      @include media-query(lg) {
        flex-direction: row-reverse;
      }
    }

    &[class*="-vertical"] {
      @include media-query(sm) {
        display: block;
      }

      [class*="flame__content"] {
        @include media-query(sm) {
          width: 100%;
          margin-right: 0;
          margin-bottom: 1.4rem;
          padding: 0 0 1.4rem;
          border-right: none;
          border-bottom: solid 1px $color-border;
        }

        &:last-child {
          @include media-query(sm) {
            margin: 0;
            padding: 0;
            border: none;
          }
        }
      }
    }
  }
}
