/*===========================================
    u-indent
===========================================*/
$indent-value: 0.5;

@for $i from 1 through 4 {
  .u-indent--#{$i} {
    padding-left: #{$i * $indent-value}em !important;
    text-indent: -#{$i * $indent-value}em !important;
  }
}
