/*===========================================
    c-price
===========================================*/
[class*="c-price"] {
  font-family: $font-family-number !important;
  font-size: 1.6rem;
  font-weight: 600;
  letter-spacing: 0 !important;

    span {
      margin-left: 0.2em;
      font-size: 0.6em;
      // font-weight: bold;
      vertical-align: baseline;
    }

  //-----------------------------------------
  //  サイズ
  //-----------------------------------------
  &[class*="-xl"] {
    font-size: 2.4rem !important;
  }

  &[class*="-lg"] {
    font-size: 2rem !important;
  }

  &[class*="-md"] {
    font-size: 1.6rem !important;
  }

  &[class*="-sm"] {
    font-size: 1.2rem !important;
  }

  &[class*="-xs"] {
    font-size: 0.8rem !important;
  }
}
