//===========================================
//  transition
//===========================================
@mixin transition($duration: 0.2s, $property: all, $timing: ease) {
  transition-property: $property;
  transition-duration: $duration;
  transition-timing-function: $timing;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
