/*===========================================
    js-dropdown
===========================================*/
.js-dropdown {
  position: relative;
  cursor: pointer;
}

.js-dropdown__trigger {
}

[class*="js-dropdown__content-"] {
  @include media-query(lg) {
    @include transition;
    position: absolute;
    z-index: 1000;
    pointer-events: none;
    opacity: 0;
  }
  @include media-query(sm) {
  }

  &[class*="-top"] {
    @include media-query(lg) {
      bottom: 100%;
      right: 0;
      transform: translate(0, 1rem);
    }
  }

  &[class*="-bottom"] {
    @include media-query(lg) {
      top: 100%;
      right: 0;
      transform: translate(0, -1rem);
    }
  }

  &[class*="-left"] {
    @include media-query(lg) {
      top: -1.2rem;
      right: 100%;
      transform: translate(1em, 0);
    }
  }

  &[class*="-right"] {
    @include media-query(lg) {
      top: -1.2rem;
      left: 100%;
      transform: translate(-1em, 0);
    }
  }


  &.is-active {
    visibility: visible;
    transform: translate(0, 0);
    pointer-events: all;
    opacity: 1;
  }
}
