/*===========================================
    c-text
===========================================*/
[class*="c-text"] {
  // font-size: $font-size-md;
  font-weight: nomal;


  //-----------------------------------------
  //  サイズ
  //-----------------------------------------
  &[class*="-xl"] {
    font-size: $font-size-xl !important;
  }

  &[class*="-lg"] {
    font-size: $font-size-lg !important;
  }

  &[class*="-md"] {
    font-size: $font-size-md !important;
  }

  &[class*="-sm"] {
    font-size: $font-size-sm !important;
  }

  &[class*="-xs"] {
    font-size: $font-size-xs !important;
  }


  //-----------------------------------------
  //  揃え
  //-----------------------------------------
  &[class*="-center"] {
    text-align: center;
  }

  &[class*="-left"] {
    text-align: left;
  }

  &[class*="-right"] {
    text-align: right;
  }


  //-----------------------------------------
  //  スタイル
  //-----------------------------------------
  &[class*="-number"] {
    font-family: $font-family-number;
  }

  &[class*="-weightBold"] {
    font-weight: bold;
  }

  &[class*="-weightNomal"] {
    font-weight: nomal;
  }

  &[class*="-italic"] {
    font-style: italic;
  }

  &[class*="-underline"] {
    text-decoration: underline;
  }

  &[class*="-highlight"] {
    background-color: $color-mark;
  }

  &[class*="-note"] {
    padding-left: 1em;
    text-indent: -1em;

    &:before {
      content: '※';
    }
  }


  //-----------------------------------------
  //  カラー
  //-----------------------------------------
  &[class*="-primary"] {
    color: $color-primary;
  }

  &[class*="-secondary"] {
    color: $color-secondary;
  }

  &[class*="-success"] {
    color: $color-success !important;
  }

  &[class*="-warning"] {
    color: $color-warning !important;
  }

  &[class*="-danger"] {
    color: $color-danger !important;
  }
}
