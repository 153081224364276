/*===========================================
    js-modal
===========================================*/
$color-modal-background: $color-white;
$color-modal-shadow: 0 4px 24px rgba($color-black, 0.2);

.js-modal {

  &--image {
    cursor: pointer;

    &:hover {
      opacity: 0.6;
    }
  }
}

.js-modal__trigger--open {
  @include transition;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }
}

.js-modal__wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2001;
  display: none;
  overflow: scroll;
  width: 100%;
  height: 100%;
  padding: 0;
}

.js-modal__overlay {
  z-index: 2000;
}

.js-modal__content {
  @include transition;
  backface-visibility: hidden;
  position: absolute;
  z-index: 2001;
  transform: scale(0.9, 0.9);
  margin: 0 auto;
  padding: 40px;
  background: $color-modal-background;
  box-shadow: $color-modal-shadow;
  opacity: 0;
  pointer-events: none;
  @include media-query(lg) {
    max-width: 1000px;
  }
  @include media-query(sm) {
    max-width: calc(100% - 32px);
  }

  img {
    display: block;
    width: auto;
    height: auto;
    @include media-query(lg) {
      // max-width: 1000px;
      // max-height: 80vh;
    }
  }

  &.is-active {
    transform: scale(1, 1);
    opacity: 1;
    pointer-events: all;
  }
}

.js-modal__origin {
  display: none;
}
