/*===========================================
    c-inportantNews
===========================================*/
.c-inportantNews {
  position: relative;
  padding: 1.4rem;
  margin-bottom: 2rem;
  border: solid 4px lighten($color-danger, 20%);
  border-radius: 0.3rem;

  &.is-hide {
    display: none;
  }

  * {
    color: $color-danger;
  }

  .inportantNews__close {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
}
