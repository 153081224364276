/*===========================================
    c-listNavigation
===========================================*/
[class*="c-listNavigation"] {
  position: relative;
  @include media-query(lg) {
    min-height: 32px;
  }

  .listNavigation__infomation {
    font-size: $font-size-lg;
    font-weight: bold;
    @include media-query(lg) {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
    @include media-query(sm) {
      margin-bottom: 2rem;
    }

    span {
      font-size: 0.8em;
      vertical-align: baseline;
    }
  }

  .listNavigation__pagenation {
    margin: 0 auto;
    @include media-query(lg) {
      width: 400px;
    }
    @include media-query(sm) {
      width: 100%;
    }

    .is-ie11 & {
      @include media-query(lg){
        width: auto;
      }
    }
  }
}
