/*===========================================
    c-section
===========================================*/
[class*="c-section"] {
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  border-bottom: solid 1px $color-border;

  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }

  > * {
    margin-bottom: 2rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &[class*="--noPadding"] {
    @include media-query(sm) {
      padding-left: 0;
      padding-right: 0;
    }

    .section__title {
      @include media-query(sm) {
        padding: 0 16px;
      }
    }
  }

  //-----------------------------------------
  // cart
  //-----------------------------------------
  &[class*="-cart"] {
      border-bottom: none;
  }

  .section__sub {
    margin-top: 4rem;
    margin-bottom: 4rem;

    > * {
      margin-bottom: 2rem;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .section__title {
    margin-bottom: 0.8em;
  }

  .section__caption {
    margin-bottom: 0.6em;
  }
}
