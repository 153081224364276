/*===========================================
    c-list
===========================================*/
[class*="c-list"] {

  .list__item {
    margin-bottom: 0.4em;
    padding-left: 0;
    text-indent: 0;
    list-style-position: inside;

    &:last-child {
      margin-bottom: 0;
    }

    > ul,
    > ol {
      margin: 0.5em 1em;
    }

    > a {
      color: $color-link;
    }

    th, td {
      text-indent: 0;
    }
  }

  //-----------------------------------------
  //  サイズ
  //-----------------------------------------
  &[class*="-xl"] {
    font-size: $font-size-xl;
  }

  &[class*="-lg"] {
    font-size: $font-size-lg;
  }

  &[class*="-md"] {
    font-size: $font-size-md;
  }

  &[class*="-sm"] {
    font-size: $font-size-sm;
  }

  &[class*="-xs"] {
    font-size: $font-size-xs;
  }


  //-----------------------------------------
  //  disc
  //-----------------------------------------
  &[class*="-disc"] {

    .list__item {
      padding-left: 1em;
      text-indent: -1em;
      list-style-type: disc;
    }
  }


  //-----------------------------------------
  //  number
  //-----------------------------------------
  &[class*="-number"] {

    .list__item {
      padding-left: 1em;
      text-indent: -1em;

      i {
        white-space: nowrap;
      }
    }
  }


  //-----------------------------------------
  //  notes
  //-----------------------------------------
  &[class*="-note"] {

    > .list__item {
      padding-left: 1em;
      text-indent: -1em;

      &:before {
        content: '※';
      }
    }
  }


  //-----------------------------------------
  //  icon
  //-----------------------------------------
  &[class*="-icon"] {

    .list__item {
      position: relative;
      padding-left: 1.4em;

      i {
        position: absolute;
        // top: 0.4em;
        left: 0;
        // font-size: 0.6em;
        vertical-align: middle;
      }
    }
  }


  //-----------------------------------------
  //  block
  //-----------------------------------------
  &[class*="-block"] {
    overflow: hidden;
    border: solid 1px $color-border;
    border-radius: 4px;
    background-color: $color-white;

    .list__item {
      position: relative;
      margin: 0;
      border-bottom: solid 1px $color-border;

      &:last-child {
        border: none;
      }

      > * {
        padding: 0.6em 1em;
      }

      > a {
        display: block;
        color: $color-link;

        &:hover {
          text-decoration: none;
          background-color: lighten($color-link, 30%);
        }
      }

      > ul,
      > ol {
        margin: 0;
        padding: 0;
        border-top: solid 1px $color-border;
        background-color: lighten($color-border, 10%);
      }
    }
  }
}
