/*===========================================
    u-display
===========================================*/
// セミオーダー系でパラメーターは必要だが、表示上削除予定のものにつける
.u-sagyou--hide {
  display: none !important;
}

[class*="u-display"] {

  &[class*="-block"] {
    display: block !important;
  }

  &[class*="-inline"] {
    display: inline !important;
  }

  &[class*="-inlineBlock"] {
    display: inline-block !important;
  }

  &[class*="-flex"] {
    display: flex !important;
    align-items: flex-start !important;
    // justify-content: space-between !important;

    &[class*="-center"] {
      align-items: center !important;
    }

    &[class*="-wrap"] {
      flex-wrap: wrap !important;
    }

    > * {
      margin-bottom: 0 !important;
    }

    &[class*="-end"] {
      justify-content: flex-end !important;
    }
  }

  &[class*="-none"] {
    // display: none !important;

    &[class*="-sm"] {
      @include media-query(sm) {
        display: none !important;
      }
    }

    &[class*="-md"] {
      @include media-query(md) {
        display: none !important;
      }
    }

    &[class*="-lg"] {
      @include media-query(lg) {
        display: none !important;
      }
    }

    &[class*="-all"] {
      display: none !important;
    }
  }
}
