/*===========================================
    c-pageIndex
===========================================*/
[class*="c-pageIndex"] {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 3rem;

  > * {
    margin: 0.5em !important;
  }
}
