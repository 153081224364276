/*===========================================
    c-pagenation
===========================================*/
[class*="c-pagenation"] {
  display: flex;
  align-items: center;
  justify-content: center;
  // width: 100%;
  font-size: 0.875rem;

  ol {
    display: flex;
    margin: 0 1rem;
    font-size: 1rem;

    li {
      padding: 0 0.5em;

      @include media-query(sm){
        padding: 0 0.3em;
      }

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2em;
        height: 2em;
        color: $color-text;
        font-weight: bold;

        &:hover {
          color: $color-primary;
          text-decoration: none;
        }
      }

      .is-active {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2em;
        height: 2em;
        background-color: $color-primary;
        border-radius: 50%;
        color: $color-white;
      }
    }
  }

  .pagenation__prev,
  .pagenation__next {
    width: 2em;
    height: 2em;

    i {
      width: 2em;
      height: 2em;
    }
  }
}
