/*===========================================
    c-button
===========================================*/

$opacity-label: 0.6;
$border-label: 2px;

//-------------------------------------------
a {
  @include transition;
  text-decoration: none !important;

  > [class*="c-label"] {
    width: 100%;
    text-decoration: none  !important;
  }

  &:hover {
    text-decoration: none !important;
    opacity: 0.8;
  }
}

[class*="c-label"] {
  @include transition;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 2.0em;
  width: auto;
  height: auto;
  padding: 0.4em 0.8em;
  background-color: lighten($color-black, 90%);
  // border: solid $border-label rgba($color-text, 0.8);
  border-radius: 4px;
  color: $color-text;
  font-weight: bold;
  line-height: 1.6;
  letter-spacing: 0.05em;
  text-decoration: none;
  white-space: nowrap;


  //-----------------------------------------
  //  色
  //-----------------------------------------
  &[class*="-black"] {
    background-color: lighten($color-black, 90%);
    // border-color: rgba($color-black, 0.8);
    color: $color-text;
  }

  &[class*="-white"] {
    background-color: $color-white;
    // border-color: rgba($color-white, 0.8);
    color: $color-text;
  }

  &[class*="-primary"] {
    background-color: lighten($color-primary, 5%);
    // border-color: rgba($color-primary, 0.8);
    color: $color-white;
  }

  &[class*="-secondary"] {
    background-color: lighten($color-secondary, 10%);
    // border-color: rgba($color-secondary, 0.8);
    color: $color-white;
  }

  &[class*="-kojin"] {
    background-color: #EA494B;
    // border-color: rgba($color-secondary, 0.8);
    color: $color-white;
  }

  &[class*="-enpou"] {
    background-color: #EA494B;
    // border-color: rgba($color-secondary, 0.8);
    color: $color-white;
  }

  &[class*="-oogata"] {
    background-color: $color-primary;
    // border-color: rgba($color-secondary, 0.8);
    color: $color-white;
  }

  &[class*="-soryo"] {
    padding-left: 2.5rem;
    background-color: lighten($color-black, 90%);
    background-image: url('../images/icon/icon_truck_b.svg');
    background-repeat: no-repeat;
    background-size: 2em auto;
    background-position: 0.2em center;
    // border-color: rgba($color-black, 0.8);
    color: $color-text;
  }


  //-----------------------------------------
  //  サイズ
  //-----------------------------------------
  &[class*="-xl"] {
    font-size: 1rem;
  }

  &[class*="-lg"] {
    font-size: 0.875rem;
  }

  &[class*="-md"] {
    font-size: 0.75rem;
  }

  &[class*="-sm"] {
    font-size: 0.625rem;
  }
}

a[class*="c-label-"] {
  cursor: pointer;

  &:hover {
    text-decoration: none;
    opacity: $opacity-button;
  }
}
