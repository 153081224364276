/*===========================================
    js-addContent
===========================================*/
.js-addContent {
}

.js-addContent__trigger {

  &--open {
  }
}

.js-addContent__content {
  // @include transition;
  display: none;

  &.is-active {
    display: block;
  }
}
