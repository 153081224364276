/*===========================================
    u-whitespace
===========================================*/
.u-whitespace {

  &--nomal {
    white-space: normal !important;
  }

  &--nowrap {
    white-space: nowrap !important;
  }
}
