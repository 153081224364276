/*===========================================
    c-pankuzu
===========================================*/
[class*="c-pankuzu"] {
  margin-bottom: 1rem;

  .pankuzu__list {
    display: flex;
    flex-wrap: wrap;
    font-size: 0.875rem;

    li {
      white-space: nowrap;

      &:after {
        content: '>';
        padding: 0 0.6em;
      }

      &:last-child {

        &:after {
          content: none;
        }
      }

      a {
        white-space: inherit;
      }
    }
  }

  //-----------------------------------------
  // scroll
  //-----------------------------------------
  &[class*="-scroll"] {
    width: 100%;

    @include media-query(lg) {
      overflow-x: hidden;
    }
    @include media-query(sm) {
      overflow-y: hidden;
      overflow-x: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;
    }

    &::-webkit-scrollbar {
      @include media-query(sm) {
        display: none;
      }
    }

    .pankuzu__list {
      flex-wrap: nowrap;
    }
  }
}
