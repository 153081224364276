/*===========================================
    js-accordion
===========================================*/
.js-accordion {

  &[data-type="lg"] {

    .js-accordion__trigger {
      @include media-query(sm) {
        pointer-events: none;
      }
    }

    .js-accordion__content {
      @include media-query(sm) {
        display: block !important;
      }
    }
  }

  &[data-type="sm"] {

    .js-accordion__trigger {
      @include media-query(lg) {
        pointer-events: none;
      }
    }

    .js-accordion__content {
      @include media-query(lg) {
        display: block !important;
      }
    }
  }
}

.js-accordion__trigger {
  cursor: pointer;
}

.js-accordion__content {
  display: none;
  margin-top: 1.4rem;
  // @include transition;
  // overflow: hidden;
  // pointer-events: none;
  // opacity: 0;

  &.is-active {
    display: block;
    pointer-events: all;
  }
}
