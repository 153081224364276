/*===========================================
    u-width
===========================================*/
$width-value: 5;

@for $i from 0 through 20 {
  .u-width--#{$i * $width-value} {
    width: #{$i * $width-value}% !important;
  }
}

@for $i from 0 through 40 {
  .u-width--#{$i}rem {
    width: #{$i}rem !important;
  }
}

.u-width--auto {
  width: auto !important;
}
