/*===========================================
    u-margin
===========================================*/
$margin-value: 2;
$margin-array: top, right, bottom, left;

@function roundWidth($value,$n){
  $squared: 10;
  @for $i from 1 to $n{
    $squared: $squared*10;
  }
  @return (round($value*$squared) / $squared);
}

@each $direction in $margin-array {
  // 0pxから120pxまで
  @for $i from 0 through 40 {
    .u-margin--#{$direction}-#{$i * $margin-value} {
      margin-#{$direction}: #{roundWidth((($i * $margin-value) / 14),2)}rem !important;
    }
  }
}

.u-margin--auto {
  margin-left: auto;
  margin-right: auto;
}
