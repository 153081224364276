/*===========================================
    c-flow
===========================================*/
[class*="c-flow"] {
  position: relative;

  &:before,
  .flow__progress {
    @include transition;
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    // transform: translate(-50%, -50%);
    display: block;
    width: calc(100% - 1rem);
    margin-top: -1px;
    margin-left: calc( (100% - 1rem) / -2);
    height: 2px;
  }

  &:before {
    background-color: $color-border;
  }

  .flow__progress {
    transform: scale(0);
    transform-origin: left;
    background-color: $color-primary;
  }

  .flow__step {
    display: flex;
    justify-content: space-between;
    
    a {
      position: relative;
      display: block;
      width: 0.6rem;
      height: 0.6rem;
      margin: 0;
      color: $color-white;

      &:before {
        content: '';
        position: absolute;

        display: block;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: $color-border;
      }

      &.is-active,
      &.is-on {
        &:before {
          background-color: $color-primary;
        }
      }
    }
  }

  .flow__balloon {
    @include transition();
    position: absolute;
    bottom: 1.4rem;
    left: 50%;
    transform: translate(-50%, 10%);
    background-color: $color-primary;
    color: $color-white;
    white-space: nowrap;
    opacity: 0;

    &:after {
      border-color: $color-primary transparent transparent transparent !important;
    }

    &.is-active {
      transform: translate(-50%, 0);
      opacity: 1;
    }
  }
}
