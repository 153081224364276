/*===========================================
    c-faqList
===========================================*/
.c-faqList {

  .faqList__item {
    padding: 1.5rem 1rem;
    border-top: solid 1px $color-border;

    &:first-child {
      border: none;
    }

    .item__question {
      position: relative;
      display: flex;
      align-items: center;
      padding-right: calc(20px + 4em);
      color: $color-text;
      font-size: $font-size-lg;
      text-decoration: none;

      &:hover {
        opacity: 0.6;
      }

      .question__icon {
        margin-right: 1em;
        font-size: 2.4rem;
        font-family: $font-family-number;
        font-weight: 700;
      }

      .question__plus {
        position: absolute;
        top: 50%;
        right: 1.5rem;
        transform: translateY(-50%);
        width: 2em;
        height: 2em;
      }
    }

    .item__answer {
      position: relative;
      display: flex;
      align-items: center;
      padding-top: 1.5rem;
      padding-right: calc(1.5rem + 4em);
      color: $color-text;
      font-size: $font-size-md;
      text-decoration: none;

      .answer__icon {
        margin-right: 1em;
        color: $color-danger;
        font-size: 2.4rem;
        font-family: $font-family-number;
        font-weight: 700;
      }

      .answer__title {
        padding: 1.5rem;
        background-color: $color-background;
        border-radius: 8px;
      }

      .answer__arrow {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        width: 2em;
        height: 2em;
      }
    }
  }
}
