/*===========================================
    u-preformatted
===========================================*/
.u-preformatted {
  * {
    font-size: 0.875rem !important;
    line-height: 1.2em !important;
  }

  code {
    padding: 2rem !important;
  }
}
