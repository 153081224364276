/*===========================================
    js-slick
===========================================*/
.slick-next,
.slick-prev {
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 100;
  padding: 0;
  background-color: transparent;
  font-size: 0;
  cursor: pointer;
  @include media-query(lg) {
    width: 48px;
    height: 48px;
  }
  @include media-query(sm) {
    width: 32px;
    height: 32px;
  }

  > * {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.slick-prev {
  @include media-query(lg) {
    left: -56px;
  }
  @include media-query(sm) {
    left: -24px;
  }
}

.slick-next {
  @include media-query(lg) {
    right: -56px;
  }
  @include media-query(sm) {
    right: -24px;
  }
}

.slick-dots {
  position: absolute;
  left: 50%;
  bottom: -1em;
  z-index: 0;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  transform: translateX(-50%);
  width: auto;
  height: 4px;
  margin: 0 auto;
  text-align: center;
  list-style: none;
  @include media-query(sm) {
    padding: 0 88px;
  }

  li {
    position: relative;
    overflow: hidden;
    width: 8px;
    height: 8px;
    margin: 0 8px;
    padding: 0;
    border-radius: 50%;
    cursor: pointer;

    button {
      display: block;
      width: 100%;
      height: 100%;
      border: 0;
      background: $color-border;
      color: transparent;
      font-size: 0;
      line-height: 0;
      outline: none;
      cursor: pointer;
    }

    &.slick-active button {
      background: $color-primary;
    }
  }
}

//-------------------------------------------
//  .shop__slick
//-------------------------------------------
.shop__slick {

  .slick-prev,
  .slick-next {
    top: 40%;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-size: 16px;
    background-position: center;
    background-color: palettes(black);
  }

  .slick-prev {
    left: 6.5%;

    i {

      &:before {
        transform: rotate(-180deg);
      }
    }
  }

  .slick-next {
    right: 6.5%;
  }
}
