/*===========================================
    c-newsList
===========================================*/
.c-newsList {

  .newsList__item {
    border-top: solid 1px $color-border;

    &:first-child {
      border: none;
    }

    a {
      display: block;
      color: $color-text;
      font-weight: normal;
      text-decoration: none;

      &:hover {
        background-color: $color-background;
      }
    }

    .item__inner {
      display: flex;
      align-items: flex-start;
      padding: 1em;
      @include media-query(sm) {
        flex-wrap: wrap;
      }
    }

    .item__time {
      width: 5em;
    }

    .item__time,
    .item__category {
      margin-bottom: 0 !important;
      margin-right: 2em;
    }

    .item__title {
      @include media-query(sm) {
        width: 100%;
        margin-top: 1em;
      }
    }
  }
}
