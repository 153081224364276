//===========================================
//  font
//===========================================

//-------------------------------------------
//  フォントファミリー
//-------------------------------------------
$font-family-primary:   "メイリオ", Meiryo, "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "ＭＳ Ｐゴシック", sans-serif;
$font-family-secondary: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", sans-serif;
$font-family-number:    "Roboto", sans-serif;

//-------------------------------------------
//  フォントサイズ
//-------------------------------------------
$font-size-xl:          1.266rem;
$font-size-lg:          1.133rem;
$font-size-md:          1rem;
$font-size-sm:          0.866rem;
$font-size-xs:          0.733rem;
