//===========================================
//  color
//===========================================

//-------------------------------------------
//  カスタム
//-------------------------------------------
$color-white:      #fff;
$color-black:      #000;
$color-gray:       #e9e9e9;
$color-primary:    #009A98;
$color-secondary:  #1DAEE9;
$color-tertiary:   #847DB6;
$color-step:       #DBD0B4;
$color-cart:       #FD9B5B;

//-------------------------------------------
//  バックグランド
//-------------------------------------------
$color-background: #f3f3f3;


//-------------------------------------------
//  テキスト
//-------------------------------------------
$color-text:       #333;


//-------------------------------------------
//  リンク
//-------------------------------------------
$color-link:       $color-primary;
$color-visit:      #009587;


//-------------------------------------------
//  ドラッグ
//-------------------------------------------
$color-mark:       rgba(#666, 0.2);


//-------------------------------------------
//  ボーダー
//-------------------------------------------
$color-border:     #ddd;


//-------------------------------------------
//  コンテキスト
//-------------------------------------------
$color-success:    #06CDB5;
$color-warning:    #E68857;
$color-danger:     #DE1816;
