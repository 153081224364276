/*===========================================
    js-scrollFixed
===========================================*/
.js-scrollFixed {
  position: relative;
}

.js-scrollFixed__target {
  @include transition(0);
  @include media-query(lg) {
    position: absolute;
    left: 0;
    right: auto !important;
    z-index: 100;
  }
  @include media-query(sm) {
    position: relative !important;
    left: 0 !important;
  }

  &.is-fixed {
    @include media-query(lg) {
      position: fixed  !important;
    }

    // .is-ie11 & {
    //   position: absolute !important;
    // }
  }
}
