/*===========================================
    c-toolbar
===========================================*/
.c-toolbar {
  display: flex;
  align-items: center;
  width: 100%;

  .toolbar__search {
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    margin-right: 1rem;
    font-size: $font-size-md;

    > * {
      min-height: 1px;
    }

    .search__select {
      min-width: 120px;

      select {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        @include media-query(sm) {
          min-height: 55px;
        }
      }
    }

    .search__input {
      flex-grow: 1;
      flex-shrink: 1;
      min-width: 1em;
      width: 100%;
      margin-left: -1px;

      input {
        border-radius: 0;
        @include media-query(lg) {
          height: 100%;
        }
        @include media-query(sm) {
          min-height: 55px;
        }

        .is-ie11 & {
          height: 100%;
        }
      }
    }

    .search__button {
      width: 4rem;
      height: auto;
      padding: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      @include media-query(sm) {
        min-height: 55px;
      }
    }
  }
}
